<template>
    <v-footer
      bottom absolute
      style="background:#3F3F3F;"
      class="font-weight-medium"
    >
      <v-col
        class="text-center text-white"
        cols="12"
      >
      © {{ new Date().getFullYear() }} -  powered by AutoSyncX™ - All Rights Reserved
      </v-col>
    </v-footer>
</template>

<script>
export default {
    
}
</script>

<style>

</style>
