<template>
 <v-app id="inspire" style="background-color: white;">

    <div style=" justify-content: space-around" class="mt-5 mb-1">
        <v-row class="pl-5 pr-5 mt-5" >
        <v-col v-if="loadingFlag" cols="12" align="center" justify="center" style="margin-bottom:-10px;">
                <img v-if="!company" @click="scrollToBottom()" :height="this.$vuetify.breakpoint.xs ? 120 : 200" class="image-logo2" src="@/assets/img/logo.png">
                <img v-if="company && company.logo" @click="scrollToBottom()" :height="this.$vuetify.breakpoint.xs ? 80 : 300" class="image-logo2" :src="base_url_img+company.logo">
        </v-col>
        </v-row>
      <div v-if="step == 1">
        <v-row class="" style="margin: 0px!important;">
         <v-col cols="12">
           <h2 class="text-h4 text-center" style="color: gray;">Welcome to a Life-Changing Opportunity! </h2>
           <p class="text-h6 text-center mt-3" style="color: black;">
            We are thrilled to introduce you to an exciting partnership between Round Tables Auto Sales, AutoSyncX™ (a low-profit company), and THE ICED GROUP (Intervening, Caring, Educating, and Developing Group), a non-profit. Together, we've developed a unique program designed to help you achieve financial freedom.</p> 
            <p class="text-h5 text-center mt-3" style="color: gray;cursor:pointer" @click="scrollToBottom()"><strong>How It Works:
           </strong></p>
           <p class="text-h6 text-center mt-3" style="color: black;">
            AutoSyncX™ and THE ICED GROUP will cover your car payments and insurance when you refer approved members into the program. Our mission is to support your financial growth by removing these major expenses and providing you with valuable resources to build a stronger future.</p> 
             <p class="text-h5 text-center mt-3" style="color: gray;cursor:pointer" @click="scrollToBottom()"><strong>Program Benefits:
           </strong></p>
             <ul class="elementor-icon-list-items text-h6  " style="padding: 0px;">
               <p :class="this.$vuetify.breakpoint.xs ? '' : 'text-center'">
                       <span class="elementor-icon-list-icon">
               <i aria-hidden="true" class="fas fa-check-circle mr-2" style="color: #4CAF50;"></i>						</span>
                     <span class="elementor-icon-list-text"><strong>Car Payments Covered</strong>: When you refer a new member who is approved, AutoSyncX™ will pay up to $500 towards your car payment.
</span>
                     </p>
                     <p :class="this.$vuetify.breakpoint.xs ? '' : 'text-center'">
                       <span class="elementor-icon-list-icon">
               <i aria-hidden="true" class="fas fa-check-circle mr-2" style="color: #4CAF50;"></i>						</span>
                     <span class="elementor-icon-list-text"><strong>Insurance Paid</strong>: Refer three approved members, and AutoSyncX™ and THE ICED GROUP will also cover your car insurance up to $500.</span>
                     </p>
                     <p :class="this.$vuetify.breakpoint.xs ? '' : 'text-center'">
                       <span class="elementor-icon-list-icon">
               <i aria-hidden="true" class="fas fa-check-circle mr-2" style="color: #4CAF50;"></i>						</span>
                     <span class="elementor-icon-list-text"><strong>Financial Education</strong>: As a member, you’ll receive access to credit education and tools for building financial wealth.</span>
                     </p>
             </ul>
         </v-col>
 
         <v-col cols="12">
             <p class="text-h5 text-center" style="color: gray;cursor:pointer" @click="scrollToBottom()"><strong>How to Get Started:</strong></p>
             <ul class="elementor-icon-list-items text-h6 " style="padding: 0px;">
               <p :class="this.$vuetify.breakpoint.xs ? '' : 'text-center'">
                    <span class="elementor-icon-list-icon mr-3">
                    <strong>1.</strong>			</span>
                  <span class="elementor-icon-list-text">Build Your Member Card: Click the button below to begin creating your profile.
</span>
              </p>
              <p :class="this.$vuetify.breakpoint.xs ? '' : 'text-center'">
                    <span class="elementor-icon-list-icon mr-3">
                    <strong>2.</strong>			</span>
                  <span class="elementor-icon-list-text">Refer Approved Members: Share this incredible program with others. Once they join and are approved, you’ll start enjoying the benefits.
</span>
              </p>
              <p :class="this.$vuetify.breakpoint.xs ? '' : 'text-center'">
                    <span class="elementor-icon-list-icon mr-3">
                    <strong>3.</strong>			</span>
                  <span class="elementor-icon-list-text">Enjoy the Perks: As your referrals grow, so do your rewards. We handle the payments so you can focus on your financial goals.
</span>
              </p>
             
                     
               
             </ul>
         </v-col>
         <v-col cols="12">
             <p class="text-h5 text-center" style="color: gray;"><strong>Ready to Start?</strong></p>

            <p class="text-h6 text-center" style="">Simply click the button below to begin creating your profile and take the first step toward financial freedom!
            </p>

            
            
         </v-col>
         <v-col cols="12">
          
          
          <p class="text-h6 text-center " style="color: gray;"><strong>If you have any questions, don’t hesitate to reach out—we’re here to help!

          </strong></p>
          <p class="text-h6 text-center " style="color: gray;"><strong>Thank you for being part of this exciting journey. Together, we’re building a future full of opportunities.

          </strong></p>
          <p class="text-h6 text-center mt-3" style="color: red;"><strong>Up to $500 Payments on Car Payments and Insurance:
</strong></p>
          <p class="text-h6 text-center mt-3" style="color:red">Car Payments: AutoSyncX™ will cover up to $500 of your car payments once your referral is approved. Specific payout details will be shown in your AutoSyncX™ Member portal.


          </p>
          <p class="text-h6 text-center mt-3" style="color:red">Insurance Payments: Insurance payouts will start after your third approved referral, covering up to $500. Specific payout details will be shown in your AutoSyncX™ Member portal.



          </p>
          </v-col>
       <v-col lg="8" cols="12" class="mx-auto my-auto">
         <!-- <h2 class="text-h3 text-center" style="color: gray;">Get your own customized digital card</h2>
           <p class="text-h5 text-center mt-3" style="color: gray;">
             Here you will be able to create and purchase your very own business & digital cards.</p> 
             <p class="text-5 text-center" style="color: gray;">
            </p>  -->
            <p style="text-align: center;" ref="bottom">
             <v-btn
                         color="#4CAF50"
                         large
                         :disabled="!validGeneral"
                         @click="step = 2"
                         style="text-transform :none!important;"
                         class="
                         
                         mt-5
                           font-weight-bolder
                           btn-default
 
                         "
                         
                       >
             Begin creating your profile   <v-icon class="material-icons-round" style="font-size: 35;">arrow_forward</v-icon>
           </v-btn>
           </p>
       </v-col>
     </v-row>
    </div>
    <div v-if="step == 3">
      <v-row class="">
      <v-col lg="8" cols="12" class="mx-auto my-auto">
        <h2 class="text-h3 text-center" style="color: #4CAF50;">Your Digital card is ready!!</h2>
          <p class="text-h5 text-center mt-3" style="color: gray;">
            Please check you email to verify and set password then you can login.</p> 
            <p class="text-5 text-center" style="color: gray;">
           </p> 
           <p style="text-align: center;">
            <v-btn
                        color="#4CAF50"
                        large
                        :disabled="!validGeneral"
                        @click="gotoNext()"
                        style="text-transform :none!important;"
                        class="
                        
                        mt-5
                          font-weight-bolder
                          btn-default

                        "
                        
                      >
            Click here to Login   <v-icon class="material-icons-round" style="font-size: 35;">arrow_forward</v-icon>
          </v-btn>
          </p>
      </v-col>
    </v-row>
    </div>
    <div v-if="step == 2">
      <v-row class="my-16">
      <v-col lg="8" cols="12" class="mx-auto my-auto my-16">
         <div>
          <v-stepper alt-labels v-model="e1" style="box-shadow :none;">
              <v-stepper-header>
                <v-stepper-step  step="1" :complete="e1 > 1" :color="'green'"  :complete-icon="'fa-check'"
          >
                  Look & Feel
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 2" step="2" :color="'green'" :complete-icon="'fa-check'">
                  Your Details
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 3" step="3" :color="'green'" :complete-icon="'fa-check'">
                  Customize Card
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 4" step="4" :color="'green'" :complete-icon="'fa-check'">
                  Confirmation
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-content step="1">
               <v-row>
                <v-col cols="12" >
                  <h1 class="text-h3 text-center"> Look & Feel</h1>
                  <p class="text-h5 text-center mt-3" style="color: gray;">
                    Custom-tailor your cards to your preference</p> 
                </v-col>
                <v-col cols="12">
                  <div style="background-color: rgb(217, 221, 226);
                    height: 6px;
                    margin: 15px auto;
                    width: 80px;"></div>
                    <p class="text-h6 text-center mt-3" > Select your preferred color theme</p>
                </v-col>  
                <v-col cols="12" style="justify-content: center;display: flex;margin-top: 25px;" class="col-color">
                  <v-radio-group
                  v-model="partner.color" row
                  style="padding: 0 0 0 64px;width: 340px;"
                  >
                  <v-radio   color="green" value="orange" class="color-check" >
                    <template v-slot:label>
                      <v-card  color="#e2e6ec" class="trucksicons d-flex align-center flex-column rounded-lg color-card" style="background: linear-gradient(-45deg, rgb(255, 167, 81), rgb(255, 207, 52));">
                      
                      </v-card>
                      <span class="color-span">Orange</span>
                    </template> 
                  </v-radio>
                  <v-radio   color="green" value="green" class="color-check" >
                    <template v-slot:label>
                      <v-card  color="#e2e6ec" class="trucksicons d-flex align-center flex-column rounded-lg color-card" style="background: linear-gradient(-45deg, rgb(67, 233, 123), rgb(56, 249, 215));">
                      
                      </v-card>
                      <span class="color-span" style="margin: -50px 0 0 -6px;">Green</span>
                    </template> 
                  </v-radio>
                  <v-radio   color="green" value="purple" class="color-check" >
                    <template v-slot:label>
                      <v-card  color="#e2e6ec" class="trucksicons d-flex align-center flex-column rounded-lg color-card" style="background: linear-gradient(-45deg, rgb(95, 114, 189), rgb(155, 35, 234));">
                      
                      </v-card>
                      <span class="color-span">Purple</span>
                    </template> 
                  </v-radio>
                  <v-radio   color="green" value="red" class="color-check" >
                    <template v-slot:label>
                      <v-card  color="#e2e6ec" class="trucksicons d-flex align-center flex-column rounded-lg color-card" style="background: linear-gradient(-45deg, rgb(147, 41, 30), rgb(237, 33, 58));">
                      
                      </v-card>
                      <span class="color-span" style="margin: -50px 0 0 -2px;">Red</span>
                    </template> 
                  </v-radio>
                  <v-radio   color="green" value="pink" class="color-check" >
                    <template v-slot:label>
                      <v-card  color="#e2e6ec" class="trucksicons d-flex align-center flex-column rounded-lg color-card" style="background: linear-gradient(-45deg, rgb(255, 117, 140), rgb(255, 126, 179));">
                      
                      </v-card>
                      <span class="color-span" style="margin: -50px 0 0 -2px;">Pink</span>
                    </template> 
                  </v-radio>
                  <v-radio   color="green" value="Blue" class="color-check" >
                    <template v-slot:label>
                      <v-card  color="#e2e6ec" class="trucksicons d-flex align-center flex-column rounded-lg color-card" style="background: linear-gradient(-45deg, rgb(0, 91, 234), rgb(0, 198, 251));">
                      
                      </v-card>
                      <span class="color-span" style="margin: -50px 0 0 -2px;">Blue</span>
                    </template> 
                  </v-radio>
                  <v-radio   color="green" value="darkblue" class="color-check" >
                    <template v-slot:label>
                      <v-card  color="#e2e6ec" class="trucksicons d-flex align-center flex-column rounded-lg color-card" style="background: linear-gradient(-45deg, rgb(2, 27, 121), rgb(5, 117, 230));">
                      
                      </v-card>
                      <span class="color-span" style="margin: -50px 0 0 -19px;">Dark Blue</span>
                    </template> 
                  </v-radio>
                  <v-radio   color="green" value="black" class="color-check" >
                    <template v-slot:label>
                      <v-card  color="#e2e6ec" class="trucksicons d-flex align-center flex-column rounded-lg color-card" style="background: linear-gradient(-45deg, rgb(35, 37, 38), rgb(65, 67, 69));">
                      
                      </v-card>
                      <span class="color-span" style="margin: -50px 0 0 -6px;">Black</span>
                    </template> 
                  </v-radio>
                  <v-radio   color="green" value="silver" class="color-check" >
                    <template v-slot:label>
                      <v-card  color="#e2e6ec" class="trucksicons d-flex align-center flex-column rounded-lg color-card" style="background: linear-gradient(-45deg, rgb(189, 195, 199), rgb(44, 62, 80));">
                      
                      </v-card>
                      <span class="color-span" style="margin: -50px 0 0 -5px;">Silver</span>
                    </template> 
                  </v-radio>
                  
                  </v-radio-group>
                </v-col>
               </v-row>
                <div style="text-align: center;"> 
                

                <v-btn color="grey"
                  large @click="e1 = 1, step=1" class="
                  
                  font-weight-bolder
                  btn-default

                ">
                  Back
                </v-btn>
                <v-btn
                  color="#4CAF50"
                  large
                  @click="e1 = 2"
                  style="text-transform :none!important;"
                  class="
                    ml-2
                    font-weight-bolder
                    btn-default

                  "
                              
                            >
                  Next: Your Details  <v-icon class="material-icons-round" style="font-size: 35;">arrow_forward</v-icon>
                </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                  >
                <v-row>
                <v-col cols="12" >
                  <h1 class="text-h3 text-center"> Your Details</h1>
                  <p class="text-h6 text-center mt-3" style="color: gray;">
                    Tell your clients & contacts who you are</p> 
                </v-col>
                <v-col cols="12" class="mb-3">
                  <div style="background-color: rgb(217, 221, 226);
                    height: 6px;
                    margin: 15px auto;
                    width: 80px;"></div>
                </v-col>  
                <v-col cols="12" md="6">
                  <v-text-field
                        id="name"
                        :rules="rules_name"
                        name="name"
                        outlined
                        filled
                        class="font-size-input input-style"
                        label="First Name"
                        style="margin-top: -28px;"
                        width="300px"
                        ref="name"
                        required
                        v-model="partner.first_name"
                        />
                </v-col>
                <v-col cols="12" md="6">
                <v-text-field
                        id="name"
                        :rules="rules_name"
                        name="name"
                        class="font-size-input input-style"
                        label="Last Name"
                        width="300px"
                        ref="name"
                        required
                        style="margin-top: -28px;"
                        outlined
                        filled
                        v-model="partner.last_name"
                        />
                </v-col>
                <v-col cols="12" md="6">
                <v-text-field
                        id="name"
                        :rules="emailRules"
                        name="name"
                        class="font-size-input input-style"
                        label="Email"
                        width="300px"
                        ref="name"
                        style="margin-top: -28px;"
                        outlined
                        filled
                        required
                        v-model="partner.email"
                        />
                </v-col>
                <v-col cols="12" md="6">
                <v-text-field
                        id="name"
                        :rules="rules"
                        name="name"
                        style="margin-top: -28px;"
                        class="font-size-input input-style"
                        label="Phone"
                        width="300px"
                        ref="name"
                        outlined
                        v-mask="'(###) ###-####'"
                        required
                        filled
                        v-model="partner.phone"
                        />
                </v-col>
                <v-col cols="12" md="12">
                  <avatar-cropper
                          :upload-handler="handleUploaded"
                          :output-mime="cropperOutputMime"
                          v-model="changeTrigger"
                          />
                          <div style="text-align: center;margin-bottom: 45px;">
                            <p class="text-h6 text-center mt-3">Your Photo</p>
                            <v-avatar v-if="!preview" @click="changeTrigger = true" size="130" color="rgb(190, 190, 190)">
                              <v-icon dark class="material-icons-round" style="font-size: 50!important;" size="50">image</v-icon>
                              </v-avatar>
                              <v-avatar v-else @click="changeTrigger = true" size="130">
                                <img
                                  :src="preview"
                                  alt="John"
                                  
                                >
                              </v-avatar>
                            </div>
                        
                </v-col>
                </v-row>
                </v-form>
                <div style="text-align: center;"> 
                

                <v-btn color="grey"
                  large @click=" e1 = 1 " class="
                  
                  font-weight-bolder
                  btn-default

                ">
                  Back
                </v-btn>
                <v-btn
                  color="#4CAF50"
                  large
                  :loading="loadingSaveButton"
                  @click="checkForm()"
                  style="text-transform :none!important;"
                  class="
                    ml-2
                    font-weight-bolder
                    btn-default

                  "
                              
                            >
                  Next: Submit  <v-icon class="material-icons-round" style="font-size: 35;">arrow_forward</v-icon>
                </v-btn>
                </div>
              </v-stepper-content>

              <!-- <v-stepper-content step="3">
                <v-row>
                  <v-col cols="12" >
                  <h1 class="text-h3 text-center"> Customize Card</h1>
                  <p class="text-h5 text-center mt-3" style="color: gray;">
                    Enter the ways/contact-points people can connect with you through</p> 
                </v-col>
                <v-col cols="12">
                  <div style="background-color: rgb(217, 221, 226);
                    height: 6px;
                    margin: 15px auto;
                    width: 80px;"></div>
                </v-col>  
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="4" style="justify-content: center;display: flex;margin-top: 25px;">
                    <v-card
                      class="mb-12"
                      color="#EEEEEE"
                      width="100%"
                      height="auto"
                    >
                  
                  
                    <div  class="header-card" :style="'background: '+partner.color+'!important;'">
        <v-row style="display: flex; justify-content: space-around" >
          <v-col cols="12" style="display: flex; justify-content: space-around;margin-top: 35px;" >
                    <v-avatar color="indigo" class="mt-5" size="100" v-if="photo">
                              <img v-if="partner"  class="" :src="preview" >
                              </v-avatar>
                </v-col>
                <v-col cols="12" style="display: flex; justify-content: space-around; margin-top: -15px;padding: 15px; " >
                    <h1 style="color: white;text-align: center;">{{partner.first_name}} {{ partner.last_name }}</h1>
                  </v-col>
                  <v-col cols="12" style="display: flex; justify-content: space-around" >
                    <p :class="`text-`" style="color: white;margin-top: -25px;">{{partner.position}}</p>
                  </v-col>
                  <v-col cols="12"  style="display: flex;margin-top: -35px; justify-content: space-around; margin-bottom: 35px;" >
                    <h5 class="h4 font-weight-medium" style="color: white;opacity: 0.55;">{{partner.business_name}}</h5>
                  </v-col>
                </v-row>
                </div>
                <v-row style="background-color: rgb(230, 230, 230);margin: 0px;">
                  <v-col cols="6" md="6" style="padding-right: 0px;padding: 0px;" v-for="item in contact_points" :key="item.id">
                      <a class="cards" @click="slectedItem = item,showDialog = true">
                        <v-row style="display: flex; justify-content: space-around;margin-top: -75px;width: 100%;" >
                                <v-col cols="12" style="display: flex; justify-content: space-around;margin-top: 15px; padding: 0px;" >
                                  <v-icon size="25" class="material-icons-round" style="color:black" >
                                      {{getIcon(item.type)}}
                                    </v-icon>
                                      </v-col>
                                    
                                        <v-col cols="12"  style="display: flex; justify-content: space-around; margin-bottom: 0px;" >
                                          <h5 class="h4 font-weight-medium  text-uppercase" style="color: black;" v-if="item.value">{{item.type}}</h5>
                                          <h5 class="text-md-h6 text-lg-h6 text-uppercase" style="color: black;" v-else>Add Contact Point</h5>
                                        </v-col>
                                        <v-col cols="12" style="display: flex; justify-content: space-around" >
                                          <p :title="item.value" :class="`text-`" style="color: black;margin-top: -25px; margin-bottom: -30px;width: 149px;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;">{{item.value}}</p>
                                        </v-col>
                                       
                                      </v-row>
                      </a>
                </v-col>
               
                </v-row>
                  
                  
                  
                  
                  </v-card>
                  </v-col>
                  <v-col cols="12" md="4"></v-col>
                  <v-col cols="12" md="12"><p class="text-h5 text-center" style="color: gray;margin-top: -50px;">Great news! Our app allows you to add additional buttons once you are logged in.</p></v-col>
                </v-row>
               

                <div style="text-align: center;"> 
                

                <v-btn color="grey"
                  large @click=" e1 = 2 " class="
                  
                  font-weight-bolder
                  btn-default

                ">
                  Back
                </v-btn>
                <v-btn
                  color="#4CAF50"
                  large
                  @click="e1 = 4 "
                  style="text-transform :none!important;"
                  class="
                    ml-2
                    font-weight-bolder
                    btn-default

                  "
                              
                            >
                  Next: Confirmation  <v-icon class="material-icons-round" style="font-size: 35;">arrow_forward</v-icon>
                </v-btn>
                </div>
              </v-stepper-content> -->
              <!-- <v-stepper-content step="4">
                <v-row>
                  <v-col cols="12" >
                  <h1 class="text-h3 text-center"> Final Confirmation</h1>
                  <p class="text-h5 text-center mt-3" style="color: gray;">
                    Preview and confirm the final looks and details of your cards. Please be advised that after this step you will not be able to make changes.</p> 
                </v-col>
                <v-col cols="12">
                  <div style="background-color: rgb(217, 221, 226);
                    height: 6px;
                    margin: 15px auto;
                    width: 80px;"></div>
                    <p class="text-h5 text-center mt-3" style="color: gray;">
                    <b>DIGITAL CARD</b></p> 
                </v-col>  
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="4" style="justify-content: center;display: flex;margin-top: 25px;">
                  <div style="position: relative;left: 66px;
transform-origin: 0px 0px 0px;
transform: scale(0.65);">
                  <img src="@/assets/img/iphone-x.png" class="sc-kkGfuU gJwyPG" width="400" style="z-index: 2;
pointer-events: none;
position: relative;">
                    <v-card
                      class="mb-12 phone-view"
                      color="#EEEEEE"
                      width="100%"
                      height="auto"
                    >
                  
                  
                    <div  class="header-card " :style="'background: '+partner.color+'!important;'">
        <v-row style="display: flex; justify-content: space-around" >
          <v-col cols="12" style="display: flex; justify-content: space-around;margin-top: 35px;" >
                    <v-avatar color="indigo" class="mt-5" size="100" v-if="photo">
                              <img v-if="partner"  class="" :src="preview" >
                              </v-avatar>
                </v-col>
                <v-col cols="12" style="display: flex; justify-content: space-around; margin-top: -15px;padding: 15px; " >
                    <h1 class=" " style="color: white;text-align: center;">{{partner.first_name}} {{ partner.last_name }}</h1>
                  </v-col>
                  <v-col cols="12" style="display: flex; justify-content: space-around" >
                    <p :class="`text-`" style="color: white;margin-top: -25px;">{{partner.position}}</p>
                  </v-col>
                  <v-col cols="12"  style="display: flex;margin-top: -35px; justify-content: space-around; margin-bottom: 35px;" >
                    <h5 class="h4 font-weight-medium" style="color: white;opacity: 0.55;">{{partner.business_name}}</h5>
                  </v-col>
                </v-row>
                </div>
                <v-row style="background-color: rgb(230, 230, 230);margin: 0px;">
                  <v-col cols="6" md="6" style="padding-right: 0px;padding: 0px;" v-for="item in contact_points" :key="item.id" v-if="item.type">
                      <a class="cards" >
                        <v-row style="display: flex; justify-content: space-around;margin-top: -75px;" >
                                <v-col cols="12" style="display: flex; justify-content: space-around;margin-top: 15px; padding: 0px;" >
                                  <v-icon size="35" class="material-icons-round" style="color:black" >
                                      {{item.type ? getIcon(item.type) : ""}}
                                    </v-icon>
                                      </v-col>
                                    
                                        <v-col cols="12"  style="display: flex; justify-content: space-around; margin-bottom: 0px;" >
                                          <h5 class="h4 font-weight-medium  text-uppercase" style="color: black;">{{item.type}}</h5>
                                        </v-col>
                                        <v-col cols="12" style="display: flex; justify-content: space-around" >
                                          <p :class="`text-`" style="color: black;margin-top: -25px; margin-bottom: -30px;width: 149px;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;">{{item.value}}</p>
                                        </v-col>
                                       
                                      </v-row>
                      </a>
                </v-col>
               
                </v-row>
                <v-row style="padding: 20px;padding-right: 35px;">
                  
                  <v-col cols="12" style=" padding-bottom: 0px;">
                    <v-btn
                        outlined
                        button
                        class="ma-2"
                        @click="overlay = true"
                        block 
                        tile
                        large
                        :color="partner.color"
                      >
                        SCAN MY QR CODE
                      </v-btn>
                  </v-col>
                  <v-col cols="12" style="padding-bottom: 0px;">
                    <v-btn
                        class="ma-2"
                        outlined
                        button
                        block 
                        tile
                        large
                        :color="partner.color"
                      >
                        ADD TO CONTACTS
                      </v-btn>
                  </v-col>
                  <v-col cols="12" style="padding-bottom: 0px;">
                    <v-btn
                        class="ma-2"
                        outlined
                        button
                        block 
                        tile
                        large
                        :color="partner.color"
                      >
                       ADD TO HOME SCREEN
                      </v-btn>
                  </v-col>
                  <v-col cols="12" style="padding-bottom: 0px;">
                    <v-btn
                        class="ma-2"
                        outlined
                        button
                        block 
                        tile
                        large
                        :color="partner.color"
                      >
                        SHARE MY CARD
                      </v-btn>
                  </v-col>
                  <v-col cols="12" style="padding-bottom: 0px;margin-bottom: 15px;">
                    <v-btn
                        class="ma-2"
                        outlined
                        button
                        block 
                        tile
                        large
                        :color="partner.color"
                      >
                        START YOUR 7-DAY FREE TRIAL
                      </v-btn>
                  </v-col>
                  
                  
                  
                  </v-row>
                  
                  
                  
                  </v-card>
                  </div>
                  </v-col>
                  <v-col cols="12" md="4"></v-col>
                </v-row>
               

                <div style="text-align: center;margin-top:-250px"> 
                

                <v-btn color="grey"
                  large @click=" e1 = 3 " class="
                  
                  font-weight-bolder
                  btn-default

                ">
                  Back
                </v-btn>
                <v-btn
                  color="#4CAF50"
                  large
                  @click="checkForm()"
                  :loading="loadingSaveButton"
                  style="text-transform :none!important;"
                  class="
                    ml-2
                    font-weight-bolder
                    btn-default

                  "
                              
                            >
                  Next: Confirm & Submit  <v-icon class="material-icons-round" style="font-size: 35;">arrow_forward</v-icon>
                </v-btn>
                </div>
              </v-stepper-content> -->
            </v-stepper>
         </div>
      </v-col>
    </v-row>
    </div>
    <v-dialog
      v-model="showDialog"
      width="700"
    >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
          Add contact point
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <v-select
            label="Type"
            outlined
            dense
            @change="slectedItem.value = '',renderInputField(slectedItem)"
            class="font-size-input input-style"
            v-model="slectedItem.type"
            :items="['Phone','Whatsapp','SMS/Text','Email','Website','Zoom','Event','Instagram','Snapchat','Twitter','Facebook','LinkedIN','Clubhouse','Cash App','Venmo','Zelle Transfer','Peymynt','Paypal','Stripe']"
            ></v-select>
            <v-text-field
            label="Value"
            color="#e91e63"
            required
            outlined
            dense
            :disabled="!slectedItem.type"
            @input="populateText(slectedItem)"
            v-model="slectedItem.value"
            class="font-size-input input-style"
          ></v-text-field>
          <v-text-field
            label="Link"
            color="#e91e63"
            required
            outlined
            disabled
            dense
            v-model="slectedItem.link"
            class="font-size-input input-style"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn
            color="green"
            outlined
            :disabled="loadingAssign"
            @click="slectedItem.type = '',slectedItem.value ='', showDialog = false, slectedItem.link = ''"
          >
            Close
          </v-btn>
          <v-btn
            color="red"
            dark
            :disabled="loadingAssign"
            @click="slectedItem.value = '',slectedItem.link = '',slectedItem.type = '', showDialog = false"
          >
            Remove
          </v-btn>
          <v-btn
            color="blue"
            dark
            :disabled="loadingAssign"
            @click="validateContact(slectedItem)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  
    </div>
 </v-app>
</template>

<script>
import DateDropdown from 'vue-date-dropdown'
import VueRecaptcha from 'vue-recaptcha'
import moment from 'moment'
import AvatarCropper from 'vue-avatar-cropper'
import ClipLoader from 'vue-spinner/src/PulseLoader.vue';
// import Vue from 'vue'
export default {
    components: {
        DateDropdown,
        'vue-recaptcha' : VueRecaptcha,
        'RotateLoader': ClipLoader,AvatarCropper
    },
    data()  {
        return {
            address: '',
            dob:'',
            age: 0,
            e1 : 1,
            step : 1,
            loadingSaveButton : false,
            verifyLoad: false,
            verfiedFlag : false,
            valid : true,
            loadingAssign : false,
            verifyUser: '',
            showDialog :false,
            slectedItem : '',
            newItem : {
              type : '',
              value : '',
            },
            showconfpassword : false,
            cropperOutputMime : '',
            changeTrigger : false,
            image : [],
            agree : false,
            preview : '',
            base_url_img : process.env.VUE_APP_WASBI_URL,
            showpassword : false,
            rules: [
                value => !!value || 'Required.',
            ],
            rules_name: [
            v => !!v || 'Field is required',
            ],
            rules_business: [
              v => /^[\w\s]+$/.test(v) || 'Name must only contain alphabets' 
            ],
            temp_email  : localStorage.getItem('temp_email') ? true : false,
            validGeneral : true,
            validAddress : true,
            validSocial : true,
            next_url : '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            loadingFlag : false,
            grant : {},
            partner : {
                first_name : "",
                last_name : '',
                middle_name: '',
                email : localStorage.getItem('temp_email') ? localStorage.getItem('temp_email') : '',
                is_email : false ,
                phone_h : '',
                phone_w : '',
                phone : '',
                flag : 'ref',
                dob: '',
                is_exist : "",
                ssn : '',
                color : 'orange',
                phone : '',
                fax : '',
                
                address :'',
                address1: '',
                city : '',
                state : '',
                zip : '',
                country :'United States',
                memo : '',
                is_verified:  0,
                document : '',
                suffix:'',
                recaptcha: '',
                user_name: "",
                password: '',
                confirm_password: "",
                crc_id : "",
                driving_license_number : '',
                driving_license_state : "",
                process_email : "",
                process_email_password : "",
                position :'',
                business_name: ''

            },
            photo : '',
            contact_points : [
                              {
                                id: 1,
                                type : '',
                                label : '',
                                link : '',
                                value : ''
                              },
                              {
                                id: 2,
                                type : '',
                                label : '',
                                link : '',
                                value : ''
                              },
                              {
                                id: 3,
                                type : '',
                                label : '',
                                link : '',
                                value : ''
                              },
                              {
                                id: 4,
                                type : '',
                                label : '',
                                link : '',
                                value : ''
                              },
                              {
                                id: 5,
                                type : '',
                                label : '',
                                link : '',
                                value : ''
                              },
                              {
                                id: 6,
                                type : '',
                                label : '',
                                link : '',
                                value : ''
                              },
                            ],
                url : '',
                company : '',
            isDisable :false,
            loading: false,
            isDark: true,
            existOption: ["Yes","No"],
            stateOptions: [
                { name: 'ALABAMA', abbreviation: 'AL'},
                { name: 'ALASKA', abbreviation: 'AK'},
                { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                { name: 'ARIZONA', abbreviation: 'AZ'},
                { name: 'ARKANSAS', abbreviation: 'AR'},
                { name: 'CALIFORNIA', abbreviation: 'CA'},
                { name: 'COLORADO', abbreviation: 'CO'},
                { name: 'CONNECTICUT', abbreviation: 'CT'},
                { name: 'DELAWARE', abbreviation: 'DE'},
                { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                { name: 'FLORIDA', abbreviation: 'FL'},
                { name: 'GEORGIA', abbreviation: 'GA'},
                { name: 'GUAM', abbreviation: 'GU'},
                { name: 'HAWAII', abbreviation: 'HI'},
                { name: 'IDAHO', abbreviation: 'ID'},
                { name: 'ILLINOIS', abbreviation: 'IL'},
                { name: 'INDIANA', abbreviation: 'IN'},
                { name: 'IOWA', abbreviation: 'IA'},
                { name: 'KANSAS', abbreviation: 'KS'},
                { name: 'KENTUCKY', abbreviation: 'KY'},
                { name: 'LOUISIANA', abbreviation: 'LA'},
                { name: 'MAINE', abbreviation: 'ME'},
                { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                { name: 'MARYLAND', abbreviation: 'MD'},
                { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                { name: 'MICHIGAN', abbreviation: 'MI'},
                { name: 'MINNESOTA', abbreviation: 'MN'},
                { name: 'MISSISSIPPI', abbreviation: 'MS'},
                { name: 'MISSOURI', abbreviation: 'MO'},
                { name: 'MONTANA', abbreviation: 'MT'},
                { name: 'NEBRASKA', abbreviation: 'NE'},
                { name: 'NEVADA', abbreviation: 'NV'},
                { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                { name: 'NEW JERSEY', abbreviation: 'NJ'},
                { name: 'NEW MEXICO', abbreviation: 'NM'},
                { name: 'NEW YORK', abbreviation: 'NY'},
                { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                { name: 'OHIO', abbreviation: 'OH'},
                { name: 'OKLAHOMA', abbreviation: 'OK'},
                { name: 'OREGON', abbreviation: 'OR'},
                { name: 'PALAU', abbreviation: 'PW'},
                { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                { name: 'PUERTO RICO', abbreviation: 'PR'},
                { name: 'RHODE ISLAND', abbreviation: 'RI'},
                { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                { name: 'TENNESSEE', abbreviation: 'TN'},
                { name: 'TEXAS', abbreviation: 'TX'},
                { name: 'UTAH', abbreviation: 'UT'},
                { name: 'VERMONT', abbreviation: 'VT'},
                { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                { name: 'VIRGINIA', abbreviation: 'VA'},
                { name: 'WASHINGTON', abbreviation: 'WA'},
                { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                { name: 'WISCONSIN', abbreviation: 'WI'},
                { name: 'WYOMING', abbreviation: 'WY' }
            ],
            year : new Date().getFullYear(),
            form: {
            // Some form values...
                recaptcha: '',
            },
            sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
        }
    },
    created () {
        this.url = window.location.origin;//this.$route.params.url ? this.$route.params.url : '';
        if(this.url)
            this.getCompanyInfo(this.url)
        else
            this.loadingFlag = true;
    },
    watch: {
      dob (val) {
        this.age = val ?  moment().diff(this.dob, 'years') :0
        console.log(this.age);
        if(isNaN(this.age))
          this.age = 0;
      },
      // partner: {
      //   handler: function(newValue) {
      //         this.partner.first_name = newValue.first_name.replace(/\W/g, "");
      //         this.partner.last_name = newValue.last_name.replace(/\W/g, "");
      //       },
      //       deep: true
        
      // },
    },
    beforeCreate: function () {
        if (localStorage.getItem('token')) {
            if(localStorage.getItem('is_admin') && localStorage.getItem('is_admin') == 1){
                this.$router.push('/admin/dashboard')
            }else
                this.$router.push('/dashboard')
        }
    },
    methods : {
      renderInputFieldByType(type){
      let label = '';
      let link = '';
      switch (type) {
        case 'Phone':
            label = 'Phone';
            link = 'tel:';
          break;
          case 'Whatsapp':
            label = 'Whatsapp';
            link = 'https://wa.me/';
          break;
          case 'Email':
            label = 'Email';
            link = 'mailto:';
          break;
          case 'SMS/Text':
            label = 'SMS/Text';
            link = 'sms:';
          break;
          case 'Website':
            label = 'Website';
            link = 'https://';
          break;
          case 'Zoom':
            label = 'Zoom';
            link = '';
          break;
          case 'Event':
            label = 'Event';
            link = '';
          break;
          case 'Instagram':
            label = 'Instagram';
            link = 'https://www.instagram.com/';
          break;
          case 'Snapchat':
            label = 'Snapchat';
            link = 'https://www.snapchat.com/add/';
          break;
          case 'Twitter':
            label = 'Twitter';
            link = 'https://www.twitter.com/';
          break;
          case 'Facebook':
            label = 'Facebook';
            link = 'https://www.facebook.com/';
          break;
          case 'LinkedIN':
            label = 'LinkedIN';
            link = 'https://www.linkedin.com/in/';
          break;
          case 'Clubhouse':
            label = 'Clubhouse';
            link = 'https://www.clubhouse.com/';
          break;
          case 'Cash App':
            label = 'Cash App';
            link = 'https://cash.app/';
          break;
          case 'Venmo':
            label = 'Venmo';
            link = 'https://venmo.com/';
          break;
          case 'Zelle Transfer':
            label = 'Zelle Transfer';
            link = '';
          break;
          case 'Peymynt':
            label = 'Peymynt';
            link = '';
          break;
          case 'Paypal':
            label = 'Paypal';
            link = '';
          break;
          case 'Stripe':
            label = 'Stripe';
            link = '';
          break;
        default:
          break;
      }
      return link;
    },
    scrollToBottom() {
      // Scroll smoothly to the bottom using scrollIntoView
      this.$refs.bottom.scrollIntoView({ behavior: 'smooth' });
    },
    renderInputField(item){
      let label = '';
      let link = '';
      switch (item.type) {
        case 'Phone':
            label = 'Phone';
            link = 'tel:';
          break;
          case 'Whatsapp':
            label = 'Whatsapp';
            link = 'https://wa.me/';
          break;
          case 'Email':
            label = 'Email';
            link = 'mailto:';
          break;
          case 'SMS/Text':
            label = 'SMS/Text';
            link = 'sms:';
          break;
          case 'Website':
            label = 'Website';
            link = 'https://';
          break;
          case 'Zoom':
            label = 'Zoom';
            link = '';
          break;
          case 'Event':
            label = 'Event';
            link = '';
          break;
          case 'Instagram':
            label = 'Instagram';
            link = 'https://www.instagram.com/';
          break;
          case 'Snapchat':
            label = 'Snapchat';
            link = 'https://www.snapchat.com/add/';
          break;
          case 'Twitter':
            label = 'Twitter';
            link = 'https://www.twitter.com/';
          break;
          case 'Facebook':
            label = 'Facebook';
            link = 'https://www.facebook.com/';
          break;
          case 'LinkedIN':
            label = 'LinkedIN';
            link = 'https://www.linkedin.com/in/';
          break;
          case 'Clubhouse':
            label = 'Clubhouse';
            link = 'https://www.clubhouse.com/';
          break;
          case 'Cash App':
            label = 'Cash App';
            link = 'https://cash.app/';
          break;
          case 'Venmo':
            label = 'Venmo';
            link = 'https://venmo.com/';
          break;
          case 'Zelle Transfer':
            label = 'Zelle Transfer';
            link = '';
          break;
          case 'Peymynt':
            label = 'Peymynt';
            link = '';
          break;
          case 'Paypal':
            label = 'Paypal';
            link = '';
          break;
          case 'Stripe':
            label = 'Stripe';
            link = '';
          break;
        default:
          break;
      }
      item.link = link
      //return link;
    },
      handleUploaded(cropper) {
      let self = this;
          let image = cropper.getCroppedCanvas().toDataURL(this.cropperOutputMime);
          let img = this.dataURLtoFile(image,'asdasd.jpg');
          self.preview = image;
          self.photo = img
          

        },
        dataURLtoFile(dataurl, filename) {
 
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
        },
      gotoPrivacy(){
        window.open('/privacy/'+this.$route.params.url, "_blank");
      },
        getCompanyInfo(url){
  axios({
          method: "POST",
           
          url: "/api/get-company-info",
          data: {
            url: url
          }
        })
          .then(response => {
            console.log(response.data.company);
            this.company = response.data.company;
            this.loadingFlag = true;
          })

},
        onCaptchaVerified: function (recaptchaToken) {
        this.partner.recaptcha = recaptchaToken
        this.validateCaptcha = true
        },
        onCaptchaExpired: function () {
        this.$refs.recaptcha.reset();
        },
         getAddressData3: function (addressData3, placeResultData, id) {
            this.grant.grant_address = addressData3.name;
        },
        getAddressData2: function (addressData2, placeResultData, id) {
            this.grant.grant_business_address = addressData2.name;
        },
        nameKeydown(e) {
          if (/^\W$/.test(e.key)) {
            e.preventDefault();
          }
        },
        getAddressData: function (addressData, placeResultData, id) {
            this.partner.address = addressData.name;
            this.partner.city = addressData.locality;
            this.partner.state = addressData.administrative_area_level_1;
            this.partner.zip = addressData.postal_code
            console.log(this.partner);
        },
        isJson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        verifyCrc(){
            let self = this;
            let flag = 1;
            if (!this.partner.crc_id) {
                Vue.$toast.error('Customer ID is required', {
                    position: 'top-right'
                });
                flag = 0;
            }else{
                self.verifyLoad = true;
                axios({
                method: "POST",
                 
                url: "/api/auth/verify-crc",
                data:{crc_id : self.partner.crc_id},
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    console.log(response);
                    if(response.data.data.success == 'True'){
                        self.verifyUser = response.data.data.result;
                        self.verfiedFlag = true;
                    }else{
                        Vue.$toast.error('Invalid customer id', {
                            position: 'top-right'
                        });
                    }
                    self.verifyLoad = false
                })
                .catch(err => {
                    // console.log(JSON.parse(err.response.data));
                    self.verifyLoad = flase;

                });
            }
        },
        validateForm(){
          if(this.$refs.form.validate()){
            if(!this.photo){
              Vue.$toast.error('Image is required', {
                    position: 'top-right'
                });
                return false
            }
            return true;
          }else{
            return false;
          }
          
        },
        getIcon(id){
            if(id == 'Phone')
              return 'fa-phone';
            if(id == '')
              return 'fa-plus';
            if(id == 'Email')
              return 'mail';
            if(id == 'Whatsapp')
              return 'fab fa-whatsapp';
            if(id == 'SMS/Text')
              return 'fas fa-comments';
            if(id == 'Website')
              return 'fas fa-link';
            if(id == 'Instagram')
              return 'fab fa-instagram';
            if(id == 'Snapchat')
              return 'fab fa-snapchat';
            if(id == 'Twitter')
              return 'fab fa-twitter';
            if(id == 'Facebook')
              return 'fab fa-facebook';
            if(id == 'LinkedIN')
              return 'fab fa-linkedin';
            else
              return 'fas fa-link';
          },
        autofill(){
            let self = this;
            self.partner.first_name = self.verifyUser.firstname
            self.partner.last_name = self.verifyUser.lastname
            if(!self.temp_email)
                self.partner.email = self.verifyUser.email
            self.partner.middle_name = self.verifyUser.middlename
            self.partner.phone_m = self.verifyUser.phone_mobile
            self.partner.phone_h = self.verifyUser.phone_home
            self.partner.phone_w = self.verifyUser.phone_work
            self.partner.ssn = self.verifyUser.ssno
            self.address = self.verifyUser.street_address
            if(self.verifyUser.state){
                self.stateOptions.forEach(element => {
                    if(self.verifyUser.state.toUpperCase()  == element.name)
                        self.partner.state = element.abbreviation
                });
            }
            self.partner.zip = self.verifyUser.zip
            self.partner.city = self.verifyUser.city
            if(self.verifyUser.birth_date){
                var temp_dob = self.verifyUser.birth_date.split("/");
                self.partner.dob = temp_dob[1]+'.'+temp_dob[0]+'.'+temp_dob[2]
                console.log(self.partner.dob);
            }
            
        },
        gotoNext(){
          window.location.replace(this.next_url);
         },
        validateContact(item){
          if(!item.type)
            Vue.$toast.error('Type is required', {
                    position: 'top-right'
                });
          else if(!item.value){
            Vue.$toast.error('Value is required', {
                    position: 'top-right'
                });
          }else if(!item.link){
            Vue.$toast.error('Link is required', {
                    position: 'top-right'
                });
          }else{
            this.showDialog = false;
          }
        },
        populateText(item){
          let oldLink = this.renderInputFieldByType(item.type)
          item.link = oldLink+item.value
        },
        getParentDomain() {
          const hostname = window.location.hostname;
          const domainParts = hostname.split('.');
          
          // Assuming the domain is of the form subdomain.example.com
          if (domainParts.length >= 2) {
              const parentDomain = domainParts.slice(-2).join('.');
              return parentDomain;
          } else {
              return hostname; // For cases like localhost or single part domains
          }
      },
        checkForm(){
            let self = this;
            let flag = 1;            
            if (!this.partner.first_name) {
                Vue.$toast.error('First Name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.last_name && flag == 1) {
                Vue.$toast.error('Last Name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.email && this.partner.is_email == false && flag == 1) {
                Vue.$toast.error('Email is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            

           
            let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (reg.test(this.partner.email) == false && this.partner.is_email == false  && flag == 1) {
                Vue.$toast.error('Invalid email format', {
                    position: 'top-right'
                });
                flag = 0;
            }

            
            this.partner.user_name = this.partner.email;
            if (flag == 1) {
                this.loadingSaveButton = true;
                let is_verified = localStorage.getItem('email_verified') ? localStorage.getItem('email_verified') : 0
                this.partner.company_id = this.company ? this.company.id : '';
                this.isDark = false;
                this.isDisable = true;
                const data = new FormData();
                for (var key in self.partner) {
                  data.append(key,self.partner[key]);
                }
                data.append('file_photo', this.photo);
                
                axios({
                method: "POST",
                url: "/api/auth/register-card",
                data:data,
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    console.log(response);
                    if(response.data.flag && response.data.flag == 3){
                          Vue.$toast.error('Client exist with same email address!', {
                            position: 'top-right'
                            });
                            this.isDark = true;
                            this.isDisable = false;
                            this.loadingSaveButton = false;
                      }else{
                        let result = response.data.data;
                        localStorage.setItem("token", response.data.token.token);
                        localStorage.setItem("user_id", result.user_id);
                        localStorage.setItem("email", result.email);
                        localStorage.setItem("phone", result.phone_m);
                        localStorage.setItem("name", result.first_name+' '+result.last_name);
                        localStorage.setItem("is_admin",0);
                        localStorage.setItem("role", JSON.stringify(result.roles[0]));
                        const domain = response.data.domain
                        let url = 'http://'+domain+'.'+this.getParentDomain();
                        window.open(url);
                        //self.$router.push('/login');
                        // if(is_verified == 0)
                        //     Vue.$toast.success('You have successfuly registered. Please check your email for login', {
                        //     position: 'top-right'
                        //     }); 
                        // else
                        //     Vue.$toast.success('You have successfuly registered. Please check your email for login', {
                        //     position: 'top-right'
                        //     }); 

                        this.$router.push("/");
                        // //location.reload();
                        // this.isDark = true;
                        // this.loadingSaveButton = false;
                        // this.isDisable = false;         
                        // this.next_url = url+'/login';     
                        // this.step = 3;   
                      }   
                })
                .catch(err => {
                  console.log(err);
                    // console.log(JSON.parse(err.response.data));
                    this.isDark = true;
                    this.isDisable = false;
                    this.loadingSaveButton = false;
                    if(err.response.data.flag){
                        if(err.response.data.flag == 2){
                            Vue.$toast.error('Company exist with same email address!', {
                            position: 'top-right'
                            });
                        }
                        if(err.response.data.flag == 3){
                          Vue.$toast.error('Client exist with same email address!', {
                            position: 'top-right'
                            });
                        }
                    }else{
                      let data = self.isJson(err.response.data) ? JSON.parse(err.response.data) : (err.response.data);
                      if(data.email){
                          Vue.$toast.error(data.email[0], {
                          position: 'top-right'
                          });
                      }else if(data.password){
                          Vue.$toast.error(data.password[0], {
                          position: 'top-right'
                          });
                      }
                      else if(data.user_name){
                          Vue.$toast.error(data.user_name[0], {
                          position: 'top-right'
                          });
                      }
                      else{
                          Vue.$toast.error(data, {
                          position: 'top-right'
                          });
                      }
                  }
                    self.onCaptchaExpired()

                });

            }
        }
    }
}
</script>

<style>
/* .image-logo2{
position: absolute;
margin: -78px -5px -4px 170px;
} */
.date-dropdown-container{
    height: 85px;
}
.date-dropdown-select{
    border: 1px solid #9E9E9E!important;
    padding: 15px!important;
}
.required_span{
    color:red;
}
.color-card{
  height: 60px;
  width: 60px;
  margin: 0 0 0 0px;
  padding: 5 -6 -5px 11px;
  top: -76px;
  left: -48px;
  border: 7px solid #e2e6ec;
}
.color-span{
margin: -50px 0 0 -11px;
position: absolute;
}
.color-check{
  /* margin: 28px 5px 3px 22px; */
  /* padding: 0 0 0 15px; */
  margin-right: 0px!important;
  margin-top: 66px!important;
}
.col-color{
  display: flex;
  flex-wrap: wrap;
  font-size: 0px;
  justify-content: space-around;
  margin: 0 auto;
  user-select: none;
}
.phone-view{
  border-color: rgb(238, 238, 238);
  top: 22px;
  left: 26px;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: unset !important;;
  width: 349px!important;
  height: 748px!important;
  background-color: white;
  position: absolute;
}
</style>