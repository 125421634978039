<template>
  <div>
  <v-btn
              color="blue"
              dark
              class="mb-2 mr-3"
              outlined
              @click="addDesserts()"
            >
              Add New
            </v-btn><v-btn
              color="blue"
              dark
              :loading="loadingDefault"
              class="mb-2 mr-3"
              outlined
              v-if="flag == 'default' && desserts.length > 0"
              @click="applyDefault()"
            >
              Apply to all inventory
            </v-btn>
    <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Description
          </th>
          <th class="text-left">
            Category
          </th>
          <th class="text-left">
            Cost
          </th>
          <th class="text-left">
            Note
          </th>
          <th class="text-left">
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item,key) in desserts"
          :key="key"
        >
          <td>
          <v-select v-model="item.description" :items="settings.vehicle_packs_description" item-text="item"
                item-value="item" @change="renderInputField(key,item)" :disabled="item.default"></v-select>
          </td>
          <td>
          <v-select v-model="item.category" :items="settings.vehicle_packs_category" item-text="item"
                item-value="item" :disabled="item.default"></v-select>
          </td>
          <td><v-text-field prefix="$" v-model="item.cost" :disabled="item.default"></v-text-field></td>
          <td><v-text-field v-model="item.note" :disabled="item.default"></v-text-field></td>
          <td><v-icon style="color:red" @click="deleteItem(key)" v-if="!item.default">fa fa-trash</v-icon></td>

        </tr>
      </tbody>
    </template>
  </v-simple-table>
  <v-row class="mt-4">
  <v-spacer></v-spacer>
  <v-btn
  v-if="flag == 'multiple'"
              color="blue"
              dark
              @click="saveMultiple()"
              class="mb-2 mr-3"
            >
              save
            </v-btn>
            <v-btn
            v-else-if="flag == 'default'"
                        color="#4CAF50"
                        large
                        @click="saveDefault()"
                        class="
                        float-right
                          font-weight-bolder
                          btn-default
                          mb-5
                          px-8
                          ms-auto
                          mr-2
                        "
                        
                      >
                        Save
                      </v-btn>
            <v-btn
  v-else
              color="blue"
              dark
              @click="save()"
              class="mb-2 mr-3"
            >
              save
            </v-btn>
            </v-row>
            </div>
</template>
<script>
export default {
  name: "app-bar-authentication",
  props : ["data","settings","selectedItemsInventory","flag"],
  data() {
    return {
      pages: [
        {
          title: "Sign Up Basic",
          link: "/pages/authentication/signup/basic",
        },
        {
          title: "Sign Up Cover",
          link: "/pages/authentication/signup/cover",
        },
        {
          title: "Sign Up Illustration",
          link: "/pages/authentication/signup/illustration",
        },
      ],
      dialog: false,
      desserts: [],
      dialogDelete: false,
      loadingDefault: false,
      headers: [
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'description',
        },
        { text: 'Category', value: 'category' },
        { text: 'Cost', value: 'cost' },
        { text: 'Note', value: 'note' },
       
      ],
      editedIndex: -1,
      editedItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
    }
  },
  mounted() {
    if(this.flag == 'default'){
      this.desserts = this.data ? this.data : [{
          description: '',
          category: '',
          cost: '',
          note: '',
      }];  
    }else{      
      if(this.settings.default_packs && !this.data.packs_info){
        this.settings.default_packs.forEach(val=>{
          val.default = 1;
          this.desserts.push(val);
        })
      }else{
        this.desserts = this.data.packs_info ? JSON.parse(this.data.packs_info) : [{
            description: '',
            category: '',
            cost: '',
            note: '',
        }];  
      }
    }
  },
  computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    methods: {
      initialize () {
        
      },
        addDesserts(){
            this.desserts.push({
        description: '',
        category: '',
        cost: '',
        note: '',
        });
        },
      editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (key) {
        this.desserts.splice(key, 1)
      },

      deleteItemConfirm () {
        this.desserts.splice(this.editedIndex, 1)
        this.closeDelete()
      },
    renderInputField(key,item){
        this.settings.vehicle_packs_description.forEach(element => {
          if(this.desserts[key].description == item.description){
            this.desserts[key].cost = element.cost
          }
        })
    },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        
        axios
              .post("/api/change-vehicle-packs/"+this.data.id,{packs : this.desserts},this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Saved successfully', {
                      position: 'top-right'
                      });
                   this.$emit('notify');
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
      },
      saveDefault () {
        
        this.$emit('notify',this.desserts);
        
      },
      saveMultiple () {
        
        axios
              .post("/api/mass-save-vehicle",{packs : this.desserts,flag: 'pack',ids : this.selectedItemsInventory},this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Saved successfully', {
                      position: 'top-right'
                      });
                   this.$emit('notify');
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
      },
      applyDefault() {
        if(confirm('Are you sure?')){
          this.loadingDefault = true;
        axios
              .post("/api/apply-default-packs",{packs : this.desserts},this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Applied successfully', {
                      position: 'top-right'
                      });
                   this.loadingDefault = false;
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
        }
    },
    },
    
};
</script>
