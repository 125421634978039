<template>
  <v-app class="bg-white">
    <v-overlay :value="loader">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-main class="auth-pages" v-if="company" >
    
          <div class="footer" id="myHeader" :style="getStyle('footer')">
            <div class="elementor-container elementor-column-gap-default" style="width: 100%;">
              <v-row style="margin:0px!important;">
      <v-col style="padding: 1px;">
					<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-a828a20" data-id="a828a20" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-88f37c7 elementor-view-default elementor-widget elementor-widget-icon" data-id="88f37c7" data-element_type="widget" data-widget_type="icon.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-wrapper">
			<a class="elementor-icon" :href="'/'">
			<i aria-hidden="true" class="fa fa-id-card" :style="vcard.header_color=='Light Text' ? 'color:black!important' : 'color:white!important'"></i>			</a>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-f383dda elementor-widget elementor-widget-heading" data-id="f383dda" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h6 class="elementor-heading-title-card elementor-size-default" ><a :href="'/'" :style="vcard.header_color=='Light Text' ? 'color:black!important' : 'color:white!important'">Card</a></h6>		</div>
				</div>
					</div>
		</div>
    </v-col>
    <v-col style="padding: 1px;">
				<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-1eed04c" data-id="1eed04c" data-element_type="column" id="share-my-card" @click="overlay = true">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-61dd731 elementor-view-default elementor-widget elementor-widget-icon" data-id="61dd731" data-element_type="widget" data-widget_type="icon.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-wrapper">
			<a class="elementor-icon" href="#">
			<i aria-hidden="true" class="fa fa-qrcode" :style="vcard.header_color=='Light Text' ? 'color:black!important' : 'color:white!important'"></i>			</a>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-08fcb6e elementor-widget elementor-widget-heading" data-id="08fcb6e" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h6 class="elementor-heading-title-card elementor-size-default"><a href="#" @click="overlay = true" :style="vcard.header_color=='Light Text' ? 'color:black!important' : 'color:white!important'">Scan</a></h6>		</div>
				</div>
					</div>
		</div>
    </v-col>
    <v-col style="padding: 1px;">
				<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-d7c2c7a" data-id="d7c2c7a" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-5f80995 elementor-view-default elementor-widget elementor-widget-icon" data-id="5f80995" data-element_type="widget" data-widget_type="icon.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-wrapper">
			<a class="elementor-icon" :href="`tel:${company.phone_m}`">
			<i aria-hidden="true" class="fas fa-phone-alt" :style="vcard.header_color=='Light Text' ? 'color:black!important' : 'color:white!important'"></i>			</a>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-2432e95 elementor-widget elementor-widget-heading" data-id="2432e95" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h6 class="elementor-heading-title-card elementor-size-default"><a :href="`tel:${company.phone_m}`" :style="vcard.header_color=='Light Text' ? 'color:black!important' : 'color:white!important'">Direct Call</a></h6>		</div>
				</div>
					</div>
		</div>
    </v-col>
    <v-col v-if="event_value">
				<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-5b2a404" data-id="5b2a404" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-57f7405 elementor-view-default elementor-widget elementor-widget-icon" data-id="57f7405" data-element_type="widget" data-widget_type="icon.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-wrapper">
			<a class="elementor-icon" :href="event_value" target="_blank">
			<i aria-hidden="true" class="fas fa-calendar-check"></i>			</a>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-eb196fc elementor-widget elementor-widget-heading" data-id="eb196fc" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h6 class="elementor-heading-title-card elementor-size-default"><a :href="event_value" target="_blank">Events</a></h6>		</div>
				</div>
					</div>
		</div>
    </v-col>
    </v-row>
							</div>
          </div>
                <v-row style="display: flex; justify-content: space-around;margin:0px!important;" >
              <v-col cols="12" style="display: flex; justify-content: space-around; margin-top: -15px; " >
                <img :height="this.$vuetify.breakpoint.xs ? 100 : 150" class="image-logo2" src="@/assets/img/logo.png">
                </v-col>
               
                <v-col
                      v-if="company_settings.display_inventory_header && company_settings.display_inventory_header.includes(company.user_id)"
                      cols="12"
                      md="12"
                      style="padding-left: 0px!important; padding-right: 0px!important;margin-top: -25px!important;"
                    >
                    <div class="bg-cta text-white text-center">
                          <div class="container">
                          <div class="brands-top">
                          <div class="brands-top-item mr-10">
                          <p class="lead text-black margin-bottom-x">{{count_make}}</p>
                          <p class="margin-bottom-x sub-h">Brands</p>
                          </div>
                          <div class="brands-top-item">
                          <p class="lead text-black margin-bottom-x">{{count_dealer}}</p>
                          <p class="margin-bottom-x sub-h">Dealerships</p>
                          </div>
                          <!-- <div class="brands-top-item">
                          <p class="lead text-black margin-bottom-x">5409</p>
                          <p class="margin-bottom-x sub-h">New Vehicles</p>
                          </div> -->
                          <div class="brands-top-item ">
                          <p class="lead text-black margin-bottom-x">{{count_vehicle}}</p>
                          <p class="margin-bottom-x sub-h">Pre-Owned Vehicles</p>
                          </div>
                          </div>
                          </div>
                          </div><!-- End Bonus Block Section / SiteWide Header Content -->
                          </div>
                      
                    </v-col>
                    <v-col cols="12" md="12" style="margin-bottom: -12px !important;padding-left: 0px!important;padding-right: 0px!important;margin-top: -25px;" v-if="company_settings.display_inventory_header && company_settings.display_inventory_header.includes(company.user_id)">
                    <section class="introSec bg-alt2" style="justify-content: center;display: flex;" :style="introStyle">
                      <div class="searchSec margin-auto">
                        <h1 class="text-h4 text-center text-white font-weight-bold" style="letter-spacing: 1px !important;">SHOP BY VEHICLE</h1>
                        <h1 class="text-h6 text-center text-white font-weight-bold">{{count_vehicle}} Vehicles Available</h1>
                        <v-select
                          :items="type_option"
                          v-model="filter.type"
                          label="Type"
                          class="inventory-filter-select mt-5"
                          filled
                        ></v-select>
                        <v-select
                          :items="make_option"
                          v-model="filter.make"
                          label="Make"
                          item-text="make"
                          item-value="make"
                          class="inventory-filter-select"
                          style="margin-top: -15px;"
                          filled
                        ></v-select>
                        <v-select
                          :items="model_option"
                          v-model="filter.model"
                          label="Model"
                          item-text="model"
                          item-value="model"
                          style="margin-top: -15px;"
                          class="inventory-filter-select"
                          filled
                        ></v-select>
                        <v-btn
                        outlined
                        block
                        tile
                        @click="search('')"
                        color="white"
                      >
                        Search
                      </v-btn>
                      <p class="text-center text-white mt-3 font-weight-bold" style="margin-bottom: 0px;cursor:pointer;" @click="reset">Reset</p>
                      </div>
                      </section>
                    
                    </v-col>

                    <v-col cols="12" md="12" style="margin-bottom: 40px !important;background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.9)), url('https://s3.us-central-1.wasabisys.com/quantumresolution/logo/blue-oil-paint-textured-background(1).jpg') no-repeat center top / cover;">
                     <h1 class="text-h3 mt-3 text-center font-weight-bold">Is your credit score above a 640?</h1>
                     <!-- <v-text-field
                            color="blue"
                            placeholder="Enter your credit score here"
                            ref="name"
                            solo
                            v-mask="'###'"
                            v-model="credit_score"
                            class="font-size-input input-style mt-3"
                          >
                          </v-text-field> -->
                          <div  style="justify-content: center;display: flex;text-align: center;">
                          <v-radio-group
                         
                              v-model="credit_score"
                              row
                            >
                              <v-radio
                                label="Yes"
                                :value="641"
                              ></v-radio>
                              <v-radio
                                label="No"
                                :value="630"
                              ></v-radio>
                            </v-radio-group>
                            </div>
                            <div  style="justify-content: center;display: flex;text-align: center;margin-top: -10px;">
                            <v-checkbox
                              class="checkbox-doc"
                              v-model="bhph_program"
                              :value="1"
                              :label="`AutoSyncX™️ BHPH Program`"
                            >   </v-checkbox>
                            </div>
                    <h1 v-if="credit_score" class="text-h3 mt-3 text-center font-weight-bold">Not sure where to start? Let's narrow it down.</h1>
                    <div :style="this.$vuetify.breakpoint.xs ? '' :'justify-content: space-around;display: flex;'" v-if="credit_score">
                    <div class="modelbar" :style="this.$vuetify.breakpoint.xs ? '' :'width: 500px;'">
                          <div class="modelbarItem" @click="search('Coupe')" style="cursor:pointer;">
                          <img src="@/assets/img/coupe.png" alt="Coupe" class="img-responsive margin-auto">
                          <p class="larger text-default">Coupe</p>
                          </div>
                          <div class="modelbarItem" @click="search('Convertible')" style="cursor:pointer;">
                          <img src="@/assets/img/convertible.png" alt="Convertible" class="img-responsive margin-auto">
                          <p class="larger text-default">Convertible</p>
                          </div>
                          <div class="modelbarItem" @click="search('Sedan')" style="cursor:pointer;">
                          <img src="@/assets/img/sedan.png" alt="Sedan" class="img-responsive margin-auto">
                          <p class="larger text-default">Sedan</p>
                          </div>
                          <div class="modelbarItem" @click="search('Hybrid/Electric')" style="cursor:pointer;">
                          <img src="@/assets/img/hybrid.png" alt="Hybrid/Electric" class="img-responsive margin-auto">
                          <p class="larger text-default">Hybrid/Electric</p>
                          </div>
                          <div class="modelbarItem" @click="search('Crossover/SUV')" style="cursor:pointer;">
                          <img src="@/assets/img/suv.png" alt="Crossover/SUV" class="img-responsive margin-auto">
                          <p class="larger text-default">Crossover/SUV</p>
                          </div>
                          <div class="modelbarItem" @click="search('Truck')" style="cursor:pointer;">
                          <img src="@/assets/img/truck.png" alt="Truck" class="img-responsive margin-auto">
                          <p class="larger text-default">Truck</p>
                          </div>
                          <div class="modelbarItem" @click="search('4WD/AWD')" style="cursor:pointer;">
                          <img src="@/assets/img/4wd.png" alt="4WD/AWD" class="img-responsive margin-auto">
                          <p class="larger text-default">4WD/AWD</p>
                          </div>
                          </div>
                          </div>
                    </v-col>
              </v-row>
          <v-row style="justify-content: center;margin-bottom: 150px;margin: 0;">
           
          <v-col cols="12" md="12" style="margin-bottom: 40px !important;">
            <h1 class="text-h6 mt-3 text-center">Powered By <strong>AutoSyncX™</strong></h1>
          </v-col>
          </v-row>
            <v-row style="justify-content: center;margin-bottom:50px!important;"></v-row>

                  <v-dialog
                      v-model="overlay"
                      width="500"
                    >
                    <img v-if="company"  class="" :src="base_url_img+'vcard/'+company.user_id+'-QR.png'" width="50">
                  
                  
                  </v-dialog>
           
    </v-main>    
  </v-app>
</template>
<script>
var slides = document.querySelectorAll(".slide");
var currentSlide = 0;


import AppBarBlurAuth from "@/components/AppBarBlurAuth";
import VCard from 'vcard-creator'
import { FadeTransition } from "vue2-transitions";
import Query from "@/components/Forms/Query.vue";
import ProfileCards from "@/components/Forms/VehicleCardList.vue";
export default {
  
  name: "page-layout",
  components: {
    AppBarBlurAuth,
    FadeTransition,
    ProfileCards,
    VCard,
    Query
  },
  data() {
    return {
       checkbox: false,
       showpassword : false,
       email : '',
      password : '',
      sales_agent_count: 0,
      search_rep: '',
      ref_agent_count: 0,
      phone : '',
      count_dealer: 0,
      bhph_program: 0,
      count_vehicle: 0,
      count_make: 0,
      credit_score: 641,
      introImage: require('@/assets/img/pexels-wavyvisuals-377312923-18948302.jpg'),
      loader : true,
      background: '',
      type_option: ['All','Used'],
      make_option: [],
      model_option: [],
      filter : {
        type : 'All',
        make : '',
        model : '',
        },
      vimeo_account: '',
      vimeo_id : '',
      reps_flag : false,
      company_settings : '',
      reps_users : [],
      overlay : false,
      index: 0,
      showPassword : false,
      zoom_value : '',
      base64Image :'',
      avatar_mimeType: '',
      event_value :'',
      dialog: false,
      color : '',
      videos : [],
      pageFlag: 'init',
      backgroundLogo: localStorage.getItem('admin_bg'),
      isDark :true,
      buttonFlag   : false,
      loadingLogo : false,
      url : '',
      items: [
        ],
      company : '',
      vcard : '',
      loadingFlag : false,
      base_url_img : process.env.VUE_APP_WASBI_URL,
      pageTitle: ''
    };
  },
  beforeCreate: function () {
    
},
created: function () {
      const domain = this.getSubdomain();
      this.getSettings()
      if(domain){
        this.getCompanyInfo(domain)
        this.getInventory(domain)
      }else
        this.$router.push('/login');
        

},
mounted : function () {

},
methods: {
  prevSlide(index) {
  if(index !=0 )
    this.index = index - 1;
  else
    this.index = this.videos.length - 1;

},
setMetaTag(metaTags) {
    let headTag = document.getElementsByTagName('head')[0]
      for (const i in metaTags) {
        // First, delete the meta tags.
        let oldTag = document.querySelector(`meta[name="${metaTags[i].name}"]`)
        if (oldTag) {
          document.querySelector(`meta[name="${metaTags[i].name}"]`).remove()
        }
        // Second, insert the replacement meta tags.
        let newTag = document.createElement('meta')
        newTag.setAttribute('name', metaTags[i].name)
        newTag.setAttribute(metaTags[i].name, metaTags[i].content)
        headTag.appendChild(newTag)
      }
    },

nextSlide(index) {
  if(index != this.videos.length - 1 )
    this.index = index + 1
  else
    this.index = 0
},
gotopayemnt: function () {
    let self = this;
    self.$router.push('/card-payment/'+this.$route.params.id);
},
checkSocial(social){
  if(!this.company.social_flags)
    return true;
  let social_flags = JSON.parse(this.company.social_flags);
  if(social_flags[social] == 1)
    return true;
  return false;
},
getSubdomain() {
    const hostname = window.location.hostname;
    const domainParts = hostname.split('.');
    
    // Assuming the domain is of the form subdomain.example.com
    if (domainParts.length > 2) {
        return domainParts[0];
    } else {
        return null; // No subdomain
    }
}
,
 getInventory(id){
  
  axios({
          method: "POST",
           
          url: "/api/get-vehicle-filters",
          data: {
            id: id
          }
   
        })
          .then(async(response) => {
            if(response.data.flag == 1){
                this.count_dealer = response.data.dealer
                this.count_vehicle = response.data.count
                this.count_make = response.data.make.length
                this.make_option = response.data.make
                this.model_option = response.data.model
                this.loader = false

            }else{
              this.$router.push('/login');
            }
          })

},
async copyURL() {
  console.log(window.location);
      try {
        await navigator.clipboard.writeText(window.location);
        alert('URL Copied, Now you can share the URL');
      } catch($e) {
        alert('Cannot copy');
      }
    },
checkFormSignup(){
  let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      if (!this.password && flag == 1) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        localStorage.setItem('temp_email',this.email)
        localStorage.setItem('temp_pass',this.password)
        localStorage.setItem('email_verified',0)
        if(this.company)
          this.$router.push('/signup/'+this.url)
        else
          this.$router.push('/signup')
      }
},
getStyle(flag){
  let self = this;
  let color = this.vcard.color;
  let font = this.vcard.header_color;
  let color_code = '';
  const colorArray = [
  { name: 'Orange', code: '#FD7E14' },
  { name: 'Green', code: '#28A745' },
  { name: 'Purple', code: '#6F42C1' },
  { name: 'Red', code: '#DC3545' },
  { name: 'Pink', code: '#E83E8C' },
  { name: 'Blue', code: '#007BFF' },
  { name: 'Dark Blue', code: '#0033cc' },
  { name: 'Black', code: '#000000' },
  { name: 'Silver', code: '#C0C0C0' },
  { name: 'Hot Pink', code: '#FF69B4' },
  { name: 'Flat Black', code: '#2D3436' }
];
colorArray.forEach(element => {
  if(element.name == color){
    color_code = element.code;
    self.color = color_code;
  }
});
if(flag == 'footer'){
  return 'background-color:'+color_code;
}
if(flag == 'button'){
  return color_code;
}

},
forgotPass(){
    this.buttonFlag =true;
    this.isDark = false;
    axios({
          method: "POST",
           
          url: "/api/auth/forgot-password",
          data: {
            email: this.email,
          }
        })
          .then(response => {
                this.sendFlag = 1;
                Vue.$toast.success('Forgot Password Mail sent', {
                position: 'top-right'
                });
                this.buttonFlag =false;
                this.isDark = true;
                this.pageFlag = 'login';
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          })
},
reset(){
  this.filter = {
        type : 'All',
        make : '',
        model : '',
        }
},
gotosignup: function () {
    let self = this;
    self.$router.push('/signup');
},gotoGoogle(){
      let self = this;
        this.$router.push('/redirect/google');
        location.reload();
  },
  gotoMs(){
      let self = this;
        this.$router.push('/redirect/microsoft');
        location.reload();
  },
  gotoYahoo(){
      let self = this;
        this.$router.push('/redirect/yahoo');
        location.reload();
  },
  callNumber(phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    },
    sendEmail(email) {
      window.location.href = `mailto:${email}`;
    },
    gotoLinks(link){
      if(!link)
      link = '#'
      const newTab = window.open('', '_blank');
      newTab.location.href = link;
    },

    search(body_style=''){
        let url = '?make='+this.filter.make+'&model='+this.filter.model+'&body_style='+body_style+'&credit_score='+this.credit_score+'&bhph='+this.bhph_program
        this.$router.push('/view-inventory'+url);
    },
    getCompanyInfo(id){
  
  axios({
          method: "POST",
           
          url: "/api/get-card",
          data: {
            id: id
          }
   
        })
          .then(async(response) => {
            if(response.data.flag == 1){
              if(id == 'reps'){
                
                this.reps_users = response.data.user;
                this.reps_flag = true;

              }else{
                this.company = response.data.user;
                this.vcard = response.data.vcard[0];
                this.sales_agent_count = response.data.sales_agents
                this.ref_agent_count = response.data.ref_agents
                // this.background =   this.base_url_img+'vcard/'+this.company.background
                // this.company.items = JSON.parse(JSON.parse(this.company.items))
                if(this.company.video_background){
                  let video_back = this.company.video_background.split("/");
                  this.vimeo_id = video_back[0]
                  this.vimeo_account = video_back[1]
                }
                let url = process.env.VUE_APP_WASBI_URL.replace('/logo','');
                const{base64Image, mimeType} = await this.convertImageToBase64(this.base_url_img+this.company.avatar);
                this.base64Image = base64Image
                this.avatar_mimeType = mimeType
              }
            }else{
              this.$router.push('/login');
            }
          })

},
    addContact() {
      const contact = {
        name: "John Doe",
        phone: "123-456-7890",
        email: "johndoe@example.com",
        address: "1234 Example St, Anytown, USA",
      };

      if ('contacts' in navigator && 'ContactsManager' in window) {
        navigator.contacts.select(
          ['name', 'email', 'tel', 'address'],
          { multiple: false }
        ).then((contacts) => {
          const newContact = new Contact({
            name: [contact.name],
            tel: [{ value: contact.phone }],
            email: [{ value: contact.email }],
            address: [{ value: contact.address }],
          });
          newContact.save();
        }).catch((error) => {
          console.error('Error adding contact:', error);
        });
      } else {
        alert(`Name: ${contact.name}\nPhone: ${contact.phone}\nEmail: ${contact.email}\nAddress: ${contact.address}`);
      }
    },
    openInGoogleMaps(address) {
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
      window.open(googleMapsUrl, '_blank');
    },
    getSettings() {
    let flag = 1;
    let url = process.env.VUE_APP_WASBI_URL
    var config = {headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache'}};
      axios({
          method: "POST",
          url: "/api/get-company-settings",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.company_settings = data.settings
               localStorage.setItem('settings', JSON.stringify(data.settings));
               if(data.settings.backgroung_logo)
                localStorage.setItem('admin_bg', JSON.stringify(url+data.settings.backgroung_logo));
              else
                localStorage.setItem('admin_bg','');
            }


         })
          .catch(err => {
            // Vue.$toast.error(err, {
            // position: 'top-right'
            // });

           });

     },
     checkPermission(flag) {
     
      if(flag == 'sales'){
        let status = 0;
        if(this.company_settings.sales_rep_flag == 'None'){
          return false
        }
        else if(this.company_settings.sales_rep_flag == 'All'){
          return true
        }else{
          if(this.company_settings.sales_rep_accounts.length != 0){
          this.company_settings.sales_rep_accounts.forEach(val => {
            if(val == this.company.user_id){
              status = 1;
            }
            
          });
        }
          return status
        }
      }
      if(flag == 'ref'){
        let status_ref = 0;
        if(this.company_settings.ref_rep_flag == 'None'){
          return false
        }
        else if(this.company_settings.ref_rep_flag == 'All'){
          return true
        }else{
          this.company_settings.ref_rep_accounts.forEach(val => {
            if(val == this.company.user_id){
              status_ref = 1;
            }
            
          });
          return status_ref
        }
      }
     },
  exportToFile(card, name='contact', force=true) {
            const a = document.createElement('a');
            const file = new Blob([card], { type: 'text/vcard'});

            a.href = URL.createObjectURL(file);
            a.download = name;
            a.click();

            URL.revokeObjectURL(a.href);
  },
  getIcon(id,value){
    if(id == 'Phone'){
      this.phone = value
      return 'fa fa-phone';
    }
    if(id == 'Zoom'){
      this.zoom_value = value
      return 'fas fa-video';
    }
    if(id == 'Event'){
      this.event_value = value
      return 'fas fa-calendar-check';
    }
    if(id == 'Email')
      return 'fas fa-envelope';
    if(id == 'Whatsapp')
      return 'fab fa-whatsapp';
    if(id == 'SMS/Text')
      return 'fas fa-comments';
    if(id == 'Website')
      return 'fas fa-link';
    if(id == 'Instagram')
      return 'fab fa-instagram';
    if(id == 'Snapchat')
      return 'fab fa-snapchat';
    if(id == 'Twitter')
      return 'fab fa-twitter';
    if(id == 'Facebook')
      return 'fab fa-facebook';
    if(id == 'LinkedIN')
      return 'fab fa-linkedin';
    else
      return 'fas fa-link';
  },
      // Function to convert image URL to Base64
  async convertImageToBase64(imageUrl) {
    let self = this;
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const mimeType = blob.type; // Get the MIME type of the image
    // this.avatar_mimeType = mimeType;
    // this.base64Image = blob
    // const reader = new FileReader();
    //     reader.onloadend = () => { self.base64Image = reader.result.split(',')[1]; self.avatar_mimeType = mimeType }; // Get Base64 string without the prefix
    //     reader.onerror = reject;
    //     reader.readAsDataURL(blob);
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve({ base64Image: reader.result.split(',')[1], mimeType }); // Get Base64 string without the prefix
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
},
/**
 * checkForm
 * login the user with valid email/pass
 */
async addCard(value) {
            const myVCard = new VCard()
            myVCard.addName(this.company.first_name+' '+this.company.last_name)
            if(this.company.business_name)
              myVCard.addCompany(this.company.business_name)
            myVCard.addEmail(this.company.email)
            myVCard.addPhoneNumber(this.company.phone_m)     
            
            // Add social media links if available
            if (this.company.facebook) {
                myVCard.addSocial(this.company.facebook, 'Facebook');
            }
            if (this.company.instagram) {
                myVCard.addSocial(this.company.instagram, 'Instagram');
            }
            if (this.company.linkedin) {
                myVCard.addSocial(this.company.linkedin, 'TikTok');
            }
            if (this.company.website) {
                myVCard.addSocial(this.company.website, 'Website');
            }
            if (this.company.youtube) {
                myVCard.addSocial(this.company.youtube, 'YouTube');
            }
            if (this.company.avatar) {
              if (this.avatar_mimeType == 'image/png') {
                myVCard.addPhoto(`${this.base64Image}`, 'PNG');
              }
              else if (this.avatar_mimeType == 'image/jpeg') {
                myVCard.addPhoto(`${this.base64Image}`, 'JPEG');
              }
              else if (this.avatar_mimeType == 'image/gif') {
                myVCard.addPhoto(`${this.base64Image}`, 'GIF');
              }
            }
            const output = myVCard.toString()
            const preElement = document.querySelector('#output')
            const outputNode = document.createTextNode(output)
            this.exportToFile(output,'contact',true)
          
    }
  },

  computed: {
    filteredUsers() {
      const query = this.search_rep.toLowerCase();
      return this.reps_users.filter(user => {
        const fullName = `${user.year} ${user.make} ${user.model}`.toLowerCase();
        return fullName.includes(query);
      });
    },
    introStyle() {
      return {
        backgroundImage: `url(${this.introImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        textAlign: 'center',
        padding: '15px',
        border: '0',
      };
    }
  },
  watch: {
    pageTitle() {
      this.$meta().refresh();
    },
  },
    
};
</script>
<style>
  .check-login .v-label {
    color: white;
  }
  .check-login .v-icon {
    color: white;
  }
.input-login .v-icon{
    color: #FDE038!important;
}
.input-login .v-label{
  color: #FDE038!important;
}
.input-login #input-6{
  color: #FDE038!important;
}
.input-login #input-10{
  color: #FDE038!important;
}
.header-card{
  overflow: hidden;
  position: relative;
  z-index: 3;
  border-bottom: 1px solid rgb(217, 218, 220);
  color: rgb(20, 20, 20);
  background: linear-gradient(45deg, rgb(255, 117, 140), rgb(255, 126, 179));
}
.cards{
  box-sizing: border-box;
  position: relative;
  display: block;
  padding: 80px 10px 30px;
  background-color: rgb(230, 230, 230);
  border-bottom: 1px solid rgb(217, 218, 220);
  text-align: center;
  cursor: pointer;
  user-select: none;
  color: rgb(20, 20, 20);
}
.cards:nth-child(2n+1) {
  border-right: 1px solid rgb(217, 218, 220);
}
.cards:hover {
  color: skyblue!important;
  background: #D9E6F4;
}
.header {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border: solid #fff;
  border-width: 0 1px;
  z-index: 1;
  background: white;
  font-weight: 700;
}
.footer {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  border: 0px;
  border-width: 0 1px;
  z-index: 1;
  background: white;
  font-weight: 700;
  width: 100%;
}
.elementor-container {
text-align: center;
}
.elementor-column {
  position: relative;
  min-height: 1px;
  display: flex;
  justify-content: center;
}
.elementor-column-header {
  position: relative;
  min-height: 1px;
  display: flex;
  justify-content: center;
  margin-bottom: -170px;
  margin-top: 50px;
}
.elementor-heading-title-card{
  color: #FFFFFF;
  font-family: "Montserrat", Sans-serif;
  font-weight: 800;
  text-shadow: 4px 4px 10px rgba(0,0,0,0.3);
}
.elementor-icon-wrapper{
  font-size: 25px;
  color: #000000;
}
.elementor-element.elementor-element-09cffa4 img {
  width: 200px;
  height: 200px;
  border-style: solid;
  border-width: 5px 5px 5px 5px;
  border-color: #FFFFFF;
  border-radius: 300px 300px 300px 300px;
  
}
.elementor-element.elementor-element-a14cd74:not(.elementor-motion-effects-element-type-background), .elementor-581 .elementor-element.elementor-element-a14cd74 > .elementor-motion-effects-container > .elementor-motion-effects-layer {
  background: url("https://s3.us-central-1.wasabisys.com/quantumresolution/logo/david-bg.jpg") 50% 50%;
    background-size: auto;
  background-size: cover;
  padding: 60px 0px 70px 0px;
  position: relative;
}
.elementor-shape-bottom {
  bottom: -1px;
}
.elementor-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}
.elementor-shape .elementor-shape-fill {
  fill: #fff;
  transform-origin: center;
  transform: rotateY(0deg);
}
.elementor-heading-title2 {
  color: #303030;
  font-family: "Lora", Sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
}
 .elementor-social-icon {
  background-color: #021033;
}
.elementor-icon.elementor-social-icon {
  border-radius: 50%;
  font-size: 15px;
  line-height: 25px;
  width: 25px;
  display: inline-block;
  
  height: 25px;
}
.elementor-screen-only, .screen-reader-text, .screen-reader-text span, .ui-helper-hidden-accessible {
  position: absolute;
  top: -10000em;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
.elementor-widget-container {
  text-align: center;
}
.elementor-social-icon i {
  color: #FFFFFF;
}
.elementor-grid-item{
  margin: 5px;
}
.slider {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slide.active {
  visibility: visible;
  opacity: 1;
}

iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container1 {
  display: flex;
  align-items: center;
  gap: 8px;
}
 .elementor-button {
  display: block;
  font-family: "Poppins", Sans-serif;
  font-size: 17px;
  font-weight: 400;
  text-transform: capitalize;
  fill: var( --e-global-color-6aef213 );
  color: var( --e-global-color-6aef213 );
  background-color: #FEFEFE;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: var( --e-global-color-6aef213 );
  border-radius: 50px 50px 50px 50px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.32);
  padding: 14px 50px 14px 50px;
}

.elementor-background-video-container {

    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    overflow: hidden;
    z-index: 0;
    direction: ltr;
    transition: opacity 1s;
    pointer-events: none;

}
 .elementor-background-video-hosted {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.brands-top {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
}

  .brands-top .brands-top-item {
    padding-left: 15px; 
    padding-right: 15px;
}
.text-black {
  font-weight: 900;
}
.bg-cta {
  background: #1B263B;
}
.list-margin-bottom-x li, .margin-bottom-x {
  margin-bottom: 0!important;
}
.brands-top .brands-top-item .text-black {
  font-size: 26px;
}
.lead {
  font-size: 1.25rem;
  line-height: 1!important;
}
.sub-h{
  font-size: 12px;
}
.searchSec {
  width: 600px;
  padding: 30px;
  background: rgba(0,0,0,.5);
  border: 2px solid #fff;
  z-index: 2;
  position: relative;
}
.inventory-filter-select .v-input__slot {
  background: white!important;
}
.inventory-filter-select .v-input__append-inner {
  margin-top: 13px!important;
}
.inventory-filter-select  .v-label {
  top: 15px!important;
}
.modelbar {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
}
  .modelbar .modelbarItem {
    width: 50%;
    padding: 5px 10px;
  }
  .img-responsive, .thumbnail a > img, .thumbnail > img {
  display: block;
  max-width: 100%;
  height: auto;
}
</style>