<template>
<v-app >
<vue-header-admin></vue-header-admin>
 <v-main>
  
<v-container fluid style="margin-bottom:50px;"  class="px-6 py-6">
  <v-tabs
                          v-model="tab"
                          color="green"
                        icons-and-text
                      >
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab :key="'dashboard'" href="#tab-dashboard" >
                          Dashboard
                          <v-icon></v-icon>
                        </v-tab>
                        <v-tab :key="'sold_unit'" href="#tab-sold_unit" v-if="checkPermission('sold_units')">
                          Sold Units
                          <v-icon></v-icon>
                        </v-tab>

                        <v-tab :key="'inventory'" id="tab-inventory" href="#tab-inventory" v-if="checkPermission('inventory')">
                          Inventory
                          <v-icon></v-icon>
                        </v-tab>
                         <v-tab :key="'dealers'" href="#tab-dealers" v-if="checkPermission('dealers')">
                          Dealers
                          <v-icon></v-icon>
                        </v-tab>
                        
                       
                      </v-tabs>
                      
                      <v-tabs-items v-model="tab">
                        <v-tab-item
                          :key="'dashboard'"
                          style="height: 100%!important;"
                          :value="'tab-dashboard'"
                        >
                        <v-row class="mb-12 mt-5">
        <v-col md="12">
          <sales-country v-if="dashboardData.scans" :scans="dashboardData.scans"></sales-country>
        </v-col>
      </v-row>

      <v-row class="mb-12" style="margin-top: -30px;">
                          <v-col md="12">
                            
                            <v-card class="card-shadow border-radius-xl" id="basic" >
                                  <v-card-text style="margin-bottom: -30px;">
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        md="12"
                                      >
                                      <h5 class="text-h6 font-weight-bold text-typo">TOTAL SHARES AVAILABLE: <span style="color:#6495ED;">{{share_settings.total_share - share_sold}}</span></h5>
                                      </v-col>
                                       <v-col
                                        cols="12"
                                        md="12"
                                        style="margin-top: -10px;"
                                      >
                                      <h5 class="text-h6 font-weight-bold text-typo" >TOTAL SHARES SOLD: <span style="color:green;">{{share_sold}}</span></h5>
                                      </v-col>
                                     
                                      <v-col cols="12"
                                        md="6" >
                                          
                                                      
                                                      <v-btn
                                                color="blue"
                                              class="
                                              ont-weight-bolder
                                                btn-default
                                                mr-3
                                                py-4
                                                px-8
                                                ms-auto
                                                mt-sm-auto mt-4
                                                              
                                                            "
                                                              small
                                                              dark
                                                              @click="dialogAssignShare = true"
                                                          >
                                                              Add Share to Agent
                                                          </v-btn>
                                                          <v-btn
                                                      color="red"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            ml-2
                                            py-4
                                            px-8
                                            ms-auto
                                            mr-3
                                            mt-sm-auto mt-4

                                                          
                                                        "
                                                          small
                                                          dark
                                                          @click="removeShare()"
                                                      >
                                                          Remove Share
                                                      </v-btn>
                                                          
                                                          
                                                     
                                        </v-col>
                                      <v-col cols="12"
                                        md="6" >
                                          <v-spacer></v-spacer>
                                              <v-text-field
                                                v-model="search"
                                                  label="Search"
                                                  single-line
                                                  clearable
                                                  hide-details
                                              >
                                                  <template v-slot:append>
                                                  
                                                  </template>
                                                  <template v-slot:append-outer>
                                                      <v-btn
                                                      color="#4CAF50"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            py-4
                                            px-8
                                            ms-auto
                                            mt-sm-auto mt-4
                                                          
                                                        "
                                                          small
                                                          @click="getShares()"
                                                      >
                                                          Submit
                                                      </v-btn>
                                                  </template>
                                              </v-text-field>
                                      </v-col>
                                      <v-col cols="12"
                                        md="12">
                                        
                                          <v-data-table
                                          :headers="headers_shares"
                                              :options.sync="datatable_options_shares"
                                              :items="shares"
                                              v-model="selectedItemsShares"
                                              show-select
                                              loading-text="Loading... Please wait"
                                              :page.sync="pageShares"
                                              :items-per-page="itemsPerPage_shares"
                                              class="table"
                                              :footer-props="{
                    
                                                      'items-per-page-options': [10, 50, 100,-1]
                                                    }"
                                          >
                                          
                                          <template v-slot:item.name="{ item }">
                                             <a style="text-decoration: none;color: #2196F3;" target="_blank" :href="'/admin/client-profile-detail/'+item.user_id">{{item.name}} </a>
                                          </template>
                                          <template v-slot:item.email="{ item }">
                                              {{item.email ? item.email : 'Not Available'}}
                                          </template>
                                          <template v-slot:item.phone="{ item }">
                                              {{item.phone_m ? item.phone_m : "Not Avilable"}}
                                          </template>
                                          <template v-slot:item.reffered_by="{ item }">
                                              {{item.reffered_by ? item.reffered_by : 'N/A'}}
                                          </template>
                                          <template v-slot:item.ownership="{ item }">
                                              <span class="font-weight-bold">{{ownershipCal(item)}} </span>
                                          </template>
                                          <template v-slot:item.total_cash="{ item }">
                                              <span class="text-success font-weight-bold ">{{totalCashCal(item)}}</span>
                                          </template>
                                          <template v-slot:item.quarterly_payout="{ item }">
                                              <span class="text-success font-weight-bold">{{totalCashQCal(item)}}</span>
                                          </template>
                                          <template v-slot:item.shares_owned="{ item }">
                                              <span class="font-weight-bold" >{{(item.shares)}}</span>
                                          </template>
                                          <template v-slot:item.selling_cost="{ item }">
                                              {{currencyFormat(item.selling_cost)}}
                                          </template>
                                          <template v-slot:item.net_profit="{ item }">
                                              {{currencyFormat(item.net_profit)}}
                                          </template>
                                          <template v-slot:item.password="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                              @click="dialogPassword = true, selectedPasswordId = item.id,selectedPassword = generatePassword()"
                                              x-small
                                              color="blue"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                key
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.edit="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="$router.push('edit-company/'+item.id)"
                                              x-small
                                              color="blue"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                edit
                                              </v-icon>
                                            </v-btn>
                                          </template>

                                          <template v-slot:item.url="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="dialogURL =true, company_url = item.company_url ? item.company_url : ''"
                                              x-small
                                              color="orange"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>

                                          <template v-slot:item.agreement_name="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="viewContract(item.id)"
                                              x-small
                                              color="orange"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>

                                          <template v-slot:item.credit_new="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'add'"
                                              x-small
                                              color="green"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                add_card
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.credit_remove="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'remove'"
                                              x-small
                                              color="red"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                credit_card_off
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.address="{ item }">
                                          <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                                          </span>
                                          </template>
                                          <template v-slot:item.status_id="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="changeStatus(item.user_id,item.status)"
                                              x-small
                                              :color="item.status==1 ? 'red':'green'"
                                            >
                                            {{item.status==1 ? 'Deactivate':'Activate'}}
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.document="{ item }">
                                            {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                                          </template>
                                          <template v-slot:item.process1="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="dialogDoc = true, selected_id = item.user_id"
                                              x-small
                                              v-if="item.process_1 == 0"
                                              :color="'green'"
                                            >
                                            Start
                                            </v-btn>
                                            <v-btn
                                              class="mx-2"
                                              disabled

                                              @click="dialogDoc = true"
                                              x-small
                                              v-if="item.process_1 == 1"
                                              :color="'green'"
                                            >
                                            Finished
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.process_1_key="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              
                                              @click="dialogDoc = true, selected_id = item.user_id"
                                              x-small
                                              disabled
                                              v-if="item.process_1 == 0"
                                              :color="'green'"
                                            >
                                            View
                                            </v-btn>
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="getProcessStatus(item.process_1_key)"
                                              x-small
                                              v-if="item.process_1 == 1"
                                              :color="'blue'"
                                            >
                                            View
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.ghl_flag="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              text
                                              @click="syncGhl(item.id, item.ghl_flag)"
                                              x-small
                                              :color="item.ghl_flag==1 ? 'green':'red'"
                                            >
                                            <v-icon v-if="item.ghl_flag==1" size="40" class="material-icons-round opacity-10" dark>
                                                toggle_on
                                              </v-icon>
                                              <v-icon v-else size="40" class="material-icons-round opacity-10" dark>
                                                toggle_off
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.ghl_chat_flag="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              text
                                              @click="syncGhlChat(item.id, item.ghl_chat_flag)"
                                              x-small
                                              :color="item.ghl_chat_flag==1 ? 'green':'red'"
                                            >
                                            <v-icon v-if="item.ghl_chat_flag==1" size="40" class="material-icons-round opacity-10" dark>
                                                toggle_on
                                              </v-icon>
                                              <v-icon v-else size="40" class="material-icons-round opacity-10" dark>
                                                toggle_off
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.id="{ item }">

                                            <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                              @click="deleteItem(item.id)"
                                              x-small
                                              color="red"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                delete
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          </v-data-table>
                                          <div class="text-center pt-2">
                                              <v-pagination
                                              prev-icon="fa fa-angle-left"
                                              next-icon="fa fa-angle-right"
                                              class="pagination"
                                              circle
                                              color="#4CAF50"
                                                  v-model="pageShares"
                                                  :length="pageCountShares"
                                                  @input="getShares"
                                              ></v-pagination>
                                              </div>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                  </v-card>
                          </v-col>
                          </v-row>

      <v-row class="mb-2 mt-15" v-if="checkPermission('car_of_the_week')">
      <v-col md="12" class="mb-5">
          <h5 class="text-h5 font-weight-bold text-typo">CARS OF THE WEEK</h5>
        </v-col>
        <v-col lg="4" class="pt-0 mb-lg-0 mb-10 mt-5">
          <card-website-views v-if="dashboardData.views" :id="1" :data="dashboardData.views.cow_view_daily"></card-website-views>
        </v-col>
        <v-col lg="4" class="pt-0 mb-lg-0 mb-10 mt-5">
          <card-daily-sales v-if="dashboardData.views" :id="1" :data="dashboardData.views.cow_view_monthly"></card-daily-sales>
        </v-col>
        <v-col lg="4" class="pt-0 mb-lg-0 mt-5">
          <card-completed-tasks v-if="dashboardData.views" :id="1" :data="dashboardData.views.cow_view_agent"></card-completed-tasks>
        </v-col>
        </v-row>

        <v-row class="mb-2 mt-5" v-if="checkPermission('clearance')">
         <v-col md="12" class="mb-5">
          <h5 class="text-h5 font-weight-bold text-typo">CLEARANCE</h5>
        </v-col>
        <v-col lg="4" class="pt-0 mb-lg-0 mb-10 mt-5">
          <card-website-views :id="2" v-if="dashboardData.views" :data="dashboardData.views.cl_view_daily"></card-website-views>
        </v-col>
        <v-col lg="4" class="pt-0 mb-lg-0 mb-10 mt-5">
          <card-daily-sales :id="2" v-if="dashboardData.views" :data="dashboardData.views.cl_view_monthly"></card-daily-sales>
        </v-col>
        <v-col lg="4" class="pt-0 mb-lg-0 mt-5">
          <card-completed-tasks :id="2" v-if="dashboardData.views" :data="dashboardData.views.cl_view_agent"></card-completed-tasks>
        </v-col>
      </v-row>

      <v-row class="mb-2 mt-5" v-if="checkPermission('inventory')">
        <v-col md="12" class="mb-5">
          <h5 class="text-h5 font-weight-bold text-typo">ALL INVENTORY</h5>
        </v-col>
        <v-col lg="4" class="pt-0 mb-lg-0 mb-10 mt-5">
          <card-website-views :id="3" v-if="dashboardData.views" :data="dashboardData.views.all_view_daily"></card-website-views>
        </v-col>
        <v-col lg="4" class="pt-0 mb-lg-0 mb-10 mt-5">
          <card-daily-sales :id="3" v-if="dashboardData.views" :data="dashboardData.views.all_view_monthly"></card-daily-sales>
        </v-col>
        <v-col lg="4" class="pt-0 mb-lg-0 mt-5">
          <card-completed-tasks :id="3" v-if="dashboardData.views" :data="dashboardData.views.all_view_agent"></card-completed-tasks>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-row>
            <v-col sm="3" cols="12">
              <v-card class="mb-6 card-shadow border-radius-xl py-4">
                <v-row no-gutters class="px-4">
                  <v-col sm="4">
                    <v-avatar
                      color="bg-gradient-default border-radius-xl mt-n8"
                      class="shadow-dark"
                      height="64"
                      width="64"
                    >
                      <v-icon class="material-icons-round text-white" size="24"
                        >weekend</v-icon
                      >
                    </v-avatar>
                  </v-col>
                  <v-col sm="8" class="text-end">
                    <p
                      class="
                        text-sm
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      "
                    >
                      Bookings
                    </p>
                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                      281
                    </h4>
                  </v-col>
                </v-row>
                <hr class="dark horizontal mt-3 mb-4" />
                <v-row class="px-4">
                  <v-col cols="12">
                    <p class="mb-0 text-body">
                      <span class="text-success text-sm font-weight-bolder"
                        >+55%</span
                      >
                      <span class="font-weight-light ms-1">than last week</span>
                    </p>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col sm="3" cols="12">
              <v-card class="mb-6 card-shadow border-radius-xl py-4">
                <v-row no-gutters class="px-4">
                  <v-col sm="4">
                    <v-avatar
                      color="bg-gradient-primary border-radius-xl mt-n8"
                      class="shadow-primary"
                      height="64"
                      width="64"
                    >
                      <v-icon class="material-icons-round text-white" size="24"
                        >leaderboard</v-icon
                      >
                    </v-avatar>
                  </v-col>
                  <v-col sm="8" class="text-end">
                    <p
                      class="
                        text-sm
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      "
                    >
                      Today's Users
                    </p>
                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                      2,300
                    </h4>
                  </v-col>
                </v-row>
                <hr class="dark horizontal mt-3 mb-4" />
                <v-row class="px-4">
                  <v-col cols="12">
                    <p class="mb-0 text-body">
                      <span class="text-success text-sm font-weight-bolder"
                        >+3%</span
                      >
                      <span class="font-weight-light ms-1"
                        >than last month</span
                      >
                    </p>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>

            <v-col sm="3" cols="12">
              <v-card class="mb-6 card-shadow border-radius-xl py-4">
                <v-row no-gutters class="px-4">
                  <v-col sm="4">
                    <v-avatar
                      color="bg-gradient-success border-radius-xl mt-n8"
                      class="shadow-success"
                      height="64"
                      width="64"
                    >
                      <v-icon class="material-icons-round text-white" size="24"
                        >store</v-icon
                      >
                    </v-avatar>
                  </v-col>
                  <v-col sm="8" class="text-end">
                    <p
                      class="
                        text-sm
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      "
                    >
                      Revenue
                    </p>
                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                      34k
                    </h4>
                  </v-col>
                </v-row>
                <hr class="dark horizontal mt-3 mb-4" />
                <v-row class="px-4">
                  <v-col cols="12">
                    <p class="mb-0 text-body">
                      <span class="text-success text-sm font-weight-bolder"
                        >+1%</span
                      >
                      <span class="font-weight-light ms-1">than yesterday</span>
                    </p>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>

            <v-col sm="3" cols="12">
              <v-card class="mb-6 card-shadow border-radius-xl py-4">
                <v-row no-gutters class="px-4">
                  <v-col sm="4">
                    <v-avatar
                      color="bg-gradient-info border-radius-xl mt-n8"
                      class="shadow-info"
                      height="64"
                      width="64"
                    >
                      <v-icon class="material-icons-round text-white" size="24"
                        >person_add</v-icon
                      >
                    </v-avatar>
                  </v-col>
                  <v-col sm="8" class="text-end">
                    <p
                      class="
                        text-sm
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      "
                    >
                      Followers
                    </p>
                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                      +91
                    </h4>
                  </v-col>
                </v-row>
                <hr class="dark horizontal mt-3 mb-4" />
                <v-row class="px-4">
                  <v-col cols="12">
                    <p class="mb-0 text-body">
                      <span class="font-weight-light ms-1">Just updated</span>
                    </p>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Summary -->


      <v-row>
        <v-col md="12" class="mb-5">
          <h5 class="text-h4 font-weight-bold text-typo">Sales Summary</h5>
        </v-col>
        <v-col md="12">
          <v-row>
            <v-col sm="2" cols="12">
              <v-card class="mb-6 card-shadow border-radius-xl py-4">
                <v-row no-gutters class="px-4">
                  
                  <v-col sm="12" class="text-center">
                    <p
                      class="
                        text-lg
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      "
                    >
                      Sold
                    </p>
                    <v-row no-gutters class="px-4">
                      <v-col sm="12" class="text-center">
                        <h4 class="text-h3 text-typo font-weight-bolder" style="margin-bottom: 17px;">
                          {{dashboardData.summary.count_sold}}
                        </h4>
                        <!-- <p class="text-lg
                        mb-0
                        text-capitalize text-body
                        font-weight-light">&nbsp;</p> -->
                        </v-col>
                    </v-row>
                  </v-col>
                </v-row>
               
              </v-card>
            </v-col>
            <v-col sm="2" cols="12">
              <v-card class="mb-6 card-shadow border-radius-xl py-4">
                <v-row no-gutters class="px-4">
                  <v-col sm="12" class="text-center">
                    <p
                      class="
                        text-lg
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      "
                    >
                      Vehicle Cost
                    </p>
                    <v-row no-gutters class="px-4">
                      <v-col sm="6" class="text-center">
                        <h4 class="text-h5 text-typo font-weight-bolder mb-0">
                          {{currencyFormatSummary(dashboardData.summary.sum_vehicle_cost)}}
                        </h4>
                        <p class="text-lg
                        mb-0
                        text-capitalize text-body
                        font-weight-light">Sum</p>
                        </v-col>
                        <v-col sm="6" class="text-center">
                        <h4 class="text-h5 text-typo font-weight-bolder mb-0">
                          {{currencyFormatSummary(dashboardData.summary.avg_vehicle_cost)}}
                        </h4>
                        <p class="text-lg
                        mb-0
                        text-capitalize text-body
                        font-weight-light">Average</p>
                        </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>

            <v-col sm="2" cols="12">
              <v-card class="mb-6 card-shadow border-radius-xl py-4">
                <v-row no-gutters class="px-4">
                  <v-col sm="12" class="text-center">
                    <p
                      class="
                        text-lg
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      "
                    >
                      Total Cost
                    </p>
                    <v-row no-gutters class="px-4">
                      <v-col sm="6" class="text-center">
                        <h4 class="text-h5 text-typo font-weight-bolder mb-0">
                          {{currencyFormatSummary(dashboardData.summary.sum_total_cost)}}
                        </h4>
                        <p class="text-lg
                        mb-0
                        text-capitalize text-body
                        font-weight-light">Sum</p>
                        </v-col>
                        <v-col sm="6" class="text-center">
                        <h4 class="text-h5 text-typo font-weight-bolder mb-0">
                          {{currencyFormatSummary(dashboardData.summary.avg_total_cost)}}
                        </h4>
                        <p class="text-lg
                        mb-0
                        text-capitalize text-body
                        font-weight-light">Average</p>
                        </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col sm="2" cols="12">
              <v-card class="mb-6 card-shadow border-radius-xl py-4">
                <v-row no-gutters class="px-4">
                  <v-col sm="12" class="text-center">
                    <p
                      class="
                        text-lg
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      "
                    >
                      Selling Price
                    </p>
                    <v-row no-gutters class="px-4">
                      <v-col sm="6" class="text-center">
                        <h4 class="text-h5 text-typo font-weight-bolder mb-0">
                          {{currencyFormatSummary(dashboardData.summary.sum_selling_price)}}
                        </h4>
                        <p class="text-lg
                        mb-0
                        text-capitalize text-body
                        font-weight-light">Sum</p>
                        </v-col>
                        <v-col sm="6" class="text-center">
                        <h4 class="text-h5 text-typo font-weight-bolder mb-0">
                          {{currencyFormatSummary(dashboardData.summary.avg_selling_price)}}
                        </h4>
                        <p class="text-lg
                        mb-0
                        text-capitalize text-body
                        font-weight-light">Average</p>
                        </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col sm="2" cols="12">
              <v-card class="mb-6 card-shadow border-radius-xl py-4">
                <v-row no-gutters class="px-4">
                  <v-col sm="12" class="text-center">
                    <p
                      class="
                        text-lg
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      "
                    >
                      Total Gross
                    </p>
                    <v-row no-gutters class="px-4">
                      <v-col sm="6" class="text-center">
                        <h4 class="text-h5 text-typo font-weight-bolder mb-0">
                          {{currencyFormatSummary(dashboardData.summary.sum_total_gross)}}
                        </h4>
                        <p class="text-lg
                        mb-0
                        text-capitalize text-body
                        font-weight-light">Sum</p>
                        </v-col>
                        <v-col sm="6" class="text-center">
                        <h4 class="text-h5 text-typo font-weight-bolder mb-0">
                          {{currencyFormatSummary(dashboardData.summary.avg_total_gross)}}
                        </h4>
                        <p class="text-lg
                        mb-0
                        text-capitalize text-body
                        font-weight-light">Average</p>
                        </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col sm="2" cols="12">
              <v-card class="mb-6 card-shadow border-radius-xl py-4">
                <v-row no-gutters class="px-4">
                  <v-col sm="12" class="text-center">
                    <p
                      class="
                        text-lg
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      "
                    >
                      Net Profit
                    </p>
                    <v-row no-gutters class="px-4">
                      <v-col sm="6" class="text-center">
                        <h4 class="text-h5 text-typo font-weight-bolder mb-0">
                          {{currencyFormatSummary(dashboardData.summary.sum_net_profit)}}
                        </h4>
                        <p class="text-lg
                        mb-0
                        text-capitalize text-body
                        font-weight-light">Sum</p>
                        </v-col>
                        <v-col sm="6" class="text-center">
                        <h4 class="text-h5 text-typo font-weight-bolder mb-0">
                          {{currencyFormatSummary(dashboardData.summary.avg_net_profit)}}
                        </h4>
                        <p class="text-lg
                        mb-0
                        text-capitalize text-body
                        font-weight-light">Average</p>
                        </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>


          </v-row>
        </v-col>
      </v-row>




      <!-- Cards -->

      <!-- <v-row class="mb-6 mt-10">
        <v-col
          v-for="(item, i) in imageCards"
          :key="item.title + i"
          lg="4"
          class="pt-0 mb-lg-0 mb-10"
        >
          <v-card
            class="card card-shadow border-radius-xl py-5 text-center"
            data-animation="true"
          >
            <div class="mt-n11 mx-4 card-header position-relative z-index-2">
              <div class="d-block blur-shadow-image">
                <img
                  :src="item.image"
                  class="img-fluid shadow border-radius-lg"
                  :alt="item.image"
                />
              </div>
              <div
                class="colored-shadow"
                v-bind:style="{ backgroundImage: 'url(' + item.image + ')' }"
              ></div>
            </div>
            <div class="d-flex mx-auto mt-n8">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="material-icons-round text-primary ms-auto px-5"
                    size="18"
                  >
                    refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="material-icons-round text-info me-auto px-5"
                    size="18"
                  >
                    edit
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </div>
            <h5 class="font-weight-normal text-typo text-h5 mt-7 mb-2 px-4">
              <a
                href="javascript:;"
                class="text-decoration-none text-default"
                >{{ item.title }}</a
              >
            </h5>
            <p class="mb-0 text-body font-weight-light px-5">
              {{ item.text }}
            </p>
            <hr class="horizontal dark my-6" />
            <div class="d-flex text-body mx-2 px-4">
              <p class="mb-0 font-weight-normal text-body">
                ${{ item.price }}/night
              </p>
              <i
                class="
                  material-icons-round
                  position-relative
                  ms-auto
                  text-lg
                  me-1
                  my-auto
                "
                >place</i
              >
              <p class="text-sm my-auto font-weight-light">
                {{ item.location }}
              </p>
            </div>
          </v-card>
        </v-col>
      </v-row> -->
                        </v-tab-item>
                        <v-tab-item
                          :key="'sold_unit'"
                          style="height: 100%!important;"
                          :value="'tab-sold_unit'"
                        >
                        <v-row class="mb-12 mt-3" >
                          <v-col md="12">
                            
                            <v-card class="card-shadow border-radius-xl" id="basic" >
                                  <v-card-text style="margin-bottom: -30px;">
                                    <v-row>
                                      <v-col
                                        cols="6"
                                        md="6"
                                      >
                                      <h5 class="text-h4 font-weight-bold text-typo">Sold Units ({{totalCount}})</h5>
                                      </v-col>
                                      <v-col
                                        cols="6"
                                        md="6"
                                      >
                                      <rotate-loader class="mt-3" :loading="loading" color="#1D75F4"></rotate-loader>
                                      <!-- <v-btn  class="mb-10" width="200px" style="float: right;" color="primary" :to="{path:'/add-parking'}" >Add Parking Lot</v-btn> -->
                                      </v-col>
                                      <v-col cols="12"
                                        md="6" >
                                          <v-btn
                                                      color="#4CAF50"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            mr-3
                                            py-4
                                            px-8
                                            ms-auto
                                            mt-sm-auto mt-4
                                                          
                                                        "
                                                          small
                                                          @click="dialogImport = true"
                                                      >
                                                          Import From csv
                                                      </v-btn>
                                                      <vue-json-to-csv :json-data="users"
                                                      :labels="{ date_sold: { title: 'Date Sold' },vehicle_info: { title: 'Vehicle Info' },vin : {title : 'VIN'},odometer : {title : 'Odometer'},vehicle_cost : {title : 'Vehicle Cost'},total_cost : {title : 'Total Cost'},selling_price : {title : 'Selling Price'},net_profite : {title : 'Net Profite'},sold_status : {title : 'Sold Status'},customer_name : {title : 'Customer Name'} }"
                                                      csv-title="sold-units"
                                                      >
                                                      <v-btn
                                                      color="blue"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            py-4
                                            px-8
                                            mr-3
                                            ms-auto
                                            mt-sm-auto mt-4
                                                          
                                                        "
                                                      dark
                                                          small
                                                      >
                                                          Export to csv
                                                      </v-btn>
                                                    </vue-json-to-csv>
                                                      <v-btn
                                                      color="red"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            ml-5
                                            py-4
                                            px-8
                                            ms-auto
                                            mr-3
                                            mt-sm-auto mt-4

                                                          
                                                        "
                                                          small
                                                          dark
                                                          @click="deleteItem('all')"
                                                      >
                                                          mass delete
                                                      </v-btn>
                                                      <v-btn
                                                color="blue"
                                              class="
                                              ont-weight-bolder
                                                btn-default
                                                mr-5
                                                py-4
                                                px-8
                                                ms-auto
                                                mt-sm-auto mt-4
                                                              
                                                            "
                                                              small
                                                              dark
                                                              @click="dialogAssign = true"
                                                          >
                                                              Assign Refferal Agent
                                                          </v-btn>
                                                          
                                                          
                                                     
                                        </v-col>
                                      <v-col cols="12"
                                        md="6" >
                                          <v-spacer></v-spacer>
                                              <v-text-field
                                                v-model="search"
                                                  label="Search"
                                                  single-line
                                                  clearable
                                                  hide-details
                                              >
                                                  <template v-slot:append>
                                                  
                                                  </template>
                                                  <template v-slot:append-outer>
                                                      <v-btn
                                                      color="#4CAF50"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            py-4
                                            px-8
                                            ms-auto
                                            mt-sm-auto mt-4
                                                          
                                                        "
                                                          small
                                                          @click="getUsers"
                                                      >
                                                          Submit
                                                      </v-btn>
                                                  </template>
                                              </v-text-field>
                                      </v-col>
                                      <v-col cols="12"
                                        md="12">
                                        
                                          <v-data-table
                                          :headers="headers_jobs"
                                              :options.sync="datatable_options"
                                              :items="users"
                                              v-model="selectedItems"
                                              show-select
                                              loading-text="Loading... Please wait"
                                              :page.sync="page"
                                              :items-per-page="itemsPerPage"
                                              class="table"
                                              :footer-props="{
                    
                                                      'items-per-page-options': [10, 50, 100,-1]
                                                    }"
                                          >
                                      
                                          <template v-slot:item.status="{ item }">
                                              {{item.status == 1 ? 'Active' :'Inactive'}}
                                          </template>
                                          <template v-slot:item.email="{ item }">
                                              {{item.email ? item.email : 'Not Available'}}
                                          </template>
                                          <template v-slot:item.phone="{ item }">
                                              {{item.phone_m ? item.phone_m : "Not Avilable"}}
                                          </template>
                                          <template v-slot:item.vehicle_cost="{ item }">
                                              {{currencyFormat(item.vehicle_cost)}}
                                          </template>
                                          <template v-slot:item.total_cost="{ item }">
                                              {{currencyFormat(item.total_cost)}}
                                          </template>
                                          <template v-slot:item.selling_cost="{ item }">
                                              {{currencyFormat(item.selling_cost)}}
                                          </template>
                                          <template v-slot:item.net_profit="{ item }">
                                              {{currencyFormat(item.net_profit)}}
                                          </template>
                                          <template v-slot:item.password="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                              @click="dialogPassword = true, selectedPasswordId = item.id,selectedPassword = generatePassword()"
                                              x-small
                                              color="blue"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                key
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.edit="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="$router.push('edit-company/'+item.id)"
                                              x-small
                                              color="blue"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                edit
                                              </v-icon>
                                            </v-btn>
                                          </template>

                                          <template v-slot:item.url="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="dialogURL =true, company_url = item.company_url ? item.company_url : ''"
                                              x-small
                                              color="orange"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>

                                          <template v-slot:item.agreement_name="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="viewContract(item.id)"
                                              x-small
                                              color="orange"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>

                                          <template v-slot:item.credit_new="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'add'"
                                              x-small
                                              color="green"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                add_card
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.credit_remove="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'remove'"
                                              x-small
                                              color="red"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                credit_card_off
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.address="{ item }">
                                          <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                                          </span>
                                          </template>
                                          <template v-slot:item.status_id="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="changeStatus(item.user_id,item.status)"
                                              x-small
                                              :color="item.status==1 ? 'red':'green'"
                                            >
                                            {{item.status==1 ? 'Deactivate':'Activate'}}
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.document="{ item }">
                                            {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                                          </template>
                                          <template v-slot:item.process1="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="dialogDoc = true, selected_id = item.user_id"
                                              x-small
                                              v-if="item.process_1 == 0"
                                              :color="'green'"
                                            >
                                            Start
                                            </v-btn>
                                            <v-btn
                                              class="mx-2"
                                              disabled

                                              @click="dialogDoc = true"
                                              x-small
                                              v-if="item.process_1 == 1"
                                              :color="'green'"
                                            >
                                            Finished
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.process_1_key="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              
                                              @click="dialogDoc = true, selected_id = item.user_id"
                                              x-small
                                              disabled
                                              v-if="item.process_1 == 0"
                                              :color="'green'"
                                            >
                                            View
                                            </v-btn>
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="getProcessStatus(item.process_1_key)"
                                              x-small
                                              v-if="item.process_1 == 1"
                                              :color="'blue'"
                                            >
                                            View
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.ghl_flag="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              text
                                              @click="syncGhl(item.id, item.ghl_flag)"
                                              x-small
                                              :color="item.ghl_flag==1 ? 'green':'red'"
                                            >
                                            <v-icon v-if="item.ghl_flag==1" size="40" class="material-icons-round opacity-10" dark>
                                                toggle_on
                                              </v-icon>
                                              <v-icon v-else size="40" class="material-icons-round opacity-10" dark>
                                                toggle_off
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.ghl_chat_flag="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              text
                                              @click="syncGhlChat(item.id, item.ghl_chat_flag)"
                                              x-small
                                              :color="item.ghl_chat_flag==1 ? 'green':'red'"
                                            >
                                            <v-icon v-if="item.ghl_chat_flag==1" size="40" class="material-icons-round opacity-10" dark>
                                                toggle_on
                                              </v-icon>
                                              <v-icon v-else size="40" class="material-icons-round opacity-10" dark>
                                                toggle_off
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.id="{ item }">

                                            <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                              @click="deleteItem(item.id)"
                                              x-small
                                              color="red"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                delete
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          </v-data-table>
                                          <div class="text-center pt-2">
                                              <v-pagination
                                              prev-icon="fa fa-angle-left"
                                              next-icon="fa fa-angle-right"
                                              class="pagination"
                                              circle
                                              color="#4CAF50"
                                                  v-model="page"
                                                  :length="pageCount"
                                                  @input="getUsers"
                                              ></v-pagination>
                                              </div>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                  </v-card>
                          </v-col>
                          </v-row>
                          </v-tab-item>

                          <v-tab-item
                          :key="'inventory'"
                          style="height: 100%!important;"
                          :value="'tab-inventory'"
                        >
                        <v-row class="mb-12 mt-3" >
                          <v-col md="12" v-if="checkPermission('inventory')">
                            
                            <v-card class="card-shadow border-radius-xl" id="basic" >
                                  <v-card-text style="">
                                    <v-row>
                                      <v-col
                                        cols="6"
                                        md="6"
                                      >
                                      <h5 class="text-h4 font-weight-bold text-typo">Inventory ({{totalCountInventory}})</h5>
                                      </v-col>
                                      <v-col
                                        cols="6"
                                        md="6"
                                      >
                                      <rotate-loader class="mt-3" :loading="loading" color="#1D75F4"></rotate-loader>
                                      <!-- <v-btn  class="mb-10" width="200px" style="float: right;" color="primary" :to="{path:'/add-parking'}" >Add Parking Lot</v-btn> -->
                                      </v-col>
                                      <v-col cols="12"
                                        md="12" >
                                         
                                                      <v-btn
                                                      color="#4CAF50"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            mr-2
                                            py-4
                                            px-8
                                            ms-auto
                                            mt-sm-auto mt-4
                                                          
                                                        "
                                                          small
                                                          @click="dialogAddNewInventory = true"
                                                      >
                                                          Add New
                                                      </v-btn>
                                                      
                                                    <v-btn
                                                color="blue"
                                              class="
                                              ont-weight-bolder
                                                btn-default
                                                mr-2
                                                py-4
                                                px-8
                                                ms-auto
                                                mt-sm-auto mt-4
                                                              
                                                            "
                                                              small
                                                              dark
                                                              @click="dialogAssignImport = true"
                                                          >
                                                              Import/Export
                                                          </v-btn>
                                                      <v-btn
                                                      color="red"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            ml-5
                                            py-4
                                            px-8
                                            ms-auto
                                            mr-2
                                            mt-sm-auto mt-4

                                                          
                                                        "
                                                          small
                                                          dark
                                                          @click="deleteItemInventory('all')"
                                                      >
                                                          mass delete
                                                      </v-btn>
                                                      <v-btn
                                                color="blue"
                                              class="
                                              ont-weight-bolder
                                                btn-default
                                                mr-2
                                                py-4
                                                px-8
                                                ms-auto
                                                mt-sm-auto mt-4
                                                              
                                                            "
                                                              small
                                                              dark
                                                              @click="dialogAssignInventory = true, assignButton = 'main'"
                                                          >
                                                              Assign Refferal Agent
                                                          </v-btn>
                                                          <v-btn
                                                color="blue"
                                              class="
                                              ont-weight-bolder
                                                btn-default
                                                mr-2
                                                py-4
                                                px-8
                                                ms-auto
                                                mt-sm-auto mt-4
                                                              
                                                            "
                                                              small
                                                              dark
                                                              @click="dialogAssignProgram = true"
                                                          >
                                                              Assign to Program
                                                          </v-btn>
                                                          
                                                          <v-menu offset-y  v-if="selectedItemsInventory.length > 0">
                                                            <template v-slot:activator="{ on, attrs }">
                                                              <v-btn
                                                             
                                                                color="blue"
                                                                    class="
                                                                    ont-weight-bolder
                                                                      btn-default
                                                                      mr-5
                                                                      py-4
                                                                      px-8
                                                                      ms-auto
                                                                      mt-sm-auto mt-4
                                                                                    
                                                                                  "
                                                                                    small
                                                                                    dark
                                                                v-bind="attrs"
                                                                v-on="on"
                                                              >
                                                                Bulk Action
                                                              </v-btn>
                                                            </template>
                                                            <v-list>
                                                              <v-list-item
                                                              @click="dialogUpdatePrice = true"
                                                                :key="1"
                                                              >
                                                                <v-list-item-title>Update Price</v-list-item-title>
                                                              </v-list-item>
                                                              <v-list-item
                                                                :key="2"
                                                                @click="packsFlag= 'multiple',openDialogPacks = true"
                                                              >
                                                                <v-list-item-title>Update Packs</v-list-item-title>
                                                              </v-list-item>
                                                              <v-list-item
                                                                :key="2"
                                                                @click="dialogAssignBodyStyle = true"
                                                              >
                                                                <v-list-item-title>Update Body Style</v-list-item-title>
                                                              </v-list-item>
                                                               <v-list-item
                                                                :key="2"
                                                                @click="dialogAssignState = true"
                                                              >
                                                                <v-list-item-title>Update State</v-list-item-title>
                                                              </v-list-item>
                                                            </v-list>
                                                          </v-menu>
                                                          
                                                     
                                        </v-col>
                                         <v-col cols="2"
                                        md="2" >
                                        <v-select class="mt-1" v-model="selectedHeaders" :items="headers_inventory_final" label="Show/Hide Columns" multiple outlined return-object>
                                            <template v-slot:selection="{ item, index }">
                                            
                                              <span v-if="index === 2" class="grey--text caption">{{ selectedHeaders.length }} Selected</span>
                                            </template>
                                        </v-select>
                                        </v-col>
                                        <v-col cols="2"
                                        md="2" >
                                          <v-select
                                                item-text="name"
                                                placeholder="Select Program"
                                                item-value="id" 
                                                label="Program"
                                                @change="getInventory"
                                                :disabled="loadingAssign"
                                                v-model="inventoryFilterProgram"
                                                :items="program_filter_options"
                                                >
                                          </v-select>
                                          </v-col>
                                      <v-col cols="2"
                                        md="2" >
                                          <v-select
                                                item-text="name"
                                                label="Body Style"
                                                placeholder="Select Body Style"
                                                item-value="id" 
                                                @change="getInventory"
                                                :disabled="loadingAssign"
                                                v-model="inventoryFilterBodyStyle"
                                                :items="['All','Coupe','Convertible','Sedan','Hybrid/Electric','Crossover/SUV','Truck','4WD/AWD','Van','Wagon']"
                                                >
                                          </v-select>
                                        </v-col>
                                        <v-col cols="1"
                                        md="1" >
                                        <v-text-field 
                                        prefix="$"
                                        label="Min Price"
                                        @input="getInventory"
                                        v-model="inventoryFilterMinPrice"
                                        ></v-text-field></v-col> <v-col cols="1"
                                        md="1" >
                                          <v-text-field 
                                          prefix="$"
                                        label="Max Price"
                                        @input="getInventory"
                                        v-model="inventoryFilterMaxPrice"
                                        ></v-text-field>
                                        </v-col>

                                         <v-col cols="1"
                                        md="1" >
                                        <v-text-field 
                                        label="Min Odometer"
                                        @input="getInventory"
                                        v-model="inventoryFilterMinOdometer"
                                        ></v-text-field></v-col> <v-col cols="1"
                                        md="1" >
                                          <v-text-field 
                                        label="Max Odometer"
                                        @input="getInventory"
                                        v-model="inventoryFilterMaxOdometer"
                                        ></v-text-field>
                                        </v-col>
                                      <v-col cols="2"
                                        md="2" >
                                          <v-spacer></v-spacer>
                                              <v-text-field
                                                v-model="search_inventory"
                                                  label="Search"
                                                  single-line
                                                  clearable
                                                  hide-details
                                              >
                                                  <template v-slot:append>
                                                  
                                                  </template>
                                                  <template v-slot:append-outer>
                                                      <v-btn
                                                      color="#4CAF50"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            py-4
                                            px-8
                                            ms-auto
                                            mt-sm-auto mt-4
                                                          
                                                        "
                                                          small
                                                          @click="pageInventory = 1,getInventory()"
                                                      >
                                                          Submit
                                                      </v-btn>
                                                  </template>
                                              </v-text-field>
                                      </v-col>
                                      
                                      <v-col cols="12"
                                        md="12">
                                        
                                          <v-data-table
                                          :headers="headers_inventory_show"
                                              :options.sync="datatable_options_inventory"
                                              :items="inventories"
                                              v-model="selectedItemsInventory"
                                              show-select
                                              loading-text="Loading... Please wait"
                                              v-sortable-table="{onEnd:sortTheHeadersAndUpdateTheKey}"
                                              :page.sync="page"
                                              :items-per-page="itemsPerPage"
                                              class="table"
                                              :key="anIncreasingNumber"
                                              :footer-props="{
                    
                                                      'items-per-page-options': [10, 50, 100,-1]
                                                    }"
                                          >
                                          <template v-slot:header.vehicle_cost="{ item }">
                                            <span style="color: red;">Vehicle Cost</span>
                                          </template>
                                          <template v-slot:header.packs="{ item }">
                                            <span style="color: red;">Packs</span>
                                          </template>
                                          <template v-slot:header.gross_profit="{ item }">
                                            <span style="color: #FADA5E;">Gross Profit</span>
                                          </template>
                                          <template v-slot:header.net_profit="{ item }">
                                            <span style="color: green;">Net Profit</span>
                                          </template>
                                          <template v-slot:item.status="{ item }">
                                              {{item.status == 1 ? 'Active' :'Inactive'}}
                                          </template>
                                          <template v-slot:item.email="{ item }">
                                              {{item.email ? item.email : 'Not Available'}}
                                          </template>
                                          <template v-slot:item.phone="{ item }">
                                              {{item.phone_m ? item.phone_m : "Not Avilable"}}
                                          </template>
                                          <template v-slot:item.exported="{ item }">
                                              {{item.exported ? 'Yes' : "No"}}
                                          </template>
                                          <template v-slot:item.created_at="{ item }">
                                              {{dateFormat(item.created_at)}}
                                          </template>
                                          <template v-slot:item.vehicle_cost="{ item }">
                                              <span style="color:red">  {{currencyFormat(item.vehicle_cost)}} </span>
                                          </template>
                                          <template v-slot:item.packs="{ item }">
                                              <span @click="editPacks(item)" style="cursor:pointer;color:red">{{currencyFormat(item.packs)}}</span>
                                          </template>
                                          <template v-slot:item.total_cost="{ item }">
                                              {{currencyFormat(item.total_cost)}}
                                          </template>
                                          <template v-slot:item.selling_cost="{ item }">
                                              {{currencyFormat(item.selling_cost)}}
                                          </template>
                                          <template v-slot:item.net_profit="{ item }">
                                              <span style="color:green">{{currencyFormat(item.net_profit)}}</span>
                                          </template>
                                           <template v-slot:item.gross_profit="{ item }">
                                              <span style="color:#FADA5E;"> {{currencyFormat(item.gross_profit)}} </span>
                                          </template>
                                          <template v-slot:item.total_tax="{ item }">
                                              {{currencyFormat(item.total_tax)}}
                                          </template>
                                          <template v-slot:item.password="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                              @click="dialogPassword = true, selectedPasswordId = item.id,selectedPassword = generatePassword()"
                                              x-small
                                              color="blue"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                key
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.account_id="{ item }">
                                              <a style="color:#2196F3" href="#" @click="selectedEditId = item.id,openDialogEdit = true">{{ item.account_id }} <v-btn
                                                elevation="0"
                                                v-if="item.default_flag"
                                                x-small
                                                :ripple="false"
                                                class="
                                                  border-radius-md
                                                  font-weight-bolder
                                                  px-3
                                                  py-3
                                                  badge-font-size
                                                  ms-auto mr-1
                                                  text-success
                                                "
                                                color="#bce2be"
                                                >Default</v-btn
                                              >
                                              <v-btn
                                                elevation="0"
                                                v-if="item.below_credit_flag"
                                                x-small
                                                :ripple="false"
                                                class="
                                                  border-radius-md
                                                  font-weight-bolder
                                                  px-3
                                                  py-3
                                                  badge-font-size
                                                  ms-auto
                                                  text-white mr-1
                                                "
                                                color="red"
                                                >Below 640</v-btn
                                              >
                                              <v-btn
                                                elevation="0"
                                                v-if="item.above_credit_flag"
                                                x-small
                                                :ripple="false"
                                                class="
                                                  border-radius-md
                                                  font-weight-bolder
                                                  px-3
                                                  py-3
                                                  badge-font-size
                                                  ms-auto
                                                  text-white ml-1 mr-1
                                                "
                                                color="green"
                                                >Above 640</v-btn
                                              >
                                              <v-btn
                                                elevation="0"
                                                v-if="item.bhph"
                                                x-small
                                                :ripple="false"
                                                class="
                                                  border-radius-md
                                                  font-weight-bolder
                                                  px-3
                                                  py-3
                                                  badge-font-size
                                                  ms-auto
                                                  text-white ml-2 mr-1
                                                "
                                                color="blue"
                                                >BHPH</v-btn
                                              ><v-btn
                                                elevation="0"
                                                v-if="item.category_cl"
                                                x-small
                                                :ripple="false"
                                                class="
                                                  border-radius-md
                                                  font-weight-bolder
                                                  px-3
                                                  py-3
                                                  badge-font-size
                                                  ms-auto
                                                  text-white ml-2
                                                "
                                                color="orange"
                                                >Clearance</v-btn
                                              >
                                              
                                              </a>
                                          </template>

                                          <template v-slot:item.url="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="dialogURL =true, company_url = item.company_url ? item.company_url : ''"
                                              x-small
                                              color="orange"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>

                                          <template v-slot:item.agreement_name="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="viewContract(item.id)"
                                              x-small
                                              color="orange"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>

                                          <template v-slot:item.credit_new="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'add'"
                                              x-small
                                              color="green"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                add_card
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.credit_remove="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'remove'"
                                              x-small
                                              color="red"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                credit_card_off
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <!-- <template v-slot:item.address="{ item }">
                                          <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                                          </span>
                                          </template> -->
                                          <template v-slot:item.status_id="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="changeStatus(item.user_id,item.status)"
                                              x-small
                                              :color="item.status==1 ? 'red':'green'"
                                            >
                                            {{item.status==1 ? 'Deactivate':'Activate'}}
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.document="{ item }">
                                            {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                                          </template>
                                          <template v-slot:item.process1="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="dialogDoc = true, selected_id = item.user_id"
                                              x-small
                                              v-if="item.process_1 == 0"
                                              :color="'green'"
                                            >
                                            Start
                                            </v-btn>
                                            <v-btn
                                              class="mx-2"
                                              disabled

                                              @click="dialogDoc = true"
                                              x-small
                                              v-if="item.process_1 == 1"
                                              :color="'green'"
                                            >
                                            Finished
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.process_1_key="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              
                                              @click="dialogDoc = true, selected_id = item.user_id"
                                              x-small
                                              disabled
                                              v-if="item.process_1 == 0"
                                              :color="'green'"
                                            >
                                            View
                                            </v-btn>
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="getProcessStatus(item.process_1_key)"
                                              x-small
                                              v-if="item.process_1 == 1"
                                              :color="'blue'"
                                            >
                                            View
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.referral_agent="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              text
                                              @click="dialogAssignedUser = true, getAssigedUser(item.id)"
                                              x-small
                                              :color=" 'orange'"
                                            >
                                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                                              visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.ghl_chat_flag="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              text
                                              @click="syncGhlChat(item.id, item.ghl_chat_flag)"
                                              x-small
                                              :color="item.ghl_chat_flag==1 ? 'green':'red'"
                                            >
                                            <v-icon v-if="item.ghl_chat_flag==1" size="40" class="material-icons-round opacity-10" dark>
                                                toggle_on
                                              </v-icon>
                                              <v-icon v-else size="40" class="material-icons-round opacity-10" dark>
                                                toggle_off
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.id="{ item }">

                                            <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                              @click="deleteItem(item.id)"
                                              x-small
                                              color="red"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                delete
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          </v-data-table>
                                          <div class="text-center pt-2">
                                              <v-pagination
                                              prev-icon="fa fa-angle-left"
                                              next-icon="fa fa-angle-right"
                                              class="pagination"
                                              circle
                                              color="#4CAF50"
                                                  v-model="pageInventory"
                                                  :length="pageCountInventory"
                                                  @input="getInventory"
                                              ></v-pagination>
                                              </div>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                  </v-card>
                          </v-col>


                          <v-col md="12" v-if="checkPermission('car_of_the_week')">
                            
                            <v-card class="card-shadow border-radius-xl" id="basic" >
                                  <v-card-text style="">
                                    <v-row>
                                      <v-col
                                        cols="6"
                                        md="6"
                                      >
                                      <h5 class="text-h4 font-weight-bold text-typo">Car Of The Week ({{totalCountInventoryCOW}})</h5>
                                      </v-col>
                                      <v-col
                                        cols="6"
                                        md="6"
                                      >
                                      <rotate-loader class="mt-3" :loading="loading" color="#1D75F4"></rotate-loader>
                                      <!-- <v-btn  class="mb-10" width="200px" style="float: right;" color="primary" :to="{path:'/add-parking'}" >Add Parking Lot</v-btn> -->
                                      </v-col>
                                      <v-col cols="12"
                                        md="12" >
                                         
                                                      <vue-json-to-csv :json-data="users"
                                                      :labels="{ account_id: { title: 'AccountID' },dcid: { title: 'DCID' },dealer_name: { title: 'Dealer Name' },vin : {title : 'VIN'},odometer : {title : 'Odometer'},address: { title: 'Address' },city: { title: 'City' }, state: { title: 'State' },zip: { title: 'Zip' },  phone: { title: 'Phone' },  stock_number: { title: 'Stock Number' },  make: { title: 'make' }, model: { title: 'model' },year: { title: 'Year' }, trim: { title: 'Trim' },  amount: { title: 'Amount' },exterior_color: { title: 'Exterior Color' }, interior_color: { title: 'Interior Color' }, transmission: { title: 'Transmission' }, }"
                                                      csv-title="inventory"
                                                      >
                                                      <v-btn
                                                      color="blue"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            py-4
                                            px-8
                                            mr-3
                                            ms-auto
                                            mt-sm-auto mt-4
                                                          
                                                        "
                                                      dark
                                                          small
                                                      >
                                                          Export to csv
                                                      </v-btn>
                                                    </vue-json-to-csv>
                                                      <v-btn
                                                      color="red"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            ml-5
                                            py-4
                                            px-8
                                            ms-auto
                                            mr-3
                                            mt-sm-auto mt-4

                                                          
                                                        "
                                                          small
                                                          dark
                                                          @click="deleteItemInventoryType('car_of_the_week')"
                                                      >
                                                          mass delete
                                                      </v-btn>
                                                      <v-btn
                                                color="blue"
                                              class="
                                              ont-weight-bolder
                                                btn-default
                                                mr-2
                                                py-4
                                                px-8
                                                ms-auto
                                                mt-sm-auto mt-4
                                                              
                                                            "
                                                              small
                                                              dark
                                                              @click="dialogAssignInventory = true, assignButton = 'cow'"
                                                          >
                                                              Assign Refferal Agent
                                                          </v-btn>
                                                      
                                                         
                                                     
                                        </v-col>
                                      <v-col cols="12"
                                        md="12" >
                                          <v-spacer></v-spacer>
                                              <v-text-field
                                                v-model="search_inventory_cow"
                                                  label="Search"
                                                  single-line
                                                  clearable
                                                  hide-details
                                              >
                                                  <template v-slot:append>
                                                  
                                                  </template>
                                                  <template v-slot:append-outer>
                                                      <v-btn
                                                      color="#4CAF50"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            py-4
                                            px-8
                                            ms-auto
                                            mt-sm-auto mt-4
                                                          
                                                        "
                                                          small
                                                          @click="getInventoryCOW"
                                                      >
                                                          Submit
                                                      </v-btn>
                                                  </template>
                                              </v-text-field>
                                      </v-col>
                                      <v-col cols="12"
                                        md="12">
                                        
                                          <v-data-table
                                          :headers="headers_inventory"
                                              :options.sync="datatable_options_inventory_cow"
                                              :items="inventories_cow"
                                              v-model="selectedItemsInventoryCOW"
                                              show-select
                                              loading-text="Loading... Please wait"
                                              :page.sync="page"
                                              :items-per-page="itemsPerPage"
                                              class="table"
                                              :footer-props="{
                    
                                                      'items-per-page-options': [10, 50, 100,-1]
                                                    }"
                                          >
                                      
                                          <template v-slot:item.status="{ item }">
                                              {{item.status == 1 ? 'Active' :'Inactive'}}
                                          </template>
                                          <template v-slot:item.email="{ item }">
                                              {{item.email ? item.email : 'Not Available'}}
                                          </template>
                                          <template v-slot:item.phone="{ item }">
                                              {{item.phone_m ? item.phone_m : "Not Avilable"}}
                                          </template>
                                          <template v-slot:item.exported="{ item }">
                                              {{item.exported ? 'Yes' : "No"}}
                                          </template>
                                          <template v-slot:item.exported="{ item }">
                                              {{item.exported ? 'Yes' : "No"}}
                                          </template>
                                          <template v-slot:item.created_at="{ item }">
                                              {{dateFormat(item.created_at)}}
                                          </template>
                                          <template v-slot:item.vehicle_cost="{ item }">
                                              {{currencyFormat(item.vehicle_cost)}}
                                          </template>
                                          <template v-slot:item.total_cost="{ item }">
                                              {{currencyFormat(item.total_cost)}}
                                          </template>
                                          <template v-slot:item.selling_cost="{ item }">
                                              {{currencyFormat(item.selling_cost)}}
                                          </template>
                                          <template v-slot:item.net_profit="{ item }">
                                              {{currencyFormat(item.net_profit)}}
                                          </template>
                                          <template v-slot:item.password="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                              @click="dialogPassword = true, selectedPasswordId = item.id,selectedPassword = generatePassword()"
                                              x-small
                                              color="blue"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                key
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.account_id="{ item }">
                                              <a style="color:#2196F3" :href="'vehicle-detail/'+item.id" target="_blank">{{ item.account_id }}</a>
                                          </template>

                                          <template v-slot:item.url="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="dialogURL =true, company_url = item.company_url ? item.company_url : ''"
                                              x-small
                                              color="orange"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>

                                          <template v-slot:item.agreement_name="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="viewContract(item.id)"
                                              x-small
                                              color="orange"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>

                                          <template v-slot:item.credit_new="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'add'"
                                              x-small
                                              color="green"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                add_card
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.credit_remove="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'remove'"
                                              x-small
                                              color="red"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                credit_card_off
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.address="{ item }">
                                          <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                                          </span>
                                          </template>
                                          <template v-slot:item.status_id="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="changeStatus(item.user_id,item.status)"
                                              x-small
                                              :color="item.status==1 ? 'red':'green'"
                                            >
                                            {{item.status==1 ? 'Deactivate':'Activate'}}
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.document="{ item }">
                                            {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                                          </template>
                                          <template v-slot:item.process1="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="dialogDoc = true, selected_id = item.user_id"
                                              x-small
                                              v-if="item.process_1 == 0"
                                              :color="'green'"
                                            >
                                            Start
                                            </v-btn>
                                            <v-btn
                                              class="mx-2"
                                              disabled

                                              @click="dialogDoc = true"
                                              x-small
                                              v-if="item.process_1 == 1"
                                              :color="'green'"
                                            >
                                            Finished
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.process_1_key="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              
                                              @click="dialogDoc = true, selected_id = item.user_id"
                                              x-small
                                              disabled
                                              v-if="item.process_1 == 0"
                                              :color="'green'"
                                            >
                                            View
                                            </v-btn>
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="getProcessStatus(item.process_1_key)"
                                              x-small
                                              v-if="item.process_1 == 1"
                                              :color="'blue'"
                                            >
                                            View
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.referral_agent="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              text
                                              @click="dialogAssignedUser = true, getAssigedUser(item.id)"
                                              x-small
                                              :color=" 'orange'"
                                            >
                                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                                              visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.ghl_chat_flag="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              text
                                              @click="syncGhlChat(item.id, item.ghl_chat_flag)"
                                              x-small
                                              :color="item.ghl_chat_flag==1 ? 'green':'red'"
                                            >
                                            <v-icon v-if="item.ghl_chat_flag==1" size="40" class="material-icons-round opacity-10" dark>
                                                toggle_on
                                              </v-icon>
                                              <v-icon v-else size="40" class="material-icons-round opacity-10" dark>
                                                toggle_off
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.id="{ item }">

                                            <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                              @click="deleteItemInventoryType(item.id)"
                                              x-small
                                              color="red"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                delete
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          </v-data-table>
                                          <div class="text-center pt-2">
                                              <v-pagination
                                              prev-icon="fa fa-angle-left"
                                              next-icon="fa fa-angle-right"
                                              class="pagination"
                                              circle
                                              color="#4CAF50"
                                                  v-model="pageInventoryCOW"
                                                  :length="pageCountInventoryCOW"
                                                  @input="getInventoryCOW"
                                              ></v-pagination>
                                              </div>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                  </v-card>
                          </v-col>

                          <v-col md="12" v-if="checkPermission('clearance')">
                            
                            <v-card class="card-shadow border-radius-xl" id="basic" >
                                  <v-card-text style="">
                                    <v-row>
                                      <v-col
                                        cols="6"
                                        md="6"
                                      >
                                      <h5 class="text-h4 font-weight-bold text-typo">Clearance ({{totalCountInventoryCL}})</h5>
                                      </v-col>
                                      <v-col
                                        cols="6"
                                        md="6"
                                      >
                                      <rotate-loader class="mt-3" :loading="loading" color="#1D75F4"></rotate-loader>
                                      <!-- <v-btn  class="mb-10" width="200px" style="float: right;" color="primary" :to="{path:'/add-parking'}" >Add Parking Lot</v-btn> -->
                                      </v-col>
                                      <v-col cols="12"
                                        md="12" >
                                         
                                                      <vue-json-to-csv :json-data="users"
                                                      :labels="{ account_id: { title: 'AccountID' },dcid: { title: 'DCID' },dealer_name: { title: 'Dealer Name' },vin : {title : 'VIN'},odometer : {title : 'Odometer'},address: { title: 'Address' },city: { title: 'City' }, state: { title: 'State' },zip: { title: 'Zip' },  phone: { title: 'Phone' },  stock_number: { title: 'Stock Number' },  make: { title: 'make' }, model: { title: 'model' },year: { title: 'Year' }, trim: { title: 'Trim' },  amount: { title: 'Amount' },exterior_color: { title: 'Exterior Color' }, interior_color: { title: 'Interior Color' }, transmission: { title: 'Transmission' }, }"
                                                      csv-title="inventory"
                                                      >
                                                      <v-btn
                                                      color="blue"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            py-4
                                            px-8
                                            mr-3
                                            ms-auto
                                            mt-sm-auto mt-4
                                                          
                                                        "
                                                      dark
                                                          small
                                                      >
                                                          Export to csv
                                                      </v-btn>
                                                    </vue-json-to-csv>
                                                      <v-btn
                                                      color="red"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            ml-5
                                            py-4
                                            px-8
                                            ms-auto
                                            mr-3
                                            mt-sm-auto mt-4

                                                          
                                                        "
                                                          small
                                                          dark
                                                          @click="deleteItemInventoryType('clearance')"
                                                      >
                                                          mass delete
                                                      </v-btn>
                                                      <v-btn
                                                color="blue"
                                              class="
                                              ont-weight-bolder
                                                btn-default
                                                mr-2
                                                py-4
                                                px-8
                                                ms-auto
                                                mt-sm-auto mt-4
                                                              
                                                            "
                                                              small
                                                              dark
                                                              @click="dialogAssignInventory = true, assignButton = 'clearance'"
                                                          >
                                                              Assign Refferal Agent
                                                          </v-btn>
                                                      
                                                         
                                                     
                                        </v-col>
                                      <v-col cols="12"
                                        md="12" >
                                          <v-spacer></v-spacer>
                                              <v-text-field
                                                v-model="search_inventory_cl"
                                                  label="Search"
                                                  single-line
                                                  clearable
                                                  hide-details
                                              >
                                                  <template v-slot:append>
                                                  
                                                  </template>
                                                  <template v-slot:append-outer>
                                                      <v-btn
                                                      color="#4CAF50"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            py-4
                                            px-8
                                            ms-auto
                                            mt-sm-auto mt-4
                                                          
                                                        "
                                                          small
                                                          @click="getInventoryCL"
                                                      >
                                                          Submit
                                                      </v-btn>
                                                  </template>
                                              </v-text-field>
                                      </v-col>
                                      <v-col cols="12"
                                        md="12">
                                        
                                          <v-data-table
                                          :headers="headers_inventory"
                                              :options.sync="datatable_options_inventory_cl"
                                              :items="inventories_cl"
                                              v-model="selectedItemsInventoryCL"
                                              show-select
                                              loading-text="Loading... Please wait"
                                              :page.sync="page"
                                              :items-per-page="itemsPerPage"
                                              class="table"
                                              :footer-props="{
                    
                                                      'items-per-page-options': [10, 50, 100,-1]
                                                    }"
                                          >
                                      
                                          <template v-slot:item.status="{ item }">
                                              {{item.status == 1 ? 'Active' :'Inactive'}}
                                          </template>
                                          <template v-slot:item.email="{ item }">
                                              {{item.email ? item.email : 'Not Available'}}
                                          </template>
                                          <template v-slot:item.phone="{ item }">
                                              {{item.phone_m ? item.phone_m : "Not Avilable"}}
                                          </template>
                                          <template v-slot:item.exported="{ item }">
                                              {{item.exported ? 'Yes' : "No"}}
                                          </template>
                                          <template v-slot:item.created_at="{ item }">
                                              {{dateFormat(item.created_at)}}
                                          </template>
                                          <template v-slot:item.vehicle_cost="{ item }">
                                              {{currencyFormat(item.vehicle_cost)}}
                                          </template>
                                          <template v-slot:item.total_cost="{ item }">
                                              {{currencyFormat(item.total_cost)}}
                                          </template>
                                          <template v-slot:item.selling_cost="{ item }">
                                              {{currencyFormat(item.selling_cost)}}
                                          </template>
                                          <template v-slot:item.net_profit="{ item }">
                                              {{currencyFormat(item.net_profit)}}
                                          </template>
                                          <template v-slot:item.password="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                              @click="dialogPassword = true, selectedPasswordId = item.id,selectedPassword = generatePassword()"
                                              x-small
                                              color="blue"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                key
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.account_id="{ item }">
                                              <a style="color:#2196F3" :href="'vehicle-detail/'+item.id" target="_blank">{{ item.account_id }}</a>
                                          </template>

                                          <template v-slot:item.url="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="dialogURL =true, company_url = item.company_url ? item.company_url : ''"
                                              x-small
                                              color="orange"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>

                                          <template v-slot:item.agreement_name="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="viewContract(item.id)"
                                              x-small
                                              color="orange"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>

                                          <template v-slot:item.credit_new="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'add'"
                                              x-small
                                              color="green"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                add_card
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.credit_remove="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'remove'"
                                              x-small
                                              color="red"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                credit_card_off
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.address="{ item }">
                                          <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                                          </span>
                                          </template>
                                          <template v-slot:item.status_id="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="changeStatus(item.user_id,item.status)"
                                              x-small
                                              :color="item.status==1 ? 'red':'green'"
                                            >
                                            {{item.status==1 ? 'Deactivate':'Activate'}}
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.document="{ item }">
                                            {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                                          </template>
                                          <template v-slot:item.process1="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="dialogDoc = true, selected_id = item.user_id"
                                              x-small
                                              v-if="item.process_1 == 0"
                                              :color="'green'"
                                            >
                                            Start
                                            </v-btn>
                                            <v-btn
                                              class="mx-2"
                                              disabled

                                              @click="dialogDoc = true"
                                              x-small
                                              v-if="item.process_1 == 1"
                                              :color="'green'"
                                            >
                                            Finished
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.process_1_key="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              
                                              @click="dialogDoc = true, selected_id = item.user_id"
                                              x-small
                                              disabled
                                              v-if="item.process_1 == 0"
                                              :color="'green'"
                                            >
                                            View
                                            </v-btn>
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="getProcessStatus(item.process_1_key)"
                                              x-small
                                              v-if="item.process_1 == 1"
                                              :color="'blue'"
                                            >
                                            View
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.referral_agent="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              text
                                              @click="dialogAssignedUser = true, getAssigedUser(item.id)"
                                              x-small
                                              :color=" 'orange'"
                                            >
                                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                                              visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.ghl_chat_flag="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              text
                                              @click="syncGhlChat(item.id, item.ghl_chat_flag)"
                                              x-small
                                              :color="item.ghl_chat_flag==1 ? 'green':'red'"
                                            >
                                            <v-icon v-if="item.ghl_chat_flag==1" size="40" class="material-icons-round opacity-10" dark>
                                                toggle_on
                                              </v-icon>
                                              <v-icon v-else size="40" class="material-icons-round opacity-10" dark>
                                                toggle_off
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.id="{ item }">

                                            <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                              @click="deleteItemInventoryType(item.id)"
                                              x-small
                                              color="red"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                delete
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          </v-data-table>
                                          <div class="text-center pt-2">
                                              <v-pagination
                                              prev-icon="fa fa-angle-left"
                                              next-icon="fa fa-angle-right"
                                              class="pagination"
                                              circle
                                              color="#4CAF50"
                                                  v-model="pageInventoryCL"
                                                  :length="pageCountInventoryCL"
                                                  @input="getInventoryCL"
                                              ></v-pagination>
                                              </div>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                  </v-card>
                          </v-col>
                          <v-col md="12" v-if="checkPermission('below_640')"> 
                            
                            <v-card class="card-shadow border-radius-xl" id="basic" >
                                  <v-card-text style="">
                                    <v-row>
                                      <v-col
                                        cols="6"
                                        md="6"
                                      >
                                      <h5 class="text-h4 font-weight-bold text-typo">Below 640 ({{totalCountInventorybelow}})</h5>
                                      </v-col>
                                      <v-col
                                        cols="6"
                                        md="6"
                                      >
                                      <rotate-loader class="mt-3" :loading="loading" color="#1D75F4"></rotate-loader>
                                      <!-- <v-btn  class="mb-10" width="200px" style="float: right;" color="primary" :to="{path:'/add-parking'}" >Add Parking Lot</v-btn> -->
                                      </v-col>
                                      <v-col cols="12"
                                        md="12" >
                                         
                                                      <vue-json-to-csv :json-data="users"
                                                      :labels="{ account_id: { title: 'AccountID' },dcid: { title: 'DCID' },dealer_name: { title: 'Dealer Name' },vin : {title : 'VIN'},odometer : {title : 'Odometer'},address: { title: 'Address' },city: { title: 'City' }, state: { title: 'State' },zip: { title: 'Zip' },  phone: { title: 'Phone' },  stock_number: { title: 'Stock Number' },  make: { title: 'make' }, model: { title: 'model' },year: { title: 'Year' }, trim: { title: 'Trim' },  amount: { title: 'Amount' },exterior_color: { title: 'Exterior Color' }, interior_color: { title: 'Interior Color' }, transmission: { title: 'Transmission' }, }"
                                                      csv-title="inventory"
                                                      >
                                                      <v-btn
                                                      color="blue"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            py-4
                                            px-8
                                            mr-3
                                            ms-auto
                                            mt-sm-auto mt-4
                                                          
                                                        "
                                                      dark
                                                          small
                                                      >
                                                          Export to csv
                                                      </v-btn>
                                                    </vue-json-to-csv>
                                                      <v-btn
                                                      color="red"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            ml-5
                                            py-4
                                            px-8
                                            ms-auto
                                            mr-3
                                            mt-sm-auto mt-4

                                                          
                                                        "
                                                          small
                                                          dark
                                                          @click="deleteItemInventoryType('below_credit_flag')"
                                                      >
                                                          mass delete
                                                      </v-btn>
                                                      <v-btn
                                                color="blue"
                                              class="
                                              ont-weight-bolder
                                                btn-default
                                                mr-2
                                                py-4
                                                px-8
                                                ms-auto
                                                mt-sm-auto mt-4
                                                              
                                                            "
                                                              small
                                                              dark
                                                              @click="dialogAssignInventory = true, assignButton = 'below_credit_flag'"
                                                          >
                                                              Assign Refferal Agent
                                                          </v-btn>
                                                      
                                                         
                                                     
                                        </v-col>
                                      <v-col cols="12"
                                        md="12" >
                                          <v-spacer></v-spacer>
                                              <v-text-field
                                                v-model="search_inventory_below"
                                                  label="Search"
                                                  single-line
                                                  clearable
                                                  hide-details
                                              >
                                                  <template v-slot:append>
                                                  
                                                  </template>
                                                  <template v-slot:append-outer>
                                                      <v-btn
                                                      color="#4CAF50"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            py-4
                                            px-8
                                            ms-auto
                                            mt-sm-auto mt-4
                                                          
                                                        "
                                                          small
                                                          @click="getInventoryBelow"
                                                      >
                                                          Submit
                                                      </v-btn>
                                                  </template>
                                              </v-text-field>
                                      </v-col>
                                      <v-col cols="12"
                                        md="12">
                                        
                                          <v-data-table
                                          :headers="headers_inventory"
                                              :options.sync="datatable_options_inventory_below"
                                              :items="inventories_below"
                                              v-model="selectedItemsInventoryBelow"
                                              show-select
                                              loading-text="Loading... Please wait"
                                              :page.sync="page"
                                              :items-per-page="itemsPerPage"
                                              class="table"
                                              :footer-props="{
                    
                                                      'items-per-page-options': [10, 50, 100,-1]
                                                    }"
                                          >
                                      
                                          <template v-slot:item.status="{ item }">
                                              {{item.status == 1 ? 'Active' :'Inactive'}}
                                          </template>
                                          <template v-slot:item.email="{ item }">
                                              {{item.email ? item.email : 'Not Available'}}
                                          </template>
                                          <template v-slot:item.phone="{ item }">
                                              {{item.phone_m ? item.phone_m : "Not Avilable"}}
                                          </template>
                                          <template v-slot:item.exported="{ item }">
                                              {{item.exported ? 'Yes' : "No"}}
                                          </template>
                                          <template v-slot:item.created_at="{ item }">
                                              {{dateFormat(item.created_at)}}
                                          </template>
                                          <template v-slot:item.vehicle_cost="{ item }">
                                              {{currencyFormat(item.vehicle_cost)}}
                                          </template>
                                          <template v-slot:item.total_cost="{ item }">
                                              {{currencyFormat(item.total_cost)}}
                                          </template>
                                          <template v-slot:item.selling_cost="{ item }">
                                              {{currencyFormat(item.selling_cost)}}
                                          </template>
                                          <template v-slot:item.net_profit="{ item }">
                                              {{currencyFormat(item.net_profit)}}
                                          </template>
                                          <template v-slot:item.password="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                              @click="dialogPassword = true, selectedPasswordId = item.id,selectedPassword = generatePassword()"
                                              x-small
                                              color="blue"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                key
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.account_id="{ item }">
                                              <a style="color:#2196F3" :href="'vehicle-detail/'+item.id" target="_blank">{{ item.account_id }}</a>
                                          </template>

                                          <template v-slot:item.url="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="dialogURL =true, company_url = item.company_url ? item.company_url : ''"
                                              x-small
                                              color="orange"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>

                                          <template v-slot:item.agreement_name="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="viewContract(item.id)"
                                              x-small
                                              color="orange"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>

                                          <template v-slot:item.credit_new="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'add'"
                                              x-small
                                              color="green"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                add_card
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.credit_remove="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'remove'"
                                              x-small
                                              color="red"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                credit_card_off
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.address="{ item }">
                                          <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                                          </span>
                                          </template>
                                          <template v-slot:item.status_id="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="changeStatus(item.user_id,item.status)"
                                              x-small
                                              :color="item.status==1 ? 'red':'green'"
                                            >
                                            {{item.status==1 ? 'Deactivate':'Activate'}}
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.document="{ item }">
                                            {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                                          </template>
                                          <template v-slot:item.process1="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="dialogDoc = true, selected_id = item.user_id"
                                              x-small
                                              v-if="item.process_1 == 0"
                                              :color="'green'"
                                            >
                                            Start
                                            </v-btn>
                                            <v-btn
                                              class="mx-2"
                                              disabled

                                              @click="dialogDoc = true"
                                              x-small
                                              v-if="item.process_1 == 1"
                                              :color="'green'"
                                            >
                                            Finished
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.process_1_key="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              
                                              @click="dialogDoc = true, selected_id = item.user_id"
                                              x-small
                                              disabled
                                              v-if="item.process_1 == 0"
                                              :color="'green'"
                                            >
                                            View
                                            </v-btn>
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="getProcessStatus(item.process_1_key)"
                                              x-small
                                              v-if="item.process_1 == 1"
                                              :color="'blue'"
                                            >
                                            View
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.referral_agent="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              text
                                              @click="dialogAssignedUser = true, getAssigedUser(item.id)"
                                              x-small
                                              :color=" 'orange'"
                                            >
                                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                                              visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.ghl_chat_flag="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              text
                                              @click="syncGhlChat(item.id, item.ghl_chat_flag)"
                                              x-small
                                              :color="item.ghl_chat_flag==1 ? 'green':'red'"
                                            >
                                            <v-icon v-if="item.ghl_chat_flag==1" size="40" class="material-icons-round opacity-10" dark>
                                                toggle_on
                                              </v-icon>
                                              <v-icon v-else size="40" class="material-icons-round opacity-10" dark>
                                                toggle_off
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.id="{ item }">

                                            <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                              @click="deleteItemInventoryType(item.id)"
                                              x-small
                                              color="red"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                delete
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          </v-data-table>
                                          <div class="text-center pt-2">
                                              <v-pagination
                                              prev-icon="fa fa-angle-left"
                                              next-icon="fa fa-angle-right"
                                              class="pagination"
                                              circle
                                              color="#4CAF50"
                                                  v-model="pageInventoryBelow"
                                                  :length="pageCountInventoryBelow"
                                                  @input="getInventoryBelow"
                                              ></v-pagination>
                                              </div>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                  </v-card>
                          </v-col>
                          <v-col md="12" v-if="checkPermission('above_640')">
                            
                            <v-card class="card-shadow border-radius-xl" id="basic" >
                                  <v-card-text style="">
                                    <v-row>
                                      <v-col
                                        cols="6"
                                        md="6"
                                      >
                                      <h5 class="text-h4 font-weight-bold text-typo">Above 640 ({{totalCountInventoryAbove}})</h5>
                                      </v-col>
                                      <v-col
                                        cols="6"
                                        md="6"
                                      >
                                      <rotate-loader class="mt-3" :loading="loading" color="#1D75F4"></rotate-loader>
                                      <!-- <v-btn  class="mb-10" width="200px" style="float: right;" color="primary" :to="{path:'/add-parking'}" >Add Parking Lot</v-btn> -->
                                      </v-col>
                                      <v-col cols="12"
                                        md="12" >
                                         
                                                      <vue-json-to-csv :json-data="users"
                                                      :labels="{ account_id: { title: 'AccountID' },dcid: { title: 'DCID' },dealer_name: { title: 'Dealer Name' },vin : {title : 'VIN'},odometer : {title : 'Odometer'},address: { title: 'Address' },city: { title: 'City' }, state: { title: 'State' },zip: { title: 'Zip' },  phone: { title: 'Phone' },  stock_number: { title: 'Stock Number' },  make: { title: 'make' }, model: { title: 'model' },year: { title: 'Year' }, trim: { title: 'Trim' },  amount: { title: 'Amount' },exterior_color: { title: 'Exterior Color' }, interior_color: { title: 'Interior Color' }, transmission: { title: 'Transmission' }, }"
                                                      csv-title="inventory"
                                                      >
                                                      <v-btn
                                                      color="blue"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            py-4
                                            px-8
                                            mr-3
                                            ms-auto
                                            mt-sm-auto mt-4
                                                          
                                                        "
                                                      dark
                                                          small
                                                      >
                                                          Export to csv
                                                      </v-btn>
                                                    </vue-json-to-csv>
                                                      <v-btn
                                                      color="red"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            ml-5
                                            py-4
                                            px-8
                                            ms-auto
                                            mr-3
                                            mt-sm-auto mt-4

                                                          
                                                        "
                                                          small
                                                          dark
                                                          @click="deleteItemInventoryType('above_credit_flag')"
                                                      >
                                                          mass delete
                                                      </v-btn>
                                                      <v-btn
                                                color="blue"
                                              class="
                                              ont-weight-bolder
                                                btn-default
                                                mr-2
                                                py-4
                                                px-8
                                                ms-auto
                                                mt-sm-auto mt-4
                                                              
                                                            "
                                                              small
                                                              dark
                                                              @click="dialogAssignInventory = true, assignButton = 'above_credit_flag'"
                                                          >
                                                              Assign Refferal Agent
                                                          </v-btn>
                                                      
                                                         
                                                     
                                        </v-col>
                                      <v-col cols="12"
                                        md="12" >
                                          <v-spacer></v-spacer>
                                              <v-text-field
                                                v-model="search_inventory_above"
                                                  label="Search"
                                                  single-line
                                                  clearable
                                                  hide-details
                                              >
                                                  <template v-slot:append>
                                                  
                                                  </template>
                                                  <template v-slot:append-outer>
                                                      <v-btn
                                                      color="#4CAF50"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            py-4
                                            px-8
                                            ms-auto
                                            mt-sm-auto mt-4
                                                          
                                                        "
                                                          small
                                                          @click="getInventoryAbove"
                                                      >
                                                          Submit
                                                      </v-btn>
                                                  </template>
                                              </v-text-field>
                                      </v-col>
                                      <v-col cols="12"
                                        md="12">
                                        
                                          <v-data-table
                                          :headers="headers_inventory"
                                              :options.sync="datatable_options_inventory_above"
                                              :items="inventories_above"
                                              v-model="selectedItemsInventoryAbove"
                                              show-select
                                              loading-text="Loading... Please wait"
                                              :page.sync="page"
                                              :items-per-page="itemsPerPage"
                                              class="table"
                                              :footer-props="{
                    
                                                      'items-per-page-options': [10, 50, 100,-1]
                                                    }"
                                          >
                                      
                                          <template v-slot:item.status="{ item }">
                                              {{item.status == 1 ? 'Active' :'Inactive'}}
                                          </template>
                                          <template v-slot:item.email="{ item }">
                                              {{item.email ? item.email : 'Not Available'}}
                                          </template>
                                          <template v-slot:item.phone="{ item }">
                                              {{item.phone_m ? item.phone_m : "Not Avilable"}}
                                          </template>
                                          <template v-slot:item.exported="{ item }">
                                              {{item.exported ? 'Yes' : "No"}}
                                          </template>
                                          <template v-slot:item.created_at="{ item }">
                                              {{dateFormat(item.created_at)}}
                                          </template>
                                          <template v-slot:item.vehicle_cost="{ item }">
                                              {{currencyFormat(item.vehicle_cost)}}
                                          </template>
                                          <template v-slot:item.total_cost="{ item }">
                                              {{currencyFormat(item.total_cost)}}
                                          </template>
                                          <template v-slot:item.selling_cost="{ item }">
                                              {{currencyFormat(item.selling_cost)}}
                                          </template>
                                          <template v-slot:item.net_profit="{ item }">
                                              {{currencyFormat(item.net_profit)}}
                                          </template>
                                          <template v-slot:item.password="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                              @click="dialogPassword = true, selectedPasswordId = item.id,selectedPassword = generatePassword()"
                                              x-small
                                              color="blue"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                key
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.account_id="{ item }">
                                              <a style="color:#2196F3" :href="'vehicle-detail/'+item.id" target="_blank">{{ item.account_id }}</a>
                                          </template>

                                          <template v-slot:item.url="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="dialogURL =true, company_url = item.company_url ? item.company_url : ''"
                                              x-small
                                              color="orange"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>

                                          <template v-slot:item.agreement_name="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="viewContract(item.id)"
                                              x-small
                                              color="orange"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>

                                          <template v-slot:item.credit_new="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'add'"
                                              x-small
                                              color="green"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                add_card
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.credit_remove="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'remove'"
                                              x-small
                                              color="red"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                credit_card_off
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.address="{ item }">
                                          <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                                          </span>
                                          </template>
                                          <template v-slot:item.status_id="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="changeStatus(item.user_id,item.status)"
                                              x-small
                                              :color="item.status==1 ? 'red':'green'"
                                            >
                                            {{item.status==1 ? 'Deactivate':'Activate'}}
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.document="{ item }">
                                            {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                                          </template>
                                          <template v-slot:item.process1="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="dialogDoc = true, selected_id = item.user_id"
                                              x-small
                                              v-if="item.process_1 == 0"
                                              :color="'green'"
                                            >
                                            Start
                                            </v-btn>
                                            <v-btn
                                              class="mx-2"
                                              disabled

                                              @click="dialogDoc = true"
                                              x-small
                                              v-if="item.process_1 == 1"
                                              :color="'green'"
                                            >
                                            Finished
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.process_1_key="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              
                                              @click="dialogDoc = true, selected_id = item.user_id"
                                              x-small
                                              disabled
                                              v-if="item.process_1 == 0"
                                              :color="'green'"
                                            >
                                            View
                                            </v-btn>
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="getProcessStatus(item.process_1_key)"
                                              x-small
                                              v-if="item.process_1 == 1"
                                              :color="'blue'"
                                            >
                                            View
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.referral_agent="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              text
                                              @click="dialogAssignedUser = true, getAssigedUser(item.id)"
                                              x-small
                                              :color=" 'orange'"
                                            >
                                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                                              visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.ghl_chat_flag="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              text
                                              @click="syncGhlChat(item.id, item.ghl_chat_flag)"
                                              x-small
                                              :color="item.ghl_chat_flag==1 ? 'green':'red'"
                                            >
                                            <v-icon v-if="item.ghl_chat_flag==1" size="40" class="material-icons-round opacity-10" dark>
                                                toggle_on
                                              </v-icon>
                                              <v-icon v-else size="40" class="material-icons-round opacity-10" dark>
                                                toggle_off
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.id="{ item }">

                                            <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                              @click="deleteItemInventoryType(item.id)"
                                              x-small
                                              color="red"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                delete
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          </v-data-table>
                                          <div class="text-center pt-2">
                                              <v-pagination
                                              prev-icon="fa fa-angle-left"
                                              next-icon="fa fa-angle-right"
                                              class="pagination"
                                              circle
                                              color="#4CAF50"
                                                  v-model="pageInventoryAbove"
                                                  :length="pageCountInventoryAbove"
                                                  @input="getInventoryAbove"
                                              ></v-pagination>
                                              </div>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                  </v-card>
                          </v-col>

                           <v-col md="12" v-if="checkPermission('bhph')">
                            
                            <v-card class="card-shadow border-radius-xl" id="basic" >
                                  <v-card-text style="">
                                    <v-row>
                                      <v-col
                                        cols="6"
                                        md="6"
                                      >
                                      <h5 class="text-h4 font-weight-bold text-typo"> (BHPH) Buy-Here-Pay-Here({{totalCountInventoryBHPH}})</h5>
                                      </v-col>
                                      <v-col
                                        cols="6"
                                        md="6"
                                      >
                                      <rotate-loader class="mt-3" :loading="loading" color="#1D75F4"></rotate-loader>
                                      <!-- <v-btn  class="mb-10" width="200px" style="float: right;" color="primary" :to="{path:'/add-parking'}" >Add Parking Lot</v-btn> -->
                                      </v-col>
                                      <v-col cols="12"
                                        md="12" >
                                         
                                                      <vue-json-to-csv :json-data="users"
                                                      :labels="{ account_id: { title: 'AccountID' },dcid: { title: 'DCID' },dealer_name: { title: 'Dealer Name' },vin : {title : 'VIN'},odometer : {title : 'Odometer'},address: { title: 'Address' },city: { title: 'City' }, state: { title: 'State' },zip: { title: 'Zip' },  phone: { title: 'Phone' },  stock_number: { title: 'Stock Number' },  make: { title: 'make' }, model: { title: 'model' },year: { title: 'Year' }, trim: { title: 'Trim' },  amount: { title: 'Amount' },exterior_color: { title: 'Exterior Color' }, interior_color: { title: 'Interior Color' }, transmission: { title: 'Transmission' }, }"
                                                      csv-title="inventory"
                                                      >
                                                      <v-btn
                                                      color="blue"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            py-4
                                            px-8
                                            mr-3
                                            ms-auto
                                            mt-sm-auto mt-4
                                                          
                                                        "
                                                      dark
                                                          small
                                                      >
                                                          Export to csv
                                                      </v-btn>
                                                    </vue-json-to-csv>
                                                      <v-btn
                                                      color="red"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            ml-5
                                            py-4
                                            px-8
                                            ms-auto
                                            mr-3
                                            mt-sm-auto mt-4

                                                          
                                                        "
                                                          small
                                                          dark
                                                          @click="deleteItemInventoryType('bhph')"
                                                      >
                                                          mass delete
                                                      </v-btn>
                                                      <v-btn
                                                color="blue"
                                              class="
                                              ont-weight-bolder
                                                btn-default
                                                mr-2
                                                py-4
                                                px-8
                                                ms-auto
                                                mt-sm-auto mt-4
                                                              
                                                            "
                                                              small
                                                              dark
                                                              @click="dialogAssignInventory = true, assignButton = 'bhph'"
                                                          >
                                                              Assign Refferal Agent
                                                          </v-btn>
                                                      
                                                         
                                                     
                                        </v-col>
                                      <v-col cols="12"
                                        md="12" >
                                          <v-spacer></v-spacer>
                                              <v-text-field
                                                v-model="search_inventory_BHPH"
                                                  label="Search"
                                                  single-line
                                                  clearable
                                                  hide-details
                                              >
                                                  <template v-slot:append>
                                                  
                                                  </template>
                                                  <template v-slot:append-outer>
                                                      <v-btn
                                                      color="#4CAF50"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            py-4
                                            px-8
                                            ms-auto
                                            mt-sm-auto mt-4
                                                          
                                                        "
                                                          small
                                                          @click="getInventoryBHPH"
                                                      >
                                                          Submit
                                                      </v-btn>
                                                  </template>
                                              </v-text-field>
                                      </v-col>
                                      <v-col cols="12"
                                        md="12">
                                        
                                          <v-data-table
                                          :headers="headers_inventory"
                                              :options.sync="datatable_options_inventory_BHPH"
                                              :items="inventories_BHPH"
                                              v-model="selectedItemsInventoryBHPH"
                                              show-select
                                              loading-text="Loading... Please wait"
                                              :page.sync="page"
                                              :items-per-page="itemsPerPage"
                                              class="table"
                                              :footer-props="{
                    
                                                      'items-per-page-options': [10, 50, 100,-1]
                                                    }"
                                          >
                                      
                                          <template v-slot:item.status="{ item }">
                                              {{item.status == 1 ? 'Active' :'Inactive'}}
                                          </template>
                                          <template v-slot:item.email="{ item }">
                                              {{item.email ? item.email : 'Not Available'}}
                                          </template>
                                          <template v-slot:item.phone="{ item }">
                                              {{item.phone_m ? item.phone_m : "Not Avilable"}}
                                          </template>
                                          <template v-slot:item.vehicle_cost="{ item }">
                                              {{currencyFormat(item.vehicle_cost)}}
                                          </template>
                                          <template v-slot:item.exported="{ item }">
                                              {{item.exported ? 'Yes' : "No"}}
                                          </template>
                                          <template v-slot:item.created_at="{ item }">
                                              {{dateFormat(item.created_at)}}
                                          </template>
                                          <template v-slot:item.total_cost="{ item }">
                                              {{currencyFormat(item.total_cost)}}
                                          </template>
                                          <template v-slot:item.selling_cost="{ item }">
                                              {{currencyFormat(item.selling_cost)}}
                                          </template>
                                          <template v-slot:item.net_profit="{ item }">
                                              {{currencyFormat(item.net_profit)}}
                                          </template>
                                          <template v-slot:item.password="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                              @click="dialogPassword = true, selectedPasswordId = item.id,selectedPassword = generatePassword()"
                                              x-small
                                              color="blue"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                key
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.account_id="{ item }">
                                              <a style="color:#2196F3" :href="'vehicle-detail/'+item.id" target="_blank">{{ item.account_id }}</a>
                                          </template>

                                          <template v-slot:item.url="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="dialogURL =true, company_url = item.company_url ? item.company_url : ''"
                                              x-small
                                              color="orange"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>

                                          <template v-slot:item.agreement_name="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="viewContract(item.id)"
                                              x-small
                                              color="orange"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>

                                          <template v-slot:item.credit_new="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'add'"
                                              x-small
                                              color="green"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                add_card
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.credit_remove="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'remove'"
                                              x-small
                                              color="red"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                credit_card_off
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.address="{ item }">
                                          <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                                          </span>
                                          </template>
                                          <template v-slot:item.status_id="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="changeStatus(item.user_id,item.status)"
                                              x-small
                                              :color="item.status==1 ? 'red':'green'"
                                            >
                                            {{item.status==1 ? 'Deactivate':'Activate'}}
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.document="{ item }">
                                            {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                                          </template>
                                          <template v-slot:item.process1="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="dialogDoc = true, selected_id = item.user_id"
                                              x-small
                                              v-if="item.process_1 == 0"
                                              :color="'green'"
                                            >
                                            Start
                                            </v-btn>
                                            <v-btn
                                              class="mx-2"
                                              disabled

                                              @click="dialogDoc = true"
                                              x-small
                                              v-if="item.process_1 == 1"
                                              :color="'green'"
                                            >
                                            Finished
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.process_1_key="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              
                                              @click="dialogDoc = true, selected_id = item.user_id"
                                              x-small
                                              disabled
                                              v-if="item.process_1 == 0"
                                              :color="'green'"
                                            >
                                            View
                                            </v-btn>
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="getProcessStatus(item.process_1_key)"
                                              x-small
                                              v-if="item.process_1 == 1"
                                              :color="'blue'"
                                            >
                                            View
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.referral_agent="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              text
                                              @click="dialogAssignedUser = true, getAssigedUser(item.id)"
                                              x-small
                                              :color=" 'orange'"
                                            >
                                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                                              visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.ghl_chat_flag="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              text
                                              @click="syncGhlChat(item.id, item.ghl_chat_flag)"
                                              x-small
                                              :color="item.ghl_chat_flag==1 ? 'green':'red'"
                                            >
                                            <v-icon v-if="item.ghl_chat_flag==1" size="40" class="material-icons-round opacity-10" dark>
                                                toggle_on
                                              </v-icon>
                                              <v-icon v-else size="40" class="material-icons-round opacity-10" dark>
                                                toggle_off
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.id="{ item }">

                                            <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                              @click="deleteItemInventoryType(item.id)"
                                              x-small
                                              color="red"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                delete
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          </v-data-table>
                                          <div class="text-center pt-2">
                                              <v-pagination
                                              prev-icon="fa fa-angle-left"
                                              next-icon="fa fa-angle-right"
                                              class="pagination"
                                              circle
                                              color="#4CAF50"
                                                  v-model="pageInventoryBHPH"
                                                  :length="pageCountInventoryBHPH"
                                                  @input="getInventoryBHPH"
                                              ></v-pagination>
                                              </div>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                  </v-card>
                          </v-col>
                          </v-row>
                          </v-tab-item>

                          <v-tab-item
                          :key="'dealers'"
                          style="height: 100%!important;"
                          :value="'tab-dealers'"
                        >
                        <v-row class="mb-12 mt-3" >
                          <v-col md="12">
                            
                            <v-card class="card-shadow border-radius-xl" id="basic" >
                                  <v-card-text style="">
                                    <v-row>
                                      <v-col
                                        cols="6"
                                        md="6"
                                      >
                                      <h5 class="text-h4 font-weight-bold text-typo">Dealers ({{totalCountDealers}})</h5>
                                      </v-col>
                                      <v-col
                                        cols="6"
                                        md="6"
                                      >
                                      <rotate-loader class="mt-3" :loading="loading" color="#1D75F4"></rotate-loader>
                                      <!-- <v-btn  class="mb-10" width="200px" style="float: right;" color="primary" :to="{path:'/add-parking'}" >Add Parking Lot</v-btn> -->
                                      </v-col>
                                      <v-col cols="12"
                                        md="12" >
                                          <v-btn
                                                      color="#4CAF50"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            mr-3
                                            py-4
                                            px-8
                                            ms-auto
                                            mt-sm-auto mt-4
                                                          
                                                        "
                                                          small
                                                          @click="dialogImportDealers = true"
                                                      >
                                                          Import From csv
                                                      </v-btn>
                                                       <v-btn
                                                      color="#4CAF50"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            mr-3
                                            py-4
                                            px-8
                                            ms-auto
                                            mt-sm-auto mt-4
                                                          
                                                        "
                                                          small
                                                          @click="dialogNewDealers = true"
                                                      >
                                                          Add New
                                                      </v-btn>
  
                                                      <vue-json-to-csv :json-data="users"
                                                      :labels="{ account_id: { title: 'AccountID' },dcid: { title: 'DCID' },dealer_name: { title: 'Dealer Name' },vin : {title : 'VIN'},odometer : {title : 'Odometer'},address: { title: 'Address' },city: { title: 'City' }, state: { title: 'State' },zip: { title: 'Zip' },  phone: { title: 'Phone' },  stock_number: { title: 'Stock Number' },  make: { title: 'make' }, model: { title: 'model' },year: { title: 'Year' }, trim: { title: 'Trim' },  amount: { title: 'Amount' },exterior_color: { title: 'Exterior Color' }, interior_color: { title: 'Interior Color' }, transmission: { title: 'Transmission' }, }"
                                                      csv-title="dealers"
                                                      >
                                                      <v-btn
                                                      color="blue"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            py-4
                                            px-8
                                            mr-3
                                            ms-auto
                                            mt-sm-auto mt-4
                                                          
                                                        "
                                                      dark
                                                          small
                                                      >
                                                          Export to csv
                                                      </v-btn>
                                                    </vue-json-to-csv>
                                                      <v-btn
                                                      color="red"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            ml-5
                                            py-4
                                            px-8
                                            ms-auto
                                            mr-3
                                            mt-sm-auto mt-4

                                                          
                                                        "
                                                          small
                                                          dark
                                                          @click="deleteItemDealers('all')"
                                                      >
                                                          mass delete
                                                      </v-btn>
                                                      
                                                      <v-btn
                                                      color="blue"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            ml-5
                                            py-4
                                            px-8
                                            ms-auto
                                            mr-3
                                            mt-sm-auto mt-4

                                                          
                                                        "
                                                          small
                                                          dark
                                                          @click="dialogChangeStatusDealers = true"
                                                      >
                                                          Change Status
                                                      </v-btn>
                                                          
                                                     
                                        </v-col>
                                      <v-col cols="12"
                                        md="12" >
                                          <v-spacer></v-spacer>
                                              <v-text-field
                                                v-model="search_dealers"
                                                  label="Search"
                                                  single-line
                                                  clearable
                                                  hide-details
                                              >
                                                  <template v-slot:append>
                                                  
                                                  </template>
                                                  <template v-slot:append-outer>
                                                      <v-btn
                                                      color="#4CAF50"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            py-4
                                            px-8
                                            ms-auto
                                            mt-sm-auto mt-4
                                                          
                                                        "
                                                          small
                                                          @click="getDealers"
                                                      >
                                                          Submit
                                                      </v-btn>
                                                  </template>
                                              </v-text-field>
                                      </v-col>
                                      <v-col cols="12"
                                        md="12">
                                        
                                          <v-data-table
                                          :headers="headers_dealers"
                                              :options.sync="datatable_options_dealers"
                                              :items="dealers"
                                              v-model="selectedItemsDealers"
                                              show-select
                                              loading-text="Loading... Please wait"
                                              :page.sync="page"
                                              :items-per-page="itemsPerPage"
                                              class="table"
                                              :footer-props="{
                    
                                                      'items-per-page-options': [10, 50, 100,-1]
                                                    }"
                                          >
                                      
                                          <template v-slot:item.status="{ item }">
                                              <span :class="item.status == 1 ? 'text-success' : 'text-danger'">{{item.status == 1 ? 'Active' :'Inactive'}} </span>
                                          </template>
                                          <template v-slot:item.edit="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="dealer = item;dialogNewDealers = true;"
                                              x-small
                                              color="blue"
                                            >
                                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                edit
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.email="{ item }">
                                              {{item.email ? item.email : 'Not Available'}}
                                          </template>
                                          <template v-slot:item.phone="{ item }">
                                              {{item.phone ? phoneFormat(item.phone) : "Not Avilable"}}
                                          </template>
                                          <template v-slot:item.vehicle_cost="{ item }">
                                              {{currencyFormat(item.vehicle_cost)}}
                                          </template>
                                          <template v-slot:item.total_cost="{ item }">
                                              {{currencyFormat(item.total_cost)}}
                                          </template>
                                          <template v-slot:item.selling_cost="{ item }">
                                              {{currencyFormat(item.selling_cost)}}
                                          </template>
                                          <template v-slot:item.net_profit="{ item }">
                                              {{currencyFormat(item.net_profit)}}
                                          </template>
                                          <template v-slot:item.password="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                              @click="dialogPassword = true, selectedPasswordId = item.id,selectedPassword = generatePassword()"
                                              x-small
                                              color="blue"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                key
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.account_id="{ item }">
                                              <a style="color:#2196F3" :href="'vehicle-detail/'+item.id" target="_blank">{{ item.account_id }}</a>
                                          </template>

                                          <template v-slot:item.url="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="dialogURL =true, company_url = item.company_url ? item.company_url : ''"
                                              x-small
                                              color="orange"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>

                                          <template v-slot:item.agreement_name="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="viewContract(item.id)"
                                              x-small
                                              color="orange"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>

                                          <template v-slot:item.credit_new="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'add'"
                                              x-small
                                              color="green"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                add_card
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.credit_remove="{ item }">
                                              <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                            @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'remove'"
                                              x-small
                                              color="red"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                credit_card_off
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <!-- <template v-slot:item.address="{ item }">
                                          <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                                          </span>
                                          </template> -->
                                          <template v-slot:item.status_id="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="changeStatus(item.user_id,item.status)"
                                              x-small
                                              :color="item.status==1 ? 'red':'green'"
                                            >
                                            {{item.status==1 ? 'Deactivate':'Activate'}}
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.document="{ item }">
                                            {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                                          </template>
                                          <template v-slot:item.process1="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="dialogDoc = true, selected_id = item.user_id"
                                              x-small
                                              v-if="item.process_1 == 0"
                                              :color="'green'"
                                            >
                                            Start
                                            </v-btn>
                                            <v-btn
                                              class="mx-2"
                                              disabled

                                              @click="dialogDoc = true"
                                              x-small
                                              v-if="item.process_1 == 1"
                                              :color="'green'"
                                            >
                                            Finished
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.process_1_key="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              
                                              @click="dialogDoc = true, selected_id = item.user_id"
                                              x-small
                                              disabled
                                              v-if="item.process_1 == 0"
                                              :color="'green'"
                                            >
                                            View
                                            </v-btn>
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              @click="getProcessStatus(item.process_1_key)"
                                              x-small
                                              v-if="item.process_1 == 1"
                                              :color="'blue'"
                                            >
                                            View
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.referral_agent="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              text
                                              @click="dialogAssignedUser = true, getAssigedUser(item.id)"
                                              x-small
                                              :color=" 'orange'"
                                            >
                                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                                              visibility
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.ghl_chat_flag="{ item }">
                                            <v-btn
                                              class="mx-2"
                                              dark
                                              text
                                              @click="syncGhlChat(item.id, item.ghl_chat_flag)"
                                              x-small
                                              :color="item.ghl_chat_flag==1 ? 'green':'red'"
                                            >
                                            <v-icon v-if="item.ghl_chat_flag==1" size="40" class="material-icons-round opacity-10" dark>
                                                toggle_on
                                              </v-icon>
                                              <v-icon v-else size="40" class="material-icons-round opacity-10" dark>
                                                toggle_off
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          <template v-slot:item.id="{ item }">

                                            <v-btn
                                              class="mx-2"
                                              text
                                              dark
                                              @click="deleteItemDealers(item.id)"
                                              x-small
                                              color="red"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                delete
                                              </v-icon>
                                            </v-btn>
                                          </template>
                                          </v-data-table>
                                          
                                      </v-col>
                                      <v-col cols="12" md="12">
                                      <div class="text-center">
                                              <v-pagination
                                              prev-icon="fa fa-angle-left"
                                              next-icon="fa fa-angle-right"
                                              class="pagination"
                                              circle
                                              color="#4CAF50"
                                                  v-model="pageDealers"
                                                  :length="pageCountDealers"
                                                  @input="getDealers"
                                              ></v-pagination>
                                              </div>
                                      
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                  </v-card>
                          </v-col>
                          </v-row>
                          </v-tab-item>
                      </v-tabs-items>

                      

</v-container>
<vue-footer-admin></vue-footer-admin>
</v-main>
<v-dialog
        persistent
      v-model="dialogAssign"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Assign to Refferal Agent
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
           <rotate-loader class="mt-3 mb-3" :loading="loadingAssign" color="#1D75F4"></rotate-loader>
                 <v-select
                            item-text="name"
                            placeholder="Select User"
                            item-value="user_id"
                            :disabled="loadingAssign"
                            v-model="selectedCompany"
                            :items="users_options"
                            ><template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field v-model="searchTerm" placeholder="Search" @input="searchFruits"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      </v-select>
              
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="assignAgent()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogAssign = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        persistent
      v-model="openDialogEdit"
      width="100%"
      class="dialog-vehicle-detail"
      style="background-color: #E0E0E0!important;"
    >

      <v-card style="background-color: #E0E0E0!important;">
        <v-card-title class="text-h5 grey lighten-2">
          Vehicle Details
          
        </v-card-title>

        <v-card-text style="padding:0px;margin-top:10px;"> 
          
          <vehicleDetail :key="selectedEditId" :id="selectedEditId" @closeDetailmodal="closeDetailmodal" />
              
        </v-card-text>

       
      </v-card>
    </v-dialog>

    <v-dialog
        persistent
      v-model="dialogAssignShare"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Assign Shares to Agent
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
           <rotate-loader class="mt-3 mb-3" :loading="loadingAssign" color="#1D75F4"></rotate-loader>
                 <v-select
                            item-text="name"
                            placeholder="Select User"
                            item-value="user_id"
                            :disabled="loadingAssign"
                            v-model="selectedUser"
                            :items="users_options"
                            ><template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field v-model="searchTerm" placeholder="Search" @input="searchFruits"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      </v-select>
                      <v-text-field
                      v-model="seletedShare"
                      label="Shares"
                      type="number"
                      ></v-text-field>
                      <p style="color:red">Note: Maximum shares can be assigned is {{share_settings.total_share - share_sold}}</p>
              
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="updateShareUser()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogAssignShare = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

<v-dialog
        persistent
      v-model="dialogChangeStatusDealers"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Change Status
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
           <rotate-loader class="mt-3 mb-3" :loading="loadingAssign" color="#1D75F4"></rotate-loader>
                 <v-select
                            item-text="name"
                            placeholder="Select Status"
                            item-value="id"
                            :disabled="loadingAssign"
                            v-model="selectedDealerStatus"
                            :items="[{id : 1, name: 'Active'},{id : 0, name: 'Inactive'}]"
                            ></v-select>
              
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="changeStatusDealer()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogChangeStatusDealers = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        persistent
      v-model="openDialogPacks"
      width="800"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Packs
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
              <Packs :settings="company_settings" :data="selected_packs" :key="selected_packs.id" @notify="getInventory" :flag="packsFlag" :selectedItemsInventory="selectedItemsInventory" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="openDialogPacks = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog
        persistent
      v-model="dialogAssignInventory"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Assign to Refferal Agent
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
           <rotate-loader class="mt-3 mb-3" :loading="loadingAssign" color="#1D75F4"></rotate-loader>
                 <v-select
                            item-text="name"
                            placeholder="Select User"
                            item-value="user_id"
                            :disabled="loadingAssign"
                            v-model="selectedUser"
                            multiple
                            :items="users_options"
                            >
                            <template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field v-model="searchTerm" placeholder="Search" @input="searchFruits"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                        <v-list-item
                            ripple
                            @mousedown.prevent
                            @click="toggleAll"
                          >
                            <v-list-item-action>
                              <v-icon class="material-icons-round" style="font-size: 18px;margin-left: 3px;">
                                {{ selectedUser.length == users_options.length ? 'check_box': 'check_box_outline_blank' }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                Select All
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                      </template>
                      <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                              <span>{{ item.name }}</span>
                            </v-chip>
                            <span
                              v-if="index === 1"
                              class="grey--text text-caption"
                            >
                              (+{{ selectedUser.length - 1 }} others)
                            </span>
                          </template>
                      </v-select>
              
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="assignAgentINventory()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogAssignInventory = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        persistent
      v-model="dialogAssignImport"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Import/Export
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
           <rotate-loader class="mt-3 mb-3" :loading="loadingAssign" color="#1D75F4"></rotate-loader>
           <div class="mt-4">
                 
                  <v-btn block
                                                      color="#4CAF50"
                                          class="
                                          ont-weight-bolder
                                            btn-default
                                            mr-2
                                            py-4
                                            px-8
                                            ms-auto
                                            mt-sm-auto mt-4
                                                          
                                                        "
                                                          small
                                                          @click="dialogImportInventory = true"
                                                      >
                                                          Import From csv
                                                      </v-btn>
                                                      </div>
                                                      <div class="mt-4">
                                                      <v-btn
                                                      block
                                                color="teal"
                                              class="
                                              ont-weight-bolder
                                                btn-default
                                                mr-2
                                                py-4
                                                px-8
                                                ms-auto
                                                mt-sm-auto mt-4
                                                              
                                                            "
                                                              small
                                                              dark
                                                              @click="dialogImportInventory2 = true"
                                                          >
                                                              Import from URl
                                                          </v-btn>
                                                          </div>
                                                          <div class="mt-4">
                                                   
                                                      <v-btn
                                                      block
                                                      :loading="loading"
                                                      @click="exportToCSV('inventory')"
                                                      color="blue"
                                          class="
                                          mt-2
                                          ont-weight-bolder
                                            btn-default
                                            py-4
                                            px-8
                                            mr-2
                                            ms-auto
                                            mt-sm-auto mt-4
                                                          
                                                        "
                                                      dark
                                                          small
                                                      >
                                                          Export to csv
                                                      </v-btn>
                                                    </div>
              
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogAssignImport = false, getInventory()"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  <v-dialog
        persistent
      v-model="dialogUpdatePrice"
      width="100%"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Update Price
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
           <rotate-loader class="mt-3 mb-3" :loading="loadingAssign" color="#1D75F4"></rotate-loader>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Name
                    </th>
                    <th class="text-left">
                      VIN
                    </th>
                     <th class="text-left">
                      Odometer
                    </th>
                     <th class="text-left">
                      Asking Price
                    </th>
                      <th class="text-left">
                      Vehicle Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in selectedItemsInventory"
                    :key="item.name"
                  >
                    <td>
                    {{ item.year }} {{item.make}} {{item.model}} 
                    </td>
                    <td>{{ item.vin }}</td>
                    <td>{{ item.odometer }}</td>
                    <td>
                    <v-text-field
                    prefix="$"
                                v-model="item.amount"
                                />
                    </td>
                    <td>
                    <v-text-field
                    prefix="$"
                                v-model="item.vehicle_cost"
                                />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
                 
              
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="saveMassSaveVehicle('price')"
          >
            Save
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogUpdatePrice = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        persistent
      v-model="dialogAssignProgram"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Assign to Program
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
           <rotate-loader class="mt-3 mb-3" :loading="loadingAssign" color="#1D75F4"></rotate-loader>
                 <v-select
                            item-text="name"
                            placeholder="Select Program"
                            item-value="id"
                            :disabled="loadingAssign"
                            v-model="selectedProgram"
                            multiple
                            :items="program_options"
                            >
                            <template v-slot:prepend-item>
                        <!-- <v-list-item>
                          <v-list-item-content>
                            <v-text-field v-model="searchTerm" placeholder="Search" @input="searchFruits"></v-text-field>
                          </v-list-item-content>
                        </v-list-item> -->
                        <!-- <v-divider class="mt-2"></v-divider>
                        <v-list-item
                            ripple
                            @mousedown.prevent
                            @click="toggleAll"
                          >
                            <v-list-item-action>
                              <v-icon class="material-icons-round" style="font-size: 18px;margin-left: 3px;">
                                {{ selectedUser.length == users_options.length ? 'check_box': 'check_box_outline_blank' }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                Select All
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item> -->
                      </template>
                      <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                              <span>{{ item.name }}</span>
                            </v-chip>
                            <span
                              v-if="index === 1"
                              class="grey--text text-caption"
                            >
                              (+{{ selectedUser.length - 1 }} others)
                            </span>
                          </template>
                      </v-select>
              
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="setVehicleType()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogAssignProgram = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

     <v-dialog
        persistent
      v-model="dialogAssignBodyStyle"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Change Body Style
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
           <rotate-loader class="mt-3 mb-3" :loading="loadingAssign" color="#1D75F4"></rotate-loader>
                 <v-select
                                color="#e91e63"
                                :items="['Coupe','Convertible','Sedan','Hybrid/Electric','Crossover/SUV','Truck','4WD/AWD','Van','Wagon']"
                                label="Body Style"
                                v-model="selected_body_style"
                                class="font-size-input input-style"
                            ></v-select>
              
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="setBodyStyle('body_style')"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogAssignBodyStyle = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        persistent
      v-model="dialogAssignState"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Change State
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
           <rotate-loader class="mt-3 mb-3" :loading="loadingAssign" color="#1D75F4"></rotate-loader>
                 <v-select
                                color="#e91e63"
                                :items="stateOptions"
                                label="State"
                                item-text="name"
                                  item-value="abbreviation"
                                v-model="selected_state"
                                class="font-size-input input-style"
                            ></v-select>
              
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="setBodyStyle('state')"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogAssignState = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
              persistent
            v-model="dialogAddNewInventory"
            width="700"
          >
          <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                    <v-card-text style="margin-bottom: -30px;">
                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                        >
                        <h5 class="text-h4 font-weight-bold text-typo">Add New Vehicle </h5>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                      </v-col>
                        <v-row style="padding:10px">
                        <v-col
                          cols="12"
                        >
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                                color="#e91e63"
                                label="Account ID"
                                v-model="company.account_id"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                                color="#e91e63"
                                label="DCID"
                                v-model="company.dcid"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Dealer Name"
                                v-model="company.dealer_name"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;" >
                        <v-text-field
                                color="#e91e63"
                                label="Address"
                                v-model="company.address"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="City"
                                v-model="company.city"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                            <v-select
                                color="#e91e63"
                                 item-text="name"
                                  item-value="abbreviation"
                                :items="stateOptions"
                                label="State"
                                v-model="company.state"
                                class="font-size-input input-style"
                            ></v-select>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Zip"
                                v-model="company.zip"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Phone"
                                v-model="company.phone"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Stock Number"
                                v-model="company.stock_number"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="VIN"
                                v-model="company.vin"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Make"
                                v-model="company.make"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Model"
                                v-model="company.model"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Year"
                                v-model="company.year"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Trim"
                                v-model="company.trim"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Odometer"
                                v-model="company.odometer"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Asking Price"
                                prefix="$"
                                v-model="company.amount"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Vehicle Cost"
                                prefix="$"
                                v-model="company.vehicle_cost"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Exterior Color"
                                v-model="company.exterior_color"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Interior Color"
                                v-model="company.interior_color"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Trasmission"
                                v-model="company.transmission"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                       <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-select
                                color="#e91e63"
                                :items="['Coupe','Convertible','Sedan','Hybrid/Electric','Crossover/SUV','Truck','4WD/AWD','Van','Wagon']"
                                label="Body Style"
                                v-model="company.body_style"
                                class="font-size-input input-style"
                            ></v-select>
                      </v-col>
                    </v-row>
                              </v-col>
                              <v-col
                                cols="12"
                                md="12"
                                class="mt-3"
                                >
    
                            <v-card-actions >
                              <v-row>
                                <v-col>
                                  <v-btn
                                    color="grey"
                                    large
                                    @click="dialogAddNewInventory = false"
                                    class="
                                      font-weight-bolder
                                      btn-default
                                      mb-5
                                      px-8
                                      mr-3
                                      ms-auto
                                    "
                                    
                                  >
                                   Close
                                  </v-btn>
                                  <v-btn
                                    color="#4CAF50"
                                    large
                                    :loading="loading"
                                    @click="AddNewVehicle()"
                                    class="
                                      font-weight-bolder
                                      btn-default
                                      mb-5
                                      mr-2
                                      px-8
                                      ms-auto
                                    "
                                    
                                  >
                                    Save
                                  </v-btn>
                               
                                  </v-col>
                                  </v-row>
                                  </v-card-actions>
                                  </v-col>
                              </v-row>                        
    
                        
                      </v-row>
                    </v-card-text>
                    <!-- <v-card-actions class="justify-center ">
                      <v-btn
                        tile  dark
                        style="height: 49px;width:95px;"
                        color="primary"
                        class="mb-10"
                        @click="saveProfile"
                      >Save</v-btn>
                    </v-card-actions> -->
                  </v-card>
          </v-dialog>

          <v-dialog
              persistent
            v-model="dialogNewDealers"
            width="700"
          >
          <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                    <v-card-text style="margin-bottom: -30px;">
                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                        >
                        <h5 class="text-h4 font-weight-bold text-typo">{{ dealer.id ? 'Update Dealer' : 'Add New Dealer' }}</h5>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                      </v-col>
                        <v-row style="padding:10px">
                        <v-col
                          cols="12"
                        >
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                                color="#e91e63"
                                label="Business Name"
                                v-model="dealer.name"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                                color="#e91e63"
                                label="State/Province"
                                v-model="dealer.state"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="City"
                                v-model="dealer.city"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;" >
                        <v-text-field
                                color="#e91e63"
                                label="Phone"
                                v-mask="'(###) ###-####'"
                                v-model="dealer.phone"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                     
                    </v-row>
                              </v-col>
                              <v-col
                                cols="12"
                                md="12"
                                class="mt-3"
                                >
    
                            <v-card-actions >
                              <v-row>
                                <v-col>
                                  <v-btn
                                    color="grey"
                                    large
                                    @click="dialogNewDealers = false"
                                    class="
                                      font-weight-bolder
                                      btn-default
                                      mb-5
                                      px-8
                                      mr-3
                                      ms-auto
                                    "
                                    
                                  >
                                   Close
                                  </v-btn>
                                  <v-btn
                                    color="#4CAF50"
                                    large
                                    :loading="loading"
                                    @click="AddNewDealer()"
                                    class="
                                      font-weight-bolder
                                      btn-default
                                      mb-5
                                      mr-2
                                      px-8
                                      ms-auto
                                    "
                                    
                                  >
                                    Save
                                  </v-btn>
                               
                                  </v-col>
                                  </v-row>
                                  </v-card-actions>
                                  </v-col>
                              </v-row>                        
    
                        
                      </v-row>
                    </v-card-text>

                  </v-card>
          </v-dialog>
<v-dialog
        persistent
      v-model="dialogImport"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Import from CSV
        </v-card-title>

        <v-card-text>
           <rotate-loader class="mt-3" :loading="loadingUpload" color="#1D75F4"></rotate-loader>
           <h3 v-if="loadingUpload"> Importing...</h3>
          <v-file-input
              show-size
              class="mt-4"
              
              :disabled="loadingUpload"
              v-model="uploadedDocumentCSV"
              accept="application/csv"
              prepend-icon="fa-paperclip"
              label="Upload CSV"
          ></v-file-input>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingUpload"
            @click="uploadFiles()"
          >
            Upload
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingUpload"
            @click="dialogImport = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        persistent
      v-model="dialogImportDealers"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Import from CSV
        </v-card-title>

        <v-card-text>
           <rotate-loader class="mt-3" :loading="loadingUpload" color="#1D75F4"></rotate-loader>
           <h3 v-if="loadingUpload"> Importing...</h3>
          <v-file-input
              show-size
              class="mt-4"
              
              :disabled="loadingUpload"
              v-model="uploadedDocumentCSV"
              accept="application/csv"
              prepend-icon="fa-paperclip"
              label="Upload CSV"
          ></v-file-input>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingUpload"
            @click="uploadFilesDealers()"
          >
            Upload
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingUpload"
            @click="dialogImportDealers = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        persistent
      v-model="dialogAssignedUser"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Assigned Users
        </v-card-title>

        <v-card-text class="mt-5">
           <rotate-loader class="mt-3" :loading="loadingUpload" color="#1D75F4"></rotate-loader>
           <h3 v-if="loadingUpload"> Importing...</h3>
          <p v-for="user in assigned_user" :key="user" v-if="assigned_user.length > 0">
            {{user.first_name}} {{user.last_name}} ({{user.email}})
          </p>
          <p v-if="assigned_user.length == 0"> No Users Assigned</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn
            color="primary"
            text
            :disabled="loadingUpload"
            @click="dialogAssignedUser = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        persistent
      v-model="dialogImportInventory"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Import from CSV
        </v-card-title>

        <v-card-text>
           <rotate-loader class="mt-3" :loading="loadingUpload" color="#1D75F4"></rotate-loader>
           <h3 v-if="loadingUpload"> Importing...</h3>
          <v-file-input
              show-size
              class="mt-4"
              
              :disabled="loadingUpload"
              v-model="uploadedDocumentCSVInventory"
              accept="application/csv"
              prepend-icon="fa-paperclip"
              label="Upload CSV"
          ></v-file-input>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingUpload"
            @click="uploadFilesInventory()"
          >
            Upload
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingUpload"
            @click="dialogImportInventory = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        persistent
      v-model="dialogImportInventory2"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Import from URL
        </v-card-title>

        <v-card-text>
           <rotate-loader class="mt-3" :loading="loadingUpload" color="#1D75F4"></rotate-loader>
           <h3 v-if="loadingUpload"> Importing...</h3>
          
           <v-select
                            item-text="name"
                            label="Select Dealer"
                            item-value="id"
                            :disabled="loadingAssign"
                            v-model="selectedUrlOption"
                            :items="urlOption"
                            ></v-select>
                            <v-text-field
                                                v-model="selectedUrl"
                                                  label="URl"
                                                  single-line
                                                  clearable
                                                  hide-details
                                              ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            text
            :disabled="loadingUpload"
            @click="fetchInventoryOutside()"
          >
            Upload
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingUpload"
            @click="dialogImportInventory2 = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
              persistent
            v-model="dialogInventoryFinal"
            width="100%"
          >
      
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Images from URL ({{importInvetories.length}})
              </v-card-title>
      
              <v-card-text style="margin-top:30px;"> 
                
                <v-simple-table >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th class="text-left">
                          Make
                        </th>
                        <th class="text-left">
                          Model
                        </th>
                        <th class="text-left">
                          Year
                        </th>
                        <th class="text-left">
                          Mileage
                        </th>
                        <th class="text-left">
                          Transmission
                        </th>
                        <th class="text-left">
                          Location
                        </th>
                        <th class="text-left">
                          Engine
                        </th>
                        <th class="text-left">
                          VIN
                        </th>
                        <th class="text-left">
                          Stock Number
                        </th>
                        <th class="text-left">
                          Drivetrain
                        </th>
                        <th class="text-left">
                          Exterior Color
                        </th>
                        <th class="text-left">
                          Interior Color
                        </th>
                        <th class="text-left">
                          Price
                        </th>
                        <th class="text-left">
                          Delete
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item,n) in importInvetories"
                        :key="item.name"
                      >
                        <td><v-avatar
                                                    class="ml-2 mr-2"
                                                    tile
                                                  >
                                                  <img
                                                      :src="item.image ? item.image :'https://imagesdl.dealercenter.net/640/480/202404-a3aa0c744e274c5e9851cec8f7e6e4ba.jpg'"
                                                      alt="John"
                                                    >
                                                  </v-avatar></td>
                        <td>{{ item.make }}</td>
                        <td>{{ item.model }}</td>
                        <td>{{ item.year }}</td>
                        <td>{{ item.odometer }}</td>
                        <td>{{ item.transmission }}</td>
                        <td>{{ item.address }}</td>
                        <td>{{ item.engine }}</td>
                        <td>{{ item.vin }}</td>
                        <td>{{ item.stock_number }}</td>
                        <td>{{ item.drivetrain }}</td>
                        <td>{{ item.exterior_color }}</td>
                        <td>{{ item.interior_color }}</td>
                        <td>{{ item.amount }}</td>
                        <td><v-btn
                                              class="mx-2"
                                              text
                                              dark
                                              @click="importInvetories.splice(n, 1)"
                                              x-small
                                              color="red"
                                            >
                                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                                delete
                                              </v-icon>
                                            </v-btn></td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                
    
              </v-card-text>
              
              <v-divider></v-divider>
      
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue"
                  text
                  :loading="loadingUpload"
                  @click="saveURLInventory()"
                >
                  submit
                </v-btn>
                 <v-btn
                  color="primary"
                  text
                  @click="dialogInventoryFinal = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
</v-app>
</template>

<script>

function watchClass(targetNode, classToWatch) {
  let lastClassState = targetNode.classList.contains(classToWatch);
  const observer = new MutationObserver((mutationsList) => {
    for (let i = 0; i < mutationsList.length; i++) {
      const mutation = mutationsList[i];
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        const currentClassState = mutation.target.classList.contains(classToWatch);
        if (lastClassState !== currentClassState) {
          lastClassState = currentClassState;
          if (!currentClassState) {
            mutation.target.classList.add('sortHandle');
          }
        }
      }
    }
  });
  observer.observe(targetNode, { attributes: true });
}

import { VueTelInput } from 'vue-tel-input'
import {Circle8} from 'vue-loading-spinner'
import moment from 'moment';
import ClipLoader from 'vue-spinner/src/PulseLoader.vue';
import SalesCountry from "@/views/Dashboard/Widgets/SalesCountry.vue";
import CardWebsiteViews from "@/views/Dashboard/Widgets/CardWebsiteViews.vue";
import CardDailySales from "@/views/Dashboard/Widgets/CardDailySales.vue";
import CardCompletedTasks from "@/views/Dashboard/Widgets/CardCompletedTasks.vue";
import vehicleDetail from "@/components/Admin/VehicleDetail.vue";
import VueJsonToCsv from 'vue-json-to-csv'
import Footer from '../Footer.vue';
import Sortable from 'sortablejs';
import Packs from '@/components/Widgets/Packs.vue';
export default {
    components : {
      'RotateLoader': ClipLoader,
      SalesCountry,
      CardWebsiteViews,
      CardDailySales,
      CardCompletedTasks,
      VueJsonToCsv,
      vehicleDetail,
      Packs
    },
    
    data () {
      return {
        header : { headers : {
            Authorization:"Bearer " + localStorage.getItem('token')
            },
        },
        imageCards : [],
        dialogImport : false,
        loadingUpload : false,
        dialogAssignProgram: false,
        selectedProgram: '',
        dialogUpdatePrice: false,
        dialogUpdatePacks: false,
        user_permissions : localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions')) : [],
        share_settings : {},
        program_options: [
          {
            id: 'none',
            name: 'None'
          },
         
          {
          id: 'car_of_the_week',
          name: 'Car of the week'
        },
        {
          id: 'clearance',
          name: 'Clearance'
        },
        {
          id: 'default',
          name: 'Default'
        },
        {
          id: 'below_credit_flag',
          name: 'Below 640'
        },
        {
          id: 'above_credit_flag',
          name: 'Above 640'
        },
        {
          id: 'bhph',
          name: 'BHPH'
        }
        ],
        program_filter_options: [
          {
          id: '',
          name: 'All'
        },
          {
          id: 'car_of_the_week',
          name: 'Car of the week'
        },
        {
          id: 'clearance',
          name: 'Clearance'
        },
        {
          id: 'default',
          name: 'Default'
        },
        {
          id: 'below_credit_flag',
          name: 'Below 640'
        },
        {
          id: 'above_credit_flag',
          name: 'Above 640'
        },
        {
          id: 'bhph',
          name: 'BHPH'
        }
        ],
        uploadedDocumentCSV : [],
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 3 || 'Min 3 characters',
          emailMatch: () => (`The email and password you entered don't match`),
        },
        transaction : [],
        loading:true,
        dialog:false,
        focusarea : [],
        dialogAssignImport : false,
        dialogAddNewInventory : false,
        selected_body_style : '',
        selected_packs: '',
        openDialogPacks: false,
        dialogAssignBodyStyle : false,
        dialogAssignState : false,
        company: {
          account_id
          : '',dcid
          : '',dealer_name
          : '',address
          : '',city
          : '',state
          : '',zip
          : '',phone
          : '',stock_number
          : '',vin
          : '',make
          : '',model
          : '',year
          : '',trim
          : '',odometer
          : '',amount
          : '',exterior_color
          : '',interior_color
          : '',transmission
          : '',body_style : ''
        },
        urlOption : [{id: 'autoactionaz', name: 'Auto Action'},{id: 'stonemountaintoyota', name: 'Stone Mountain Toyota'},{id: 'drivenationaz', name: 'Drivenation'},{id: 'roundtables', name: 'Round Tables'}, {id: 'mandmdiamondcars', name: 'M&M Diamond Cars'}, {id: 'bestarizonacars', name: 'Arizona Best Cars'}, {id: 'autonationchevroletgilbert', name: 'AutoNation Chevrolet Gilbert'}],
        stateOptions: [
                { name: 'ALABAMA', abbreviation: 'AL'},
                { name: 'ALASKA', abbreviation: 'AK'},
                { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                { name: 'ARIZONA', abbreviation: 'AZ'},
                { name: 'ARKANSAS', abbreviation: 'AR'},
                { name: 'CALIFORNIA', abbreviation: 'CA'},
                { name: 'COLORADO', abbreviation: 'CO'},
                { name: 'CONNECTICUT', abbreviation: 'CT'},
                { name: 'DELAWARE', abbreviation: 'DE'},
                { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                { name: 'FLORIDA', abbreviation: 'FL'},
                { name: 'GEORGIA', abbreviation: 'GA'},
                { name: 'GUAM', abbreviation: 'GU'},
                { name: 'HAWAII', abbreviation: 'HI'},
                { name: 'IDAHO', abbreviation: 'ID'},
                { name: 'ILLINOIS', abbreviation: 'IL'},
                { name: 'INDIANA', abbreviation: 'IN'},
                { name: 'IOWA', abbreviation: 'IA'},
                { name: 'KANSAS', abbreviation: 'KS'},
                { name: 'KENTUCKY', abbreviation: 'KY'},
                { name: 'LOUISIANA', abbreviation: 'LA'},
                { name: 'MAINE', abbreviation: 'ME'},
                { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                { name: 'MARYLAND', abbreviation: 'MD'},
                { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                { name: 'MICHIGAN', abbreviation: 'MI'},
                { name: 'MINNESOTA', abbreviation: 'MN'},
                { name: 'MISSISSIPPI', abbreviation: 'MS'},
                { name: 'MISSOURI', abbreviation: 'MO'},
                { name: 'MONTANA', abbreviation: 'MT'},
                { name: 'NEBRASKA', abbreviation: 'NE'},
                { name: 'NEVADA', abbreviation: 'NV'},
                { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                { name: 'NEW JERSEY', abbreviation: 'NJ'},
                { name: 'NEW MEXICO', abbreviation: 'NM'},
                { name: 'NEW YORK', abbreviation: 'NY'},
                { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                { name: 'OHIO', abbreviation: 'OH'},
                { name: 'OKLAHOMA', abbreviation: 'OK'},
                { name: 'OREGON', abbreviation: 'OR'},
                { name: 'PALAU', abbreviation: 'PW'},
                { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                { name: 'PUERTO RICO', abbreviation: 'PR'},
                { name: 'RHODE ISLAND', abbreviation: 'RI'},
                { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                { name: 'TENNESSEE', abbreviation: 'TN'},
                { name: 'TEXAS', abbreviation: 'TX'},
                { name: 'UTAH', abbreviation: 'UT'},
                { name: 'VERMONT', abbreviation: 'VT'},
                { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                { name: 'VIRGINIA', abbreviation: 'VA'},
                { name: 'WASHINGTON', abbreviation: 'WA'},
                { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                { name: 'WISCONSIN', abbreviation: 'WI'},
                { name: 'WYOMING', abbreviation: 'WY' }
            ],
        selectedUrlOption : '',
        anIncreasingNumber : 0,
        selectedUrl: '',
        search_inventory : '',
        dialogImportInventory2 : false,
        searchTerm : '',
        
        share_sold : 0,
        users_options_old : [],
        selectedCompany: '',
        dialogAssignedUser : false,
        selectedDealerStatus : 1,
        openDialogEdit: false,
        selectedEditId: '',
        inventoryFilterProgram: '',
        inventoryFilterBodyStyle:'All',
        inventoryFilterMinPrice:'',
        inventoryFilterMaxPrice:'',
        inventoryFilterMaxOdometer:'',
        inventoryFilterMinOdometer:'',
        dialogChangeStatusDealers : false,
        selectedUser : '',
        loadingAssign : false,
        seletedShare: 0,
        selectedHeaders: [],
        headers_inventory_final: [],
        dialogNewDealers : false,
        dealer: {
          name : '',
          state: '',
          city: '',
          phone: '',
        },
        uploadedDocumentCSVInventory : [],
        dialogImportDealers : false,
        dialogImportInventory : false,
        dialogAssign: false,
        importInvetories: [],
        dialogInventoryFinal: false,
        dashboardData : [],
        users_options: [],
        locations : [],
        headers_shares:[ 
          { text: 'Name', value: 'name',sortable: true },
          { text: 'Email', value: 'email',sortable: true },
          { text: 'Phone', value: 'phone_m',sortable: true },
          { text: '%Ownership', value: 'ownership' },
          { text: 'Shares Owned', value: 'shares_owned' },
          { text: 'Total Cash Value', value: 'total_cash' },
          { text: 'Quarterly Payout', value: 'quarterly_payout' },
          { text: 'Reffered By', value: 'reffered_by',sortable: true },


        ],
        headers_dealers:[ { text: 'Business Name', value: 'name',
            sortable: true },
          { text: 'State/Province', value: 'state',
            sortable: false },
            { text: 'City', value: 'city',
            sortable: false },
            { text: 'Phone', value: 'phone',
            sortable: false },
            { text: 'Status', value: 'status',
            sortable: true },
            { text: 'Created At', value: 'created_at',
            sortable: true },{ text: 'Edit', value: 'edit',
            sortable: false }],
        datatable_options_dealers: {
          
        },
        dealers: [],
        selectedItemsDealers: [],
        pageDealers: 1,
        pageCountDealers: 0,  
        search_dealers: '',
        totalCountDealers: 0,
        packsFlag : 'single',
        datatable_options_shares: {},
        shares: [],
        selectedItemsShares: [],
        pageShares: 1,
        pageCountShares: 0,
        itemsPerPage_shares: 10,
        headers_inventory: [ { text: 'AccountID', value: 'account_id',width:'300',
            sortable: false },
          { text: 'DCID', value: 'dcid',
            sortable: false },
            { text: 'Dealer Name', value: 'dealer_name',
            sortable: false },
            { text: 'Address', value: 'address',
            sortable: false },
            { text: 'City', value: 'city',
            sortable: false },
            { text: 'State', value: 'state',
            sortable: false },
            { text: 'Zip', value: 'zip',
            sortable: false },
            { text: 'Phone', value: 'phone',
            sortable: false },
            { text: 'Stock Number', value: 'stock_number',
            sortable: false },
          { text: 'VIN', value: 'vin',
            sortable: true },
            { text: 'Make', value: 'make',
            sortable: true },
            { text: 'Model', value: 'model',
            sortable: true },
            { text: 'Year', value: 'year',
            sortable: true },
             { text: 'Body Style', value: 'body_style',
            sortable: false },
            { text: 'Trim', value: 'trim',
            sortable: true },
            { text: 'Asking Price', value: 'amount',
            sortable: true },
            { text: 'Vehicle Cost', value: 'vehicle_cost',
            sortable: true },
            { text: 'Packs', value: 'packs',
            sortable: true },
            { text: 'Gross Profit', value: 'gross_profit',
            sortable: true },
            { text: 'Total Tax', value: 'total_tax',
            sortable: true },
            { text: 'Net Profit', value: 'net_profit',
            sortable: true },
            { text: 'Odometer', value: 'odometer',
            sortable: false },
            { text: 'Exterior Color', value: 'exterior_color',
            sortable: true },
            { text: 'Interior Color', value: 'interior_color',
            sortable: true },
            { text: 'Trasmission', value: 'transmission',
            sortable: true },
            { text: 'Refferal Agent', value: 'referral_agent',
            sortable: true },
            { text: 'Exported', value: 'exported',
            sortable: true },
            { text: 'Date Added', value: 'created_at',
            sortable: true },],
        datatable_options_inventory: {},
        datatable_options_inventory_cow: {},
        datatable_options_inventory_cl: {},
        inventories: [],

        offsetInventoryCOW: 0,
        search_inventory_cow: '',
        inventories_cow: [],
        pageCountInventoryCOW: 1,
        totalCountInventoryCOW: 0,
        selectedItemsInventoryCOW: [],

        offsetInventoryCL: 0,
        search_inventory_cl: '',
        inventories_cl: [],
        pageCountInventoryCL: 1,
        totalCountInventoryCL: 0,
        selectedItemsInventoryCL: [],

        selectedItemsInventory: [],
        totalCountInventory: 0,
        selectedItems : [],

        totalCountInventorybelow: 0,
        search_inventory_below: '',
        datatable_options_inventory_below: {},
        selectedItemsInventoryBelow: [],
        pageInventoryBelow: 1,
        inventories_below: [],
        pageCountInventoryBelow: 1,
        totalCountInventoryBelow: 0,

        totalCountInventoryabove: 0,
        search_inventory_above: '',
        datatable_options_inventory_above: {},
        selectedItemsInventoryAbove: [],
        pageInventoryAbove: 1,
        inventories_above: [],
        pageCountInventoryAbove: 1,
        totalCountInventoryAbove: 0,

        totalCountInventoryBHPH: 0,
        search_inventory_BHPH: '',
        datatable_options_inventory_BHPH: {},
        selectedItemsInventoryBHPH: [],
        pageInventoryBHPH: 1,
        inventories_BHPH: [],
        pageCountInventoryBHPH: 1,
        totalCountInventoryBHPH: 0,


        selected_focus_area : 'all',
        offsetDealers: 0,
        dialogAssignInventory : false,
        selected_location : 'all',
        focus_area_grant_total : 0,
        focus_area_grant_completed : 0,
        selected_state: '',
        focus_area_value : 0,
        location_value : 0,
        location_grant_total: 0,
        location_grant_completed : 0,
        tab: null,
        initials:'',
        role: JSON.parse(localStorage.getItem('role')),
        valid:true,
        pageCount:1,
        pageInventory: 1,
        company_settings: '',
        pageCountInventory: 1,
        documentList:[],
        totalCount:0,
        settings : JSON.parse(localStorage.getItem('settings')),
        company_id : localStorage.getItem('company_id'),
        page:1,
        links: [],
        itemsPerPage:10,
        selected_job:'',
        dialogAssignShare : false,
        credit : localStorage.getItem('credit') ?   localStorage.getItem('credit') : 0.00,
        dialogDoc:false,
        offset: 0,
        offsetShares: 0,
        offsetInventory: 0,
        datatable_options:{},
        selected_id : '',
        limit: 10,
        selected_project:'',
        checkbox: '',
        assigned_user : [],
        process_status_dialog : false,
        headers_jobs: [
          { text: 'Date Sold', value: 'date_sold',
            sortable: true },
          { text: 'Vehicle Info', value: 'vehicle_info',
            sortable: true },
          { text: 'VIN', value: 'vin',
            sortable: true },
            { text: 'Odometer', value: 'odometer',
            sortable: false },
            { text: 'Vehicle Cost', value: 'vehicle_cost',
            sortable: true },
            { text: 'Total Cost', value: 'total_cost',
            sortable: true },
            { text: 'Selling Cost', value: 'selling_price',
            sortable: true },
            { text: 'Net Profit', value: 'net_profit',
            sortable: true },
            { text: 'Sold Status', value: 'sold_status',
            sortable: true },
            { text: 'Customer Name', value: 'customer_name',
            sortable: true },
            { text: 'Refferal Agent', value: 'referral_agent',
            sortable: true },
            // { text: 'CRC ID', value: 'crc_id',
            // sortable: false },
          // { text: 'Created at', value: 'created_at',
          //   sortable: false,width: '200' },
          // { text: 'Updated at', value: 'updated_at',
          //   sortable: false,width: '200' },
          //   { text: 'Documents', value: 'document',
          //   sortable: false,width: '150' },
          //   { text: 'Change Status', value: 'status_id',
          //   sortable: false,width: '150' },
          //   { text: 'Process 1', value: 'process1',
          //   sortable: false,width: '150' },
          //   { text: 'Process 1 Status', value: 'process_1_key',
          //   sortable: false,width: '150' },
          // { text: 'Delete', value: 'id',
          //   sortable: false,width: '50' },
        ],
        projects: [
          
        ],
        users: []
      }
    },
    created () { 
      if(!localStorage.getItem('token'))
          this.$router.push('/login')
      if(!this.checkPermission('dashboard'))
          this.$router.push('/admin/my-profile')
        this.headers_inventory_final = Object.values(this.headers_inventory);
        this.selectedHeaders = this.headers_inventory;
        if(this.$route.query.tab){
          this.tab = this.$route.query.tab
          setTimeout(() => {
            const inventoryTab = document.getElementById('tab-inventory');
            if (inventoryTab) {
            inventoryTab.click();
          }
          }, 2000);
          
        }
        
        // this.getUsers();
        //this.getFocusArea();
        //this.getLocation();\
        this.getSettings();
        this.getShareSettings();
        this.getDashboardGrant();
        this.getLinks();
    },
    directives: {
    'sortable-table': {
      inserted: (el, binding) => {
        el.querySelectorAll('th').forEach((draggableEl) => {
          // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
          watchClass(draggableEl, 'sortHandle');
          draggableEl.classList.add('sortHandle');
        });
        Sortable.create(el.querySelector('tr'), binding.value ? { ...binding.value, handle: '.sortHandle' } : {});
      },
    },
  },
    methods:{
      sortTheHeadersAndUpdateTheKey(evt) {
        console.log(evt);
        
       const headersTmp = [...this.headers_inventory_final]; // Create a shallow copy for immutability
  const oldIndex = evt.oldIndex -1;
  const newIndex = evt.newIndex -1;

  // Check if the indices are within bounds to prevent unexpected behavior
    // Remove the item at oldIndex and store it
    const [movedHeader] = headersTmp.splice(oldIndex, 1);

    // Insert the item at the new index
    headersTmp.splice(newIndex, 0, movedHeader);
    // Update the table headers to reflect the new order
    this.headers_inventory_final = headersTmp;
    this.anIncreasingNumber += 1; // Update counter to trigger reactivity if necessary

      },
      searchFruits(e) {
      if (!this.searchTerm) {
        this.users_options = this.users_options_old;
      }
      this.users_options = this.users_options_old.filter((fruit) => {
        return fruit.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
      });
    },
      currencyFormat(value){
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(value);
      },
      currencyFormatSummary(value){
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0, 
          minimumFractionDigits: 0, 
        }).format(value);
      },
      ownershipCal(item){        
        return Math.round((item.shares / this.share_settings.total_share) * 100) + '%'
      },
      totalCashCal(item){
        let val = this.share_settings.share_price * Math.pow((1 + (this.share_settings.percentage_increase/100)), (item.shares / this.share_settings.total_shares_before_price_increase));        
         return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2, 
          minimumFractionDigits: 0, 
        }).format(val * item.shares);
      },
      totalCashQCal(item){
        let val = this.share_settings.share_price * Math.pow((1 + (this.share_settings.percentage_increase/100)), (item.shares / this.share_settings.total_shares_before_price_increase));        
         return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2, 
          minimumFractionDigits: 0, 
        }).format((val * item.shares)/4);
      },
      dateFormat(date){
        return moment(date).format("MM/DD/YYYY");
      },
        formatDate(value) {
            return moment(value).format("DD MMM YYYY")
        },
        StartProcess1(flag){
          let id = this.selected_id;
          var r = confirm("Are you sure, You went to start process 1?");
            if (r == true) {
              this.loading = true;
              this.dialogDoc = false;
                    axios
                .get("/api/process1/"+id+"/"+flag,this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Process started successfully', {
                            position: 'top-right'
                            });
                            this.loading = false;
                            this.getUsers();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                            this.loading = false;
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                
            }else{
                return false;
            }
        },
        openDocModal(id){
          
          this.dialogDoc = true;
          axios
            .get("/api/get-documents/"+id,this.header)
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.documentList = data.data;
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
           });
        },
        toggleAll(){
          if (this.selectedUser.length > 0) this.selectedUser = []
          else this.selectedUser = this.users_options.slice()
        },
        editItem(id){
          this.$router.push('/add-parking/'+id)
        },
        getFocusArea(){
       axios({
          method: "POST",
          url: "/api/get-all-focus-area",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.focusarea.push({name : 'All',id :'all'})
              data.forEach(element => {
                this.focusarea.push(element)
              });
            }
            })
    },
    getValue(val){
      var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',

        });

        return formatter.format(val); /* $2,500.00 */
    },
    
    getLocation(){
       axios({
          method: "POST",
          url: "/api/get-all-location",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.locations.push({name : 'All',id :'all'})
              data.forEach(element => {
                this.locations.push(element)
              });
            }
            })
    },
    getDashboardGrant(){
       axios({
          method: "GET",
          url: "/api/getdashboard",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.dashboardData = data
            }
            })
    },
    phoneFormat(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
      },
    getAssigedUser(id){
       axios({
          method: "GET",
          url: "/api/get-asigned-vehicle-user/"+id,
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            this.assigned_user = response.data.data;
            })
    },
    AddNewVehicle: function() {
          let self = this;
          let flag = 1;
          if(!this.company.vin) {
            Vue.$toast.error('Please Enter Vin Number', {
            position: 'top-right'
            })
          }
          this.company.amount = this.company.amount.replace(/[^a-zA-Z0-9]/g, '');
          let header = { headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    }
            }
    
            if (flag) {
              self.loading = true;
              
            axios({
              method: "POST",
             headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    },
               
              url: "/api/save-vehicle",
              data: self.company
            })
              .then(response => {
                let data = response.data.data;
                if (response.data) {
                      
                      self.loading = false;
                      self.dialogAddNewInventory = false;
                      self.getInventory();
    
                } else {
                    Vue.$toast.error('Failed To Add', {
                    position: 'top-right'
                    });
                    self.loading = false;
                }
              })
              .catch(err => {
                console.log(err.response);
                
                  if(err.response.data.errors.email)
                    Vue.$toast.error(err.response.data.errors.email[0], {
                    position: 'top-right'
                    });
                  if(err.response.data.errors.user_name)
                    Vue.$toast.error(err.response.data.errors.user_name[0], {
                    position: 'top-right'
                    });     
                  if(err.response.data.errors.company_url)
                    Vue.$toast.error(err.response.data.errors.company_url[0], {
                    position: 'top-right'
                    });             
              });
            }
    
     },

     AddNewDealer: function() {
          let self = this;
          let flag = 1;
          if(!this.dealer.name) {
            Vue.$toast.error('Please Enter Name', {
            position: 'top-right'
            })
          }
          let header = { headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    }
            }
    
            if (flag) {
              self.loading = true;
              
            axios({
              method: "POST",
             headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    },
               
              url: "/api/save-dealer",
              data: self.dealer
            })
              .then(response => {
                let data = response.data.data;
                if (response.data) {
                      
                      self.loading = false;
                      self.dialogNewDealers = false;
                      self.getDealers();
    
                } else {
                    Vue.$toast.error('Failed To Add', {
                    position: 'top-right'
                    });
                    self.loading = false;
                }
              })
              .catch(err => {
                console.log(err.response);
                
                  if(err.response.data.errors.email)
                    Vue.$toast.error(err.response.data.errors.email[0], {
                    position: 'top-right'
                    });
                  if(err.response.data.errors.user_name)
                    Vue.$toast.error(err.response.data.errors.user_name[0], {
                    position: 'top-right'
                    });     
                  if(err.response.data.errors.company_url)
                    Vue.$toast.error(err.response.data.errors.company_url[0], {
                    position: 'top-right'
                    });             
              });
            }
    
     },

        deleteItem(id){
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .post("/api/delete-sold-units",{ids : this.selectedItems},this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getUsers();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        deleteItemDealers(id){
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .post("/api/delete-dealers",{ids : this.selectedItemsDealers},this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getDealers();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        changeStatusDealer(){
          var r = confirm("Are you sure, You went to change?");
          if (r == true) {
            axios
              .post("/api/change-status-dealer/"+this.selectedDealerStatus,{ids : this.selectedItemsDealers},this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Status changed successfully', {
                      position: 'top-right'
                      });
                    this.getDealers();
                    this.dialogChangeStatusDealers = false
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        saveMassSaveVehicle(flag){
          var r = confirm("Are you sure, You went to change?");
          if (r == true) {
            axios
              .post("/api/mass-save-vehicle",{ids : this.selectedItemsInventory,flag: flag,packs: this.packs},this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Updated successfully', {
                      position: 'top-right'
                      });
                    this.getInventory();
                    this.dialogUpdatePrice = false
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        getSettings() {
    let flag = 1;
    let url = process.env.VUE_APP_WASBI_URL
    var config = {headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache'}};
      axios({
          method: "POST",
          url: "/api/get-company-settings",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.company_settings = data.settings
               
            }


         })
          .catch(err => {
            // Vue.$toast.error(err, {
            // position: 'top-right'
            // });

           });

     },
        getShareSettings() {
            let flag = 1;
            var config = {headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache'}};
              axios({
                  method: "POST",
                  
                  url: "/api/get-share-settings",
                  headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    let data = response.data.data;
                    if(data){
                      this.share_settings = JSON.parse(data.settings);
                    }


                })
                  .catch(err => {
                    // Vue.$toast.error(err, {
                    // position: 'top-right'
                    // });

                  });

            },
        setVehicleType(){
          var r = confirm("Are you sure, You went to change?");
          if (r == true) {
            axios
              .post("/api/vehicle-type-set",{ids : this.selectedItemsInventory,type : this.selectedProgram},this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Vehicle Type set successfully', {
                      position: 'top-right'
                      });
                    this.getInventory();
                    this.getInventoryCOW();
                    this.getInventoryCL();
                    this.dialogAssignProgram = false
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        setBodyStyle(flag){
          let data = ''
          if(flag == 'body_style'){
            data = this.selected_body_style;
          }else if(flag == 'state'){
            data = this.selected_state;
          }

          var r = confirm("Are you sure, You went to change?");
          if (r == true) {
            axios
              .post("/api/vehicle-body-set",{ids : this.selectedItemsInventory,type : data,flag : flag},this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Vehicle details updated successfully', {
                      position: 'top-right'
                      });
                    this.getInventory();
                    this.getInventoryCOW();
                    this.getInventoryCL();
                    this.dialogAssignBodyStyle = false
                    this.dialogAssignState = false
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        closeDetailmodal(){
          this.openDialogEdit = false
        },
        editPacks(item){
          this.selected_packs = item;
          this.packsFlag = 'single';
          this.openDialogPacks = true
        },
        deleteItemInventoryType(id){
          let items = []
          if(id == 'clearance'){
            items = this.selectedItemsInventoryCL
          }else if(id == 'below_credit_flag'){
            items = this.selectedItemsInventoryBelow
          }else if(id == 'above_credit_flag'){
            items = this.selectedItemsInventoryAbove
          }else if(id == 'bhph'){
            items = this.selectedItemsInventoryBHPH
          }else{
            items = this.selectedItemsInventoryCOW
          }
          var r = confirm("Are you sure, You went to change?");
          if (r == true) {
            axios
              .post("/api/vehicle-type-unset",{ids : items,type : id},this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Vehicle Type set successfully', {
                      position: 'top-right'
                      });
                    this.getInventoryCOW();
                    this.getInventoryCL();
                    this.getInventoryBelow();
                    this.getInventoryAbove();
                    this.getInventoryBHPH()
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        deleteItemInventory(id){
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .post("/api/delete-vehicle",{ids : this.selectedItemsInventory},this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getInventory();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        removeShare(){
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .post("/api/delete-user-share",{ids : this.selectedItemsShares},this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getShares();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        assignAgent(){
          var r = confirm("Are you sure, You went to assign?");
          if (r == true) {
            this.loadingAssign = true
            axios
              .post("/api/assign-sold-units",{ids : this.selectedItems,agent_id : this.selectedCompany},this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Assigned successfully', {
                      position: 'top-right'
                      });
                    this.getUsers();
                    this.loadingAssign = false
                    this.dialogAssign = false
                  }else {
                    this.loadingAssign = false
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        updateShareUser(){
          var r = confirm("Are you sure, You went to assign?");
          let flag = 1;
          if(!this.selectedUser ){
             Vue.$toast.error('Select User', {
                      position: 'top-right'
                      });
            flag = 0
          }
          if(!this.seletedShare ){
             Vue.$toast.error('Provide Share', {
                      position: 'top-right'
                      });
                      flag = 0
          }
          if(this.seletedShare > (this.share_settings.total_share - this.share_sold) ){
             Vue.$toast.error('Share amount is exceed with max share amount. Max Share : '+(this.share_settings.total_share - this.share_sold), {
                      position: 'top-right'
                      });
                      flag = 0
          }
          if (r == true && flag == 1) {
            this.loadingAssign = true
            axios
              .post("/api/update-user-share/"+this.selectedUser,{shares : this.seletedShare},this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Assigned successfully', {
                      position: 'top-right'
                      });
                    this.getShares();
                    this.loadingAssign = false
                    this.dialogAssignShare = false
                  }else {
                    this.loadingAssign = false
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        assignAgentINventory(){
          var r = confirm("Are you sure, You went to assign?");
          if (r == true) {
            this.loadingAssign = true
            let items = []
            if(this.assignButton == 'main'){
              items = this.selectedItemsInventory
            }
            if(this.assignButton == 'below_credit_flag'){
              items = this.selectedItemsInventoryBelow
            }
            if(this.assignButton == 'above_credit_flag'){
              items = this.selectedItemsInventoryAbove
            }
            if(this.assignButton == 'clearance'){
              items = this.selectedItemsInventoryCL
            }
            if(this.assignButton == 'cows'){
              items = this.selectedItemsInventoryCOW
            }
            if(this.assignButton == 'bhph'){
              items = this.selectedItemsInventoryBHPH
            }
            axios
              .post("/api/assign-vehicle-inventory",{ids : items,agent_id : this.selectedUser},this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Assigned successfully', {
                      position: 'top-right'
                      });
                    this.getInventory();
                    this.loadingAssign = false
                    this.dialogAssignInventory = false
                  }else {
                    this.loadingAssign = false
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        changeStatus(id,value){
            this.loading = true
             var r = confirm("Are you sure, You went to change status?");
            if (r == true) {
                    axios
                .get("/api/change-status/"+id+"/"+value,this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Profile status changed successfully', {
                            position: 'top-right'
                            });
                            this.getUsers();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                
            }else{
                return false;
            }
        },
        uploadFiles(){
            let self = this;
            let flag = 1;
            if(self.uploadedDocumentCSV.length == 0){
                Vue.$toast.error('Select document', {
                position: 'top-right'
                });
                flag = 0;
            }
            else{
                self.loadingUpload = true;
                const data = new FormData();
                data.append('client_csv', self.uploadedDocumentCSV);
                axios({
                method: "POST",
                url: "/api/import-sold-unit-csv",
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
                data: data
                })
                .then(response => {
                console.log(response);
                self.loadingUpload = false;
                 Vue.$toast.success('Client imported successfully', {
                            position: 'top-right'
                            });
                self.getUsers();
                self.uploadedDocumentCSV = [];
                self.dialogImport = false;
                // this.isDisableUpload = false;
                // this.isDarkUpload = true;
                // self.e1 = 3;
                
                })
                .catch(err => {
                    Vue.$toast.error("Invalid file to upload", {
                    position: 'top-right'
                    });
                    self.loadingUpload = false;
                    // this.isDisableUpload = false;
                    // this.isDarkUpload = true;
                });
            }
        },
        uploadFilesInventory(){
            let self = this;
            let flag = 1;
            if(self.uploadedDocumentCSVInventory.length == 0){
                Vue.$toast.error('Select document', {
                position: 'top-right'
                });
                flag = 0;
            }
            else{
                self.loadingUpload = true;
                const data = new FormData();
                data.append('client_csv', self.uploadedDocumentCSVInventory);
                axios({
                method: "POST",
                url: "/api/import-inventory-csv",
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
                data: data
                })
                .then(response => {
                console.log(response);
                self.loadingUpload = false;
                 Vue.$toast.success('Vehicle imported successfully', {
                            position: 'top-right'
                            });
                self.getInventory();
                self.uploadedDocumentCSVInventory = [];
                self.dialogImportInventory = false;
                // this.isDisableUpload = false;
                // this.isDarkUpload = true;
                // self.e1 = 3;
                
                })
                .catch(err => {
                    Vue.$toast.error("Invalid file to upload", {
                    position: 'top-right'
                    });
                    self.loadingUpload = false;
                    // this.isDisableUpload = false;
                    // this.isDarkUpload = true;
                });
            }
        },
        checkPermission(module){
          if(this.role.name =='super_admin')
            return true;
          if(this.user_permissions.length > 0){
            let flag = 0;
            this.user_permissions.forEach(val => {
              if(val.module.name == module)
                flag = true;
            })
            return flag;
          }else{
            return false;
          }
        },
        uploadFilesDealers(){
            let self = this;
            let flag = 1;
            if(self.uploadedDocumentCSV.length == 0){
                Vue.$toast.error('Select document', {
                position: 'top-right'
                });
                flag = 0;
            }
            else{
                self.loadingUpload = true;
                const data = new FormData();
                data.append('client_csv', self.uploadedDocumentCSV);
                axios({
                method: "POST",
                url: "/api/import-dealer-csv",
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
                data: data
                })
                .then(response => {
                console.log(response);
                self.loadingUpload = false;
                 Vue.$toast.success('Dealers imported successfully', {
                            position: 'top-right'
                            });
                self.getDealers();
                self.uploadedDocumentCSV = [];
                self.dialogImportDealers = false;
                // this.isDisableUpload = false;
                // this.isDarkUpload = true;
                // self.e1 = 3;
                
                })
                .catch(err => {
                    Vue.$toast.error("Invalid file to upload", {
                    position: 'top-right'
                    });
                    self.loadingUpload = false;
                    // this.isDisableUpload = false;
                    // this.isDarkUpload = true;
                });
            }
        },
        getLinks(){
          let headers = {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
          axios
            .get("/api/get-all-client", {headers: headers})
            .then(response => {
              console.log(response);
              this.users_options = this.users_options_old = response.data.data
            })
        },
        fetchInventoryOutside(){
          this.loadingUpload = true
          let headers = {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
          axios
            .post("/api/fetch-inventory-outside",{type: this.selectedUrlOption,url: this.selectedUrl}, {headers: headers})
            .then(response => {
              this.importInvetories = response.data.data
              this.dialogImportInventory2 = false
              this.dialogInventoryFinal = true
              this.loadingUpload = false;
            })
        },
        saveURLInventory(){
            let self = this;
            self.loadingUpload= true;
            let header = { headers : {
                        Authorization:"Bearer " + localStorage.getItem('token')
                        }
                } 
            axios
                .post("/api/import-inventory-save",{inventory:this.importInvetories,dealer_name:this.selectedUrlOption},header)
                .then(response => {
                  
                
                self.dialogInventoryFinal = false;
                self.loadingUpload = false;
                self.getInventory();
                self.selectedUrlOption = '';
                self.selectedUrl = '';
                })
                .catch(err => {
                self.loadingChat = false;
                    Vue.$toast.error('Unable to send', {
                    position: 'top-right'
                    });
                })
            },
        getUsers(){
            this.loading = true;
            this.offset = (this.page - 1) * this.datatable_options.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options.itemsPerPage,
              offset : this.offset
            }
            axios({
          method: "POST",
           
          url: "/api/get-sold-units",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.users = data.data;
                this.pageCount = data.total_page
                this.totalCount = data.count
                console.log( this.users);
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            // Vue.$toast.error(err, {
            // position: 'top-right'
            // });

           });
        },
        getShares(){
            this.loading = true;
            this.offset = (this.pageShares - 1) * this.datatable_options_shares.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options_shares.itemsPerPage,
              offset : this.offset
            }
            axios({
          method: "POST",
           
          url: "/api/get-share-users",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.shares = data.data;
                this.pageCountShares = data.total_page
                this.share_sold = data.share_sold
                this.totalCountShares = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            // Vue.$toast.error(err, {
            // position: 'top-right'
            // });

           });
        },
        getInventory(){
            this.loading = true;
            this.offsetInventory = (this.pageInventory - 1) * this.datatable_options_inventory.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options_inventory.itemsPerPage,
              offset : this.offsetInventory,
              search : this.search_inventory,
              filter_program : this.inventoryFilterProgram,
              filter_body_style : this.inventoryFilterBodyStyle != 'All' ? this.inventoryFilterBodyStyle : '',
              amount_min : this.inventoryFilterMinPrice,
              amount_max : this.inventoryFilterMaxPrice,
              odometer_min : this.inventoryFilterMinOdometer,
              odometer_max : this.inventoryFilterMaxOdometer,
              vehicle_type : ''
            }
            axios({
          method: "POST",
           
          url: "/api/get-vehicle-inventory",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.inventories = data.data;
                this.pageCountInventory = data.total_page
                this.totalCountInventory = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            // Vue.$toast.error(err, {
            // position: 'top-right'
            // });

           });
        },
        exportToCSV(type){
          this.loading = true;
            this.offsetInventory = (this.pageInventory - 1) * this.datatable_options_inventory.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options_inventory.itemsPerPage,
              offset : this.offsetInventory,
              search : this.search_inventory,
              filter_program : this.inventoryFilterProgram,
              filter_body_style : this.inventoryFilterBodyStyle != 'All' ? this.inventoryFilterBodyStyle : '',
              amount_min : this.inventoryFilterMinPrice,
              amount_max : this.inventoryFilterMaxPrice,
              vehicle_type : '',
              selected_ids : this.selectedItemsInventory
            }
            axios({
          method: "POST",
           
          url: "/api/export-vehicle-inventory",
          responseType: 'blob', // Important for file download
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
             // Create a new Blob object using the response data
            const blob = new Blob([response.data], { type: 'text/csv' });
             const now = new Date();
              const dateStr = now.getFullYear() + "-" + 
                        ("0" + (now.getMonth() + 1)).slice(-2) + "-" + 
                        ("0" + now.getDate()).slice(-2) + "_" +
                        ("0" + now.getHours()).slice(-2) + "-" + 
                        ("0" + now.getMinutes()).slice(-2) + "-" + 
                        ("0" + now.getSeconds()).slice(-2);
            const filename = `vehicle_inventory_${dateStr}.csv`;
            // Generate a download link for the CSV file
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); // The file name
            document.body.appendChild(link);
            link.click(); // Trigger the download
            link.remove(); // Remove the link after downloading

            // Show success message
            Vue.$toast.success('Data Exported successfully', {
                position: 'top-right'
            });
            this.loading = false;

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
        getDealers(){
            this.loading = true;            
            this.offsetDealers = (this.pageDealers - 1) * this.datatable_options_dealers.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options_dealers.itemsPerPage,
              offset : this.offsetDealers,
              search : this.search_dealers,
              vehicle_type : ''
            }
            console.log(req);
            
            axios({
          method: "POST",
           
          url: "/api/get-dealers",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.dealers = data.data;
                this.pageCountDealers = data.total_page
                this.totalCountDealers = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            // Vue.$toast.error(err, {
            // position: 'top-right'
            // });

           });
        },
        getInventoryCOW(){
            this.loading = true;
            this.offsetInventoryCOW = (this.pageInventoryCOW - 1) * this.datatable_options_inventory_cow.itemsPerPage
            let req = {
              limit : this.datatable_options_inventory_cow.itemsPerPage,
              offset : this.offsetInventoryCOW,
              search : this.search_inventory_cow,
              vehicle_type : 'car_of_the_week'
            }
            axios({
          method: "POST",
           
          url: "/api/get-vehicle-inventory",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.inventories_cow = data.data;
                this.pageCountInventoryCOW = data.total_page
                this.totalCountInventoryCOW = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            // Vue.$toast.error(err, {
            // position: 'top-right'
            // });

           });
        },
        getInventoryBelow(){
            this.loading = true;
            this.offsetInventoryBelow = (this.pageInventoryBelow - 1) * this.datatable_options_inventory_below.itemsPerPage
            let req = {
              limit : this.datatable_options_inventory_below.itemsPerPage,
              offset : this.offsetInventoryBelow,
              search : this.search_inventory_below,
              vehicle_type : 'below_credit_flag'
            }
            axios({
          method: "POST",
           
          url: "/api/get-vehicle-inventory",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.inventories_below = data.data;
                this.pageCountInventoryBelow = data.total_page
                this.totalCountInventorybelow = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            // Vue.$toast.error(err, {
            // position: 'top-right'
            // });

           });
        },
        getInventoryAbove(){
            this.loading = true;
            this.offsetInventoryAbove = (this.pageInventoryAbove - 1) * this.datatable_options_inventory_above.itemsPerPage
            let req = {
              limit : this.datatable_options_inventory_above.itemsPerPage,
              offset : this.offsetInventoryAbove,
              search : this.search_inventory_above,
              vehicle_type : 'above_credit_flag'
            }
            axios({
          method: "POST",
           
          url: "/api/get-vehicle-inventory",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.inventories_above = data.data;
                this.pageCountInventoryAbove = data.total_page
                this.totalCountInventoryAbove = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            // Vue.$toast.error(err, {
            // position: 'top-right'
            // });

           });
        },
        getInventoryBHPH(){
            this.loading = true;
            const offsetInventoryBHPH = (this.pageInventoryBHPH - 1) * this.datatable_options_inventory_BHPH.itemsPerPage
            let req = {
              limit : this.datatable_options_inventory_BHPH.itemsPerPage,
              offset : offsetInventoryBHPH,
              search : this.search_inventory_BHPH,
              vehicle_type : 'bhph'
            }
            axios({
          method: "POST",
           
          url: "/api/get-vehicle-inventory",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.inventories_BHPH = data.data;
                this.pageCountInventoryBHPH = data.total_page
                this.totalCountInventoryBHPH = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            // Vue.$toast.error(err, {
            // position: 'top-right'
            // });

           });
        },
        getInventoryCL(){
            this.loading = true;
            this.offsetInventoryCL = (this.pageInventoryCL - 1) * this.datatable_options_inventory_cl.itemsPerPage
            let req = {
              limit : this.datatable_options_inventory_cl.itemsPerPage,
              offset : this.offsetInventoryCL,
              search : this.search_inventory_cl,
              vehicle_type : 'clearance'
            }
            axios({
          method: "POST",
           
          url: "/api/get-vehicle-inventory",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.inventories_cl = data.data;
                this.pageCountInventoryCL = data.total_page
                this.totalCountInventoryCL = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            // Vue.$toast.error(err, {
            // position: 'top-right'
            // });

           });
        },
        getProcessStatus(id){
            this.loading = true;
            this.offset = (this.page - 1) * 10
            axios({
              method: "GET",
               
              url: "/api/get-process-status/"+id,
              header : { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
                }
            })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.transaction = data.data.value[0];
                console.log(this.transaction);
                this.process_status_dialog = true;
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
        

    },
    watch: {
            datatable_options: {
                handler () {
                this.getUsers()
                },
                deep: true,
            },
            datatable_options_inventory: {
                handler () {
                  this.getInventory()
                },
                deep: true,
            },
            datatable_options_dealers: {
                handler () {
                  this.getDealers()
                },
                deep: true,
            },
            datatable_options_inventory_cow: {
                handler () {
                  this.getInventoryCOW()
                },
                deep: true,
            },
            datatable_options_inventory_below: {
                handler () {
                  this.getInventoryBelow()
                },
                deep: true,
            },
            datatable_options_inventory_above: {
                handler () {
                  this.getInventoryAbove()
                },
                deep: true,
            },
            datatable_options_inventory_cl: {
                handler () {
                  this.getInventoryCL()
                },
                deep: true,
            },
            datatable_options_shares: {
                handler () {
                  this.getShares()
                },
                deep: true,
            },
            datatable_options_inventory_BHPH: {
                handler () {
                  this.getInventoryBHPH()
                },
                deep: true,
            },
          },
          computed: {
          //Done to get the ordered headers
          headers_inventory_show () {
            let self = this;
            return this.headers_inventory_final.filter(s => self.selectedHeaders.includes(s));
          }
        }
    
}
</script>

<style>
.v-tabs-items{
  height: 100%;
  margin-bottom: 70px;
}
.v-window-item .v-window-item--active{
  height: inherit;
}
</style>