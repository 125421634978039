<template>
  <v-app class="bg-white">
    <v-main class="auth-pages" style="background-color: #000000;">
      
      <div
        class="position-relative "
        :style="`background-image: url(${logo_background}); background-size: cover;height: 100%;`  "
      >
        <span class="mask bg-gradient-default opacity-6"></span>
        <v-container class="pb-0 position-relative" style="justify-content: space-around;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);background-color: transparent;">
                <div class=" mb-1" style="display: flex; justify-content: space-around" >
    </div>
                <v-row class="align-center d-flex h-100" style="" align="center" justify="center">
                <!-- <v-col lg="5" md="7" class="mx-auto"></v-col> -->
                      <v-col lg="5" md="7" class="mx-auto">
                        <v-card class="card-shadow border-radius-xl py-1" style="background-color:transparent;">
                         
                         <div class="card-padding pb-0" style="text-align:center;" >
      <img v-if="!company && loadingFlag" :height="this.$vuetify.breakpoint.xs ? 180 : 280" :class="this.$vuetify.breakpoint.xs ? '':'image-logo2 mb-5'" src="@/assets/img/logo.png">
      <img v-if="company && loadingFlag &&  company.logo" :height="this.$vuetify.breakpoint.xs ? 150 : 200" class="image-logo2" :src="base_url_img+company.logo">
      <h1 :style="this.$vuetify.breakpoint.xs ? 'font-size: 20px' : ''" class="elementor-heading-title elementor-size-default">THE MODERN<br>APPROACH TO</h1>
      <h1 :style="this.$vuetify.breakpoint.xs ? 'font-size: 20px' : ''" class="elementor-heading-title elementor-size-default" style="color: #FAC507;">REFERRING</h1>
      <!-- <p class="mb-0  font-weight-light text-white">
        Please login to your account
      </p> -->
    </div>
    <div class="card-padding pb-4">
   
      <v-text-field
        label="Email"
        color="#FDE038"
        outlined
        rounded
        v-model="email"
        prepend-inner-icon="fa-envelope"
        dense
        class="font-size-input input-style py-0 input-login"
      ></v-text-field>

      <v-text-field
        label="Password"
        color="#FDE038"
        prepend-inner-icon="fa-key"
        outlined
        rounded
        v-model="password"
        dense
        :type="showpassword ? 'text' : 'password'"
        :append-icon="showpassword ? 'fa-eye' : 'fa-eye-slash'"
        @click:append="showpassword = !showpassword"
        class="font-size-input input-style py-0 input-login"
      ></v-text-field>
      <div style="justify-content: center;display: flex; ">
      <v-checkbox
      style="margin-top: -10px; margin-bottom: -20px;"
      class="check-login"
      v-model="checkbox"
      color="white"
      :label="`I agree to terms & Conditions.`"
    ></v-checkbox></div>
      <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        rounded
        outlined
        class="
          font-weight-bold
          text-uppercase
          btn-primary
          
          py-2
          px-6
          me-2
          mt-7
          mb-2
          w-100
        "
        color="#FDE038"
        small
         v-on:click="checkForm"
        >Sign In</v-btn
      >
      
      <div class="text-center">
        <p class="text-sm text-body font-weight-light mt-3 mb-0" style="color:#7b809a !important;">
          Don't Have an Account?
          <a
            @click="$router.push('/signup')"
            class="
              text-white  text-decoration-none
              font-weight-bold
            "
            >Sign up</a
          >
        </p>
      </div>
    </div>
                        </v-card>
                      </v-col>
                    </v-row>
                    </v-container>
                    </div>
      <!-- <div class="header-auth position-relative border-radius-xl min-vh-100">
        <v-container class="py-0">
          <v-row>
            <v-col
              cols="7"
              class="
                d-lg-flex d-none
                h-100
                my-auto
                pe-0
                pa-0
                position-absolute
                top-0
                start-0
                text-center
                justify-center
                flex-column
              "
            >
              <div
                class="
                  position-relative
                  h-100
                  ma-5
                  px-7
                  border-radius-lg
                  d-flex
                  flex-column
                  justify-content-center
                "
                :style="`background-image: url(${require('@/assets/img/Background.jpeg')}); background-size: cover;`"
              ></div>
            </v-col>
            <v-col
              lg="4"
              md="7"
              class="d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5 py-0"
            >
              <fade-transition
                :duration="200"
                origin="center top"
                mode="out-in"
              >
                <v-card class=" bg-transparent">
    <div class="card-padding pb-0">
      <img  :height="this.$vuetify.breakpoint.xs ? 200 : 280" class="image-logo2" src="@/assets/img/Quantum Disputes.png">
      <h2 class="text-h1 font-weight-bolder text-typo mb-2 mt-5">Welcome Back!</h2>
      <p class="mb-0 text-body font-weight-light">
        Please login to your account
      </p>
    </div>
    <div class="card-padding pb-4">
   
      <v-text-field
        label="Email"
        color="#e91e63"
        outlined
        v-model="email"
        prepend-inner-icon="fa-envelope"
        dense
        class="font-size-input input-style py-0"
      ></v-text-field>

      <v-text-field
        label="Password"
        color="#e91e63"
        prepend-inner-icon="fa-key"
        outlined
        v-model="password"
        dense
        :type="showpassword ? 'text' : 'password'"
        :rules="rules"
        :append-icon="showpassword ? 'fa-eye' : 'fa-eye-slash'"
        @click:append="showpassword = !showpassword"
        class="font-size-input input-style py-0"
      ></v-text-field>

      <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        class="
          font-weight-bold
          text-uppercase
          btn-primary
          
          py-2
          px-6
          me-2
          mt-7
          mb-2
          w-100
        "
        color="#4AA64E"
        small
         v-on:click="checkForm"
        >Sign In</v-btn
      >
      
      <div class="text-center">
        <p class="text-sm text-body font-weight-light mt-3 mb-0">
          Don't Have an Account?
          <a
            @click="$router.push('/signup')"
            class="
              text-secondary text-gradient text-decoration-none
              font-weight-bold
            "
            >Sign up</a
          >
        </p>
      </div>
    </div>
  </v-card>
              </fade-transition>
            </v-col>
          </v-row>
        </v-container>
      </div> -->
    </v-main>
  </v-app>
</template>
<script>
import AppBarBlurAuth from "@/components/AppBarBlurAuth";
import { FadeTransition } from "vue2-transitions";

export default {
  name: "page-layout",
  components: {
    AppBarBlurAuth,
    FadeTransition,
  },
  data() {
    return {
       checkbox: false,
       showpassword : false,
       email : '',
      password : '',
      showPassword : false,
      dialog: false,
      logo_background: require('@/assets/img/img-bg.jpg'),
      pageFlag: 'init',
      backgroundLogo: localStorage.getItem('admin_bg'),
      isDark :true,
      buttonFlag   : false,
      loadingLogo : false,
      url : '',
      company : '',
      loadingFlag : false,
      base_url_img : process.env.VUE_APP_WASBI_URL
    };
  },
  beforeCreate: function () {
    if (localStorage.getItem('token')) {
        if(localStorage.getItem('is_admin') && localStorage.getItem('is_admin') == 1)
            this.$router.push('/admin/dashboard')
        else
            this.$router.push('/dashboard')
    }
},
created: function () {
  this.url = window.location.origin;//this.$route.params.url ? this.$route.params.url : '';console.log(this.$route)
  if(this.url)
      this.getCompanyInfo(this.url)
  else
      this.loadingFlag = true;
},
methods: {
gotohome: function () {
    let self = this;
    self.$router.push({ name: "Home" });
},
getCompanyInfo(url){
  axios({
          method: "POST",
           
          url: "/api/get-company-info",
          data: {
            url: url
          }
        })
          .then(response => {
            this.company = response.data.company;
            console.log(this.company);
            this.loadingFlag = true;
            this.loadingLogo = true;
          })

},
checkFormSignup(){
  let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      if (!this.password && flag == 1) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        localStorage.setItem('temp_email',this.email)
        localStorage.setItem('temp_pass',this.password)
        localStorage.setItem('email_verified',0)
        if(this.company)
          this.$router.push('/signup/'+this.url)
        else
          this.$router.push('/signup')
      }
},
forgotPass(){
    this.buttonFlag =true;
    this.isDark = false;
    axios({
          method: "POST",
           
          url: "/api/auth/forgot-password",
          data: {
            email: this.email,
          }
        })
          .then(response => {
                this.sendFlag = 1;
                Vue.$toast.success('Forgot Password Mail sent', {
                position: 'top-right'
                });
                this.buttonFlag =false;
                this.isDark = true;
                this.pageFlag = 'login';
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          })
},
gotosignup: function () {
    let self = this;
    self.$router.push('/signup');
},gotoGoogle(){
      let self = this;
        this.$router.push('/redirect/google');
        location.reload();
  },
  gotoMs(){
      let self = this;
        this.$router.push('/redirect/microsoft');
        location.reload();
  },
  gotoYahoo(){
      let self = this;
        this.$router.push('/redirect/yahoo');
        location.reload();
  },
/**
 * checkForm
 * login the user with valid email/pass
 */
checkForm: function(e) {
      var loginData = {};
      var header = {};
      let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      if (!this.password && flag == 1) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        var email = this.email;
        axios({
          method: "POST",
           
          url: "/api/auth/login",
          data: {
            email: this.email,
            password: this.password,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.flag == 1) {
              let result = data.data;
              if(result.roles.length > 1){
                localStorage.setItem('logged_in_email',this.email);
                localStorage.setItem('logged_in_password',btoa(this.password));
                this.$router.push("/choose-profile");
              }else{
                localStorage.setItem("token", data.token.token);
                localStorage.setItem("user_id", result.user_id);
                localStorage.setItem("email", result.email);
                localStorage.setItem("phone", result.phone_m);
                localStorage.setItem("name", result.first_name+' '+result.last_name);
                localStorage.setItem("is_admin",0);
                localStorage.setItem("role", JSON.stringify(result.roles[0]));

                this.$router.push("/dashboard");
                location.reload();
              }
            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }
    }
  }
    
};
</script>
<style>
  .check-login .v-label {
    color: white;
  }
  .check-login .v-icon {
    color: white;
  }
.input-login .v-icon{
    color: #FDE038!important;
}
.input-login .v-label{
  color: #FDE038!important;
}
.input-login #input-6{
  color: #FDE038!important;
}
.input-login #input-10{
  color: #FDE038!important;
}
.elementor-heading-title {
  color: #FFFFFF;
  font-family: "Montserrat", Sans-serif;
  font-weight: 800;
  letter-spacing: 10px;
  text-shadow: 4px 4px 10px rgba(0,0,0,0.3);
}
</style>